import React, { useState, memo } from "react"
import { Heading, Input, Box, Portal, useTheme } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser } from "@fortawesome/free-solid-svg-icons"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import AthletesSearchList from "../../users/components/AthletesSearchList"
import styles from "./css/UserSearch.module.css"
import useColors from "../../common/hooks/useColors"

dayjs.extend(relativeTime)

const TeamRow = ({ athlete, onClick }) => {
  const { blueColor } = useColors()
  return (
    <div
      className={styles["search-item"]}
      onClick={() => {
        onClick(athlete)
      }}
    >
      {athlete.profile.picture ? (
        <img
          className={styles["user-img"]}
          alt={athlete.name}
          src={athlete.profile.picture}
        />
      ) : (
        <div className={styles["profile-place"]}>
          <FontAwesomeIcon color={blueColor} icon={faUser} />
        </div>
      )}
      <Box>
        <Heading mb={0} size="sm" variant="link" as="h6">
          {athlete.name}{" "}
        </Heading>
        <Heading mb={0} size="xs" fontSize={8} as="h6">
          {athlete.lastLoggedIn
            ? `Last active ${dayjs(athlete.lastLoggedIn).fromNow()}`
            : ""}
        </Heading>
      </Box>
    </div>
  )
}

const UserSearch = ({
  title,
  onClick,
  filterFn = () => true,
  compId = null,
}) => {
  const [searchStr, setSearchStr] = useState("")
  return (
    <div className={styles["container"]}>
      <Input
        className="width-100"
        autoComplete="off"
        placeholder={title}
        value={searchStr}
        onChange={(e) => {
          setSearchStr(e.target.value)
        }}
      />
      <AthletesSearchList
        isSmall
        searchStr={searchStr}
        filterFn={filterFn}
        compId={compId}
        onClick={(user) => {
          onClick(user)
          setSearchStr("")
        }}
        Row={TeamRow}
      />
    </div>
  )
}

export default memo(UserSearch, (prev, next) => {
  // return prev.title === next.title
  return true
})
