import * as React from "react"
import {
  Table,
  Thead,
  Tr,
  Td,
  Tbody,
  Heading,
  Button,
  Box,
} from "@chakra-ui/react"
import {
  CompScore,
  CompScoreInput,
  CompSet,
  CompSetInput,
  Competitor,
  ScannerActivity,
} from "src/gql/types/graphql"
import useSaveScannerCompScore from "../../comps/hooks/useSaveScannerCompScore"

export interface IEditCompRoundScannerActivityCompScoresProps {
  scannerActivities: ScannerActivity[]
  compSet: CompSet
  competitor: Competitor
  setAlertMessage: (message: string) => void
  finishSaving: () => void
}

export default function EditCompRoundScannerActivityCompScores({
  scannerActivities,
  compSet,
  competitor,
  setAlertMessage,
  finishSaving,
}: IEditCompRoundScannerActivityCompScoresProps) {
  const [isLoading, setIsLoading] = React.useState(false)
  const saveScannerCompScore = useSaveScannerCompScore({
    setMessage: setAlertMessage,
    returnFromSave: (score: CompScore) => {
      if (score?._id) {
        setAlertMessage("Score saved")
      }
    },
  })
  return (
    <>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Td>
              <Heading mb={0} size="sm" textTransform={"uppercase"}>
                Activity
              </Heading>
            </Td>
            <Td>
              <Heading
                mb={0}
                textAlign={"right"}
                size="sm"
                textTransform={"uppercase"}
              >
                Points
              </Heading>
            </Td>
            <Td width={200}></Td>
          </Tr>
        </Thead>
        <Tbody>
          {(scannerActivities ?? []).map((act) => (
            <Tr key={act._id}>
              <Td>
                <Heading size="sm">{act.name}</Heading>
              </Td>
              <Td>
                <Heading textAlign="right" size="sm">
                  {act.points}
                </Heading>
              </Td>
              <Td>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    isLoading={isLoading}
                    variant="secondary"
                    size="sm"
                    onClick={async () => {
                      setIsLoading(true)
                      await saveScannerCompScore({
                        activityId: act._id,
                        score: act.points,
                        compSetId: compSet._id,
                        competitorId: competitor._id,
                      })
                      setIsLoading(false)
                      finishSaving()
                    }}
                  >
                    Score
                  </Button>
                </Box>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  )
}
