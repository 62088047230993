import _ from "lodash"
import { useMutation } from "@apollo/client"
import React, { useState, useEffect } from "react"
import {
  MenuList,
  Menu,
  MenuButton,
  MenuItem,
  Portal,
  IconButton,
  Flex,
  Heading,
  useTheme,
} from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBell } from "@fortawesome/pro-light-svg-icons"
import { SET_NOTIFICATION_OPENED } from "../graphql/users"
import useColors from "../../common/hooks/useColors"

import styles from "./css/NotificationsMenu.module.css"
import TextLink from "../../common/components/TextLink"

const NotificationsMenu = ({ notifications }) => {
  const { blueColor } = useColors()
  const [setNotificationOpened] = useMutation(SET_NOTIFICATION_OPENED)
  if (!notifications.length) {
    return null
  }

  const numNewNotes = notifications.filter((n) => n.isSeen === false).length
  notifications = _.orderBy(notifications, ["date"], ["desc"])
  return (
    <div className="relative">
      <Menu>
        <MenuButton variant="text" size="lg" mr={3} as={IconButton}>
          <FontAwesomeIcon size="2x" color={blueColor} icon={faBell} />
        </MenuButton>
        {numNewNotes !== 0 ? (
          <Flex
            position={"absolute"}
            top={1}
            right={3}
            width={"18px"}
            height={"18px"}
            bg={"brand.orangeColor"}
            border={"1px solid"}
            borderColor={"brand.whiteColor"}
            borderRadius={"100%"}
            align="center"
            justify="center"
          >
            <Heading mb={"2px"} size="xs" color={"brand.whiteColor"} as="h6">
              {numNewNotes}
            </Heading>
          </Flex>
        ) : null}
        <Portal>
          <MenuList
            maxHeight={300}
            overflow="auto"
            position="relative"
            // setting schedule goes to 1002
            zIndex={1002}
          >
            {notifications.map((note, ind) => {
              const pict = _.get(note.users?.[0], "profile.picture")
              const link = note.link.includes("pebble://")
                ? note.link.split("pebble:/")[1]
                : note.link.includes("pebbleclimbing.com")
                ? note.link.split("pebbleclimbing.com")[1]
                : note.link
              return (
                <MenuItem
                  key={note.link + ind}
                  className={
                    styles[
                      note.isOpened
                        ? "note-list-item is-opened"
                        : "note-list-item"
                    ]
                  }
                >
                  <TextLink
                    to={link.includes("http") ? null : link}
                    href={link.includes("http") ? link : null}
                    onClick={() => {
                      setNotificationOpened({
                        variables: {
                          link: note.link,
                        },
                      })
                    }}
                  >
                    <Flex as="span" align="center">
                      {pict ? (
                        <img
                          className={styles["note-img"]}
                          src={pict}
                          alt={note.users?.[0].name}
                        />
                      ) : null}
                      <Heading as="h4" size="sm" mb={0} variant="link">
                        {note.name}
                      </Heading>
                    </Flex>
                  </TextLink>
                </MenuItem>
              )
            })}
          </MenuList>
        </Portal>
      </Menu>
    </div>
  )
}

export default NotificationsMenu
