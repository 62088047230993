import dayjs from "dayjs"
import _ from "lodash"
import { getGradeCollectionFromGym } from "./grades"
export function getRelativeGradeDelta({
  gradeCollection,
  competitor,
  climb,
  userCompScore,
}) {
  let maxGrade =
    climb.type === "route"
      ? competitor.maxRouteGrade
      : competitor.maxProblemGrade
  // if previous score, then look for previous max scores in competitor to compare score against
  const compScore = userCompScore || climb.userCompScore
  if (compScore) {
    let prevMaxScores =
      climb.type === "route"
        ? competitor.previousMaxRouteGrades
        : competitor.previousMaxProblemGrades
    if (prevMaxScores?.length) {
      const scoreDate = dayjs(compScore.createdAt)
      const prevMax = prevMaxScores.find(
        (prev) => dayjs(prev.maxDate).diff(scoreDate) > 0,
      )?.grade
      if (prevMax) {
        maxGrade = prevMax
      }
    }
  }
  const grade = climb.setterGrade || climb.grade
  const gradeIndex = climb?.gym?.isOverlappingGrades
    ? gradeCollection.findIndex((gr) => {
        const label = gr.name || gr.label
        return climb.gradeColor
          ? gr.color === climb.gradeColor
          : label === climb.gradeName
      })
    : gradeCollection.findIndex((obj) => obj.value >= grade)
  // greater than or equal in case user is using euro grading and gym is using us grade
  const climberIndex = gradeCollection.findIndex((obj) => obj.value >= maxGrade)
  return gradeIndex - climberIndex
}

export function getScoreDelta({ competitor, climb, userCompScore }) {
  if (!competitor) {
    return 0
  }
  const gradeCollection = getGradeCollectionFromGym({
    gym: climb.gym,
    climbType: climb.type,
  })
  return getRelativeGradeDelta({
    gradeCollection,
    competitor,
    climb,
    userCompScore,
  })
}

export function getRelativeScore({ compSet, delta }) {
  if (!compSet) {
    return null
  }
  const ptsPerGradeDiff = compSet.isRelativeScoring
    ? compSet.pointsPerGradeDiff
    : 0
  return compSet.isRelativeScoring
    ? compSet.relativeScore + ptsPerGradeDiff * delta
    : null
}

export function getIsPrevMaxScore({ score, climbType, competitor }) {
  let prevMaxScores =
    climbType === "route"
      ? competitor.previousMaxRouteGrades
      : competitor.previousMaxProblemGrades
  if (prevMaxScores?.length) {
    const scoreDate = dayjs(score.judgedAt)
    const prevMax = prevMaxScores.find(
      (prev) => dayjs(prev.maxDate).diff(scoreDate) > 0,
    )?.grade
    return !!prevMax
  }
  return false
}

// there's a bunch of loopy shit. I'm grabbing the compSet and comp and
// userCompScore by either going to the comp object or the score object and
// shoving those into the climb so that the ClimbRowScore component can
// properly parse the score from the climb object
// TODO: refactor, but requires some time looking at the graphql structure from the server
export function getTopNScores({ comp, competitors, isTakingAll }) {
  // there's a bunch of loopy shit. I'm grabbing the compSet and comp and
  // userCompScore by either going to the comp object or the score object and
  // shoving those into the climb so that the ClimbRowScore component can
  // properly parse the score from the climb object
  const setScoresObj = (comp.compSets || [])
    .filter((s) => !!s?.slug)
    .reduce((acc, set) => {
      acc[set?.slug] = []
      return acc
    }, {})
  const allScoresByRound = competitors.reduce((acc, cptr) => {
    _.sortBy(
      cptr.compScores,
      (scr) => new Date(scr.createdAt).getTime() * -1,
    ).forEach((scr) => {
      const curScrs = scr.compSet ? acc[scr.compSet.slug] : []
      // in CompSessionScores.js, the score doens't have the compSet, so need to get from comp
      const compSet = scr.compSet ? scr.compSet : comp.compSets?.[0]
      const newScr = {
        ...scr,
        // in CompSessionScores.js, the score doens't have the compSet, so need to get from comp
        compSet,
        user: cptr.user,
        competitor: cptr,
        climb: {
          ...(scr.problem || scr.route),
          comps: [
            {
              name: comp.name,
              _id: comp._id,
            },
          ],
          userCompScores: [
            {
              ...scr,
              comp: { _id: comp._id, name: comp.name },
            },
          ],
        },
      }
      // make sure multiple scores from same competitor on same route are
      // limited to maxTimesClimbScored
      const numScoresOfClimb =
        (curScrs || []).filter((sc) => {
          return (
            sc.climb._id === newScr.climb._id &&
            sc.competitor._id === newScr.competitor._id
          )
        })?.length || 0
      // if hasn't been added yet or the max num times allowed to send, then add to scores
      if (
        numScoresOfClimb <= (compSet.maxTimesClimbScored || 1) - 1 &&
        scr.compSet
      ) {
        acc[scr.compSet.slug] = [...(curScrs || []), newScr]
      }
    })
    return acc
  }, setScoresObj)
  return Object.keys(allScoresByRound).reduce((acc, key) => {
    const set = comp.compSets.find((set) => set.slug === key)
    if (!set) {
      return acc
    }
    competitors.forEach((cptr) => {
      const scrs = (allScoresByRound[key] || []).filter(
        (scr) => scr.competitor?._id === cptr._id,
      )
      const topN = _.chain(scrs)
        .sortBy((scr) => -1 * scr.score)
        .take(isTakingAll ? scrs?.length ?? 0 : set.numClimbsToScore)
        .value()
      acc[key] = _.sortBy(
        [...(acc[key] || []), ...topN],
        (scr) => -1 * scr.score,
      )
    })
    if (set.numClimbsToScorePerTeam && !isTakingAll) {
      const limitedScores = {}
      Object.keys(acc).forEach((key) => {
        limitedScores[key] = _.take(acc[key], set.numClimbsToScorePerTeam)
      })
      return limitedScores
    }
    return acc
  }, {})
}

export const getCompInfoFromTries = (tries) => {
  const compScoreTries = _.uniqBy(
    _.sortBy(tries || [], (tr) => new Date(tr.date).getTime() * -1).filter(
      (tr) => tr[tr.sendType].userCompScores?.length ?? 0,
    ),
    (tr) => tr[tr.sendType]._id,
  )
  const compSets = _.uniqBy(
    compScoreTries.map((tr) => tr[tr.sendType].compSets).flat(),
    "_id",
  )
  const comps = _.uniqBy(
    compScoreTries.map((tr) => tr[tr.sendType].comps).flat(),
    "_id",
  )
  const scores = compScoreTries
    .map((tr) => {
      const cl = tr.problem || tr.route
      return cl.userCompScores.map((sc) => ({
        ...sc,
        climb: tr.problem || tr.route,
        user: tr.user,
        send: tr.isAttempt ? null : tr,
      }))
    })
    .flat()

  return { scores, compSets, comps }
}
