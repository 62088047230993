import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import ModalCard from "./ModalCard"
import { Flex } from "@chakra-ui/react"

const StyledIFrame = styled.iframe`
  max-width: calc(100vw - 80px);
  max-height: calc(100vh - 80px);

  @media (max-width: 600px) {
    /* height = 100 * (9 / 16) = 56.25 */
    max-width: calc(100vw);
    margin: -10px -10px 0;
  }
`

const YoutubeModal = ({ youtubeId, isOpen, closeModal }) => {
  const [windowWidth, setWindowWidth] = useState(
    typeof window === "undefined" ? 800 : window.innerWidth,
  )
  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      setWindowWidth(window.innerWidth)
    }
    // set resize listener
    window.addEventListener("resize", resizeListener)

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener)
    }
  }, [])
  const vidWidth = windowWidth > 600 ? windowWidth - 360 : windowWidth - 42
  const vidHeight = (vidWidth * 9) / 16

  if (!isOpen || !youtubeId) {
    return null
  }
  return (
    <ModalCard width={vidWidth} isOpen={isOpen} closeModal={closeModal}>
      <Flex justifyContent="center">
        <StyledIFrame
          title="user demo"
          frameBorder="0"
          height={vidHeight}
          width={vidWidth}
          allowfullscreen="true"
          allow="fullscreen;"
          src={`https://www.youtube.com/embed/${youtubeId}?autoplay=1&modestbranding=1&showinfo=0&autohide=1`}
        ></StyledIFrame>
      </Flex>
    </ModalCard>
  )
}

export default YoutubeModal
