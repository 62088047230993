import { useEffect, useMemo, useState } from "react"
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  from,
  ApolloLink,
} from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import merge from "deepmerge"
import isEqual from "lodash/isEqual"
import typePolicies from "./typePolicies"
import { apiVersion } from "./env"
import { setContext } from "apollo-link-context"
import { createNetworkStatusNotifier } from "react-apollo-network-status"
import { uncrunch } from "graphql-crunch"
import { EventEmitter } from "events"
import { persistCache, LocalStorageWrapper } from "apollo3-cache-persist"
import isbot from "isbot"

const connector = new EventEmitter()
connector.setMaxListeners(100)

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__"

let apolloClient

const isServer = typeof window === "undefined"

const { link: networkStatusLink, useApolloNetworkStatus: useNetworkStatus } =
  createNetworkStatusNotifier()

export const useApolloNetworkStatus =
  typeof window !== "undefined" ? useNetworkStatus : () => null

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    )
  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const httpLink = new HttpLink({
  // uri: "http://host.docker.internal:9000/.netlify/functions/graphql",
  uri:
    process.env.NODE_ENV === "production"
      ? `${process.env.NEXT_PUBLIC_URL}/.netlify/functions/graphql`
      : "http://localhost:9000/.netlify/functions/graphql",
  credentials: "same-origin", // Additional fetch() options like `credentials` or `headers`
})

const auth = setContext((obj, { headers }) => {
  // get the authentication token from local storage if it exists
  let token = !isServer && localStorage.getItem("jsonwebtoken")
  // if token being passed, then change to this logged in user
  // cant user route param compLinkId here, so just splitting string to get
  if (!isServer && window.location.pathname.includes("/token/") && !token) {
    token = window.location.pathname.split("/token/")[1]
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
      api_version: apiVersion,
      "Apollo-Require-Preflight": "true",
    },
  }
})
// not using because puppeteer stalls if use (for reports)
// and apollo-cache-persist doesn't work with it
// const uncruncher = new ApolloLink((operation, forward) =>
//   forward(operation).map((response) => {
//     response.data = uncrunch(response.data)
//     return response
//   }),
// )

const defaultOptions = {
  watchQuery: {
    fetchPolicy:
      // fix for search bots and ssr since apollo can't use cache-and-network for apolloClient.query calls
      typeof navigator === "undefined" || isbot(navigator.userAgent)
        ? "cache-first"
        : "cache-and-network",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy:
      // fix for search bots and ssr since apollo can't use cache-and-network for apolloClient.query calls
      typeof navigator === "undefined" || isbot(navigator.userAgent)
        ? "cache-first"
        : "cache-and-network",
    errorPolicy: "all",
  },
  mutate: {
    errorPolicy: "all",
  },
}

function createApolloClient() {
  return new ApolloClient({
    ssrMode: isServer,
    link: from([auth, networkStatusLink, errorLink, httpLink]),
    cache: new InMemoryCache({
      typePolicies,
    }),
    defaultOptions,
  })
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient()

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s)),
        ),
      ],
    })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}

export function useApollo(pageProps) {
  const [isPersistentReady, setIsPersistentReady] = useState(false)
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  useEffect(() => {
    async function persist() {
      await persistCache({
        cache: apolloClient.cache,
        storage: new LocalStorageWrapper(window.localStorage),
      })
      setIsPersistentReady(true)
    }
    persist()
  }, [state])
  const store = useMemo(() => initializeApollo(state), [state])
  return isPersistentReady ? store : null
}
