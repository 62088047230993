import { useQuery } from "@apollo/client"
import { useRouter } from "next/router"
import React, { createContext, useState, useContext, useEffect } from "react"
import { LoggedInUserQuery } from "src/app/users/graphql/users"

// Create the Auth context
const AuthContext = createContext({
  loggedInUser: null,
  logout: () => {},
  loading: true,
})

// AuthProvider component
export const AuthProvider = ({ children }) => {
  const router = useRouter()
  const [loggedInUser, setLoggedInUser] = useState(null)

  useEffect(() => {
    // Check for a logged-in loggedInUser on component mount
    const storedLoggedInUser = JSON.parse(localStorage.getItem("loggedInUser"))
    if (storedLoggedInUser) {
      setLoggedInUser(storedLoggedInUser)
    }
  }, [])
  const logout = () => {
    setLoggedInUser(null)
    localStorage.removeItem("loggedInUser")
  }

  const { loading, data: { loggedInUser: queriedLoggedInUser } = {} } =
    useQuery(LoggedInUserQuery, {
      fetchPolicy: "network-only",
    })

  useEffect(() => {})

  useEffect(() => {
    if (queriedLoggedInUser) {
      setLoggedInUser(queriedLoggedInUser)
      localStorage.setItem("loggedInUser", JSON.stringify(queriedLoggedInUser))
    }
  }, [queriedLoggedInUser])

  // apple users logging in with web for first time can't get
  // name from social because sends back info as post data and
  // since we're running serverless, can't get that data.
  useEffect(() => {
    if (
      loggedInUser &&
      loggedInUser.firstName === "" &&
      !router.pathname.includes("/edit/user")
    ) {
      router.push({
        pathname: "/edit/user",
      })
      return
    }
  }, [router.pathname, loggedInUser, router])

  return (
    <AuthContext.Provider value={{ loggedInUser, logout, loading }}>
      {children}
    </AuthContext.Provider>
  )
}

// Hook to use the Auth context
export const useAuth = () => {
  return useContext(AuthContext)
}
