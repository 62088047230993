import * as React from "react"
import {
  Box,
  Flex,
  Heading,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Switch,
} from "@chakra-ui/react"
import { CompScore, CompSet } from "src/gql/types/graphql"

export interface IWCSportScoreInputProps {
  compScore: CompScore
  compSet: CompSet
  updateCompScore(compScore: CompScore): void
  updateCompScore(key: string, value: string | number | boolean | any[]): void
  setMessage(message: string, isError: boolean): void
}

export default function WCSportScoreInput({
  compScore,
  compSet,
  updateCompScore,
  setMessage,
}: IWCSportScoreInputProps) {
  return (
    <Box>
      <Heading size="sm" mb={2}>
        Hold Number
      </Heading>
      <NumberInput
        width={"70px"}
        onChange={(value) => {
          if (!compScore.userId && compSet.judgeType === "judge") {
            setMessage("You must select a competitor first.", false)
            return
          }
          updateCompScore("highpoint", Number(value))
        }}
        value={compScore?.highpoint ?? 0}
        min={1}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <Flex align="center" mt={3}>
        <Switch
          isChecked={compScore?.isPlusGiven ?? false}
          onChange={(e) => {
            if (!compScore.userId && compSet.judgeType === "judge") {
              setMessage("You must select a competitor first.", false)
              return
            }
            updateCompScore("isPlusGiven", e.target.checked)
          }}
        />
        <Heading size="sm">Did they get a plus?</Heading>
      </Flex>
    </Box>
  )
}
