import { Box, Button, Flex, Heading, Input, Text } from '@chakra-ui/react';
import { DiscountedPrice } from '../../../gql/types/graphql';
import * as React from 'react';

export interface IDiscountCodeFormProps {
  setMessage: (message: string) => void;
  discountCodePrices: DiscountedPrice[];
  initialCost: number;
  cost: number;
  setCost: (cost: number) => void;
  discountCode: string;
  setDiscountedPriceId: (discountedPriceId: string) => void;
  setDiscountCode: (discountCode: string) => void;
}

export default function DiscountCodeForm ({ discountCodePrices, initialCost, cost, setCost, setDiscountCode, discountCode, setMessage, setDiscountedPriceId }: IDiscountCodeFormProps) {
  const [hasApplied, setHasApplied] = React.useState(false);
  return (<Box mb={5}>
    <Heading as="h6" size="md">
      Discount Code
    </Heading>
    <Text mb={2}>If you have a discount code, enter it here.</Text>
    <Flex>
      <Input
        mr={2}
        flex={1}
        value={discountCode || ""}
        onChange={(e) => {
          setHasApplied(false)
          setDiscountCode(e.target.value)
        }} />

      <Button
        onClick={() => {
          const discountCodePrice = discountCodePrices.find(
            (d) => d?.code?.toLowerCase() === discountCode.toLowerCase(),
          )
          if (discountCodePrice) {
            if ((discountCodePrice?.price ?? 0) > cost) {
              setMessage(`The discount code you entered will not reduce your cost. Your cost is $${cost.toFixed(2)}.`
              )
              return
            }
            setHasApplied(true)
            setCost(discountCodePrice?.price ?? 0)
            if (discountCodePrice?._id) {
              setDiscountedPriceId(discountCodePrice?._id)
            }
          } else {
            setMessage(
              "We couldn't find that discount code. Please try again.",
            )
            setCost(initialCost)
          }
        }}
      >Apply</Button>
    </Flex>
    {hasApplied ? <Heading as="h6" size="xs" variant="error">This discount code has been applied!</Heading> : null}
  </Box>
  );
}
