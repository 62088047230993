import _ from "lodash"
import { useMutation } from "@apollo/client"
import {
  CREATE_COMPETITOR,
  EDIT_COMPETITOR,
  GET_USER_COMPETITOR_FROM_COMP,
  GET_CURRENT_USER_COMP_SETS,
  GET_COMP,
} from "../graphql/comps"
import { LoggedInUserQuery } from "../../users/graphql/users"
import { validateCompetitor } from "../../utilities/comps"
import { cleanCompetitor } from "../../guides/utilities/cleanFeatures"

export function useValidateCompetitor({
  comp,
  competitor: initCompetitor,
  setAlertMessage,
  isCreatingCompetitorAsManager,
}) {
  let competitor = cleanCompetitor(initCompetitor)
  // might be async in future if need to check against server
  async function validate() {
    return validateCompetitor({
      comp,
      competitor,
      setAlertMessage,
      isCreatingCompetitorAsManager,
    })
  }
  return validate
}
export function useSaveCompetitor({
  isEditing,
  competitorToChange,
  setMessage,
  returnFromSave,
  comp,
  team,
  isMobile,
  isCreatingCompetitorAsManager,
}) {
  const [createCompetitor] = useMutation(CREATE_COMPETITOR)
  const [editCompetitor] = useMutation(EDIT_COMPETITOR)
  const validate = useValidateCompetitor({
    competitor: competitorToChange,
    comp,
    setAlertMessage: (...props) => {
      setMessage(...props)
      returnFromSave()
    },
    isCreatingCompetitorAsManager,
  })
  async function saveCompetitor(paymentIntentId) {
    let competitor = cleanCompetitor(competitorToChange)
    if (!isMobile) {
      setMessage(isEditing ? "Saving..." : "Registering...", false, 10000)
    }
    const isValid = await validate()
    if (!isValid) {
      return
    }
    let savedCompetitor
    if (isEditing) {
      const { data, errors } = await editCompetitor({
        variables: {
          competitor: {
            compId: comp._id,
            ...competitor,
          },
        },
        refetchQueries: [
          {
            query: GET_USER_COMPETITOR_FROM_COMP,
            variables: { compId: comp._id },
          },
          {
            query: GET_CURRENT_USER_COMP_SETS,
          },
          {
            query: LoggedInUserQuery,
          },
          {
            query: GET_COMP,
            variables: { slug: comp.slug },
          },
        ],
      })
      savedCompetitor = data.editCompetitor
    } else {
      const { data } = await createCompetitor({
        variables: {
          competitor: {
            compId: comp._id,
            ...competitor,
            teamId: competitor.teamId || team?._id,
            paymentIntentId,
            isCreatingCompetitorAsManager: !!isCreatingCompetitorAsManager,
          },
        },
        refetchQueries: [
          {
            query: GET_USER_COMPETITOR_FROM_COMP,
            variables: { compId: comp._id },
          },
          {
            query: GET_CURRENT_USER_COMP_SETS,
          },
          {
            query: LoggedInUserQuery,
          },
          {
            query: GET_COMP,
            variables: { slug: comp.slug },
          },
        ],
      })
      savedCompetitor = data.createCompetitor
    }
    if (!isMobile && !!savedCompetitor?._id) {
      setMessage(isEditing ? "Saved!" : "Registration Complete!")
      setTimeout(() => {
        setMessage("")
      }, 2000)
    } else if (!savedCompetitor?._id) {
      setMessage("Something went wrong", true)
    }
    returnFromSave(savedCompetitor)
  }
  return saveCompetitor
}

export default useSaveCompetitor
