import tinycolor from "tinycolor2"

export function getBlueHues(total) {
  // was using tinycolor but breaks chromium and puppeteer for reports
  const bAr = [
    "#0da6fc",
    "#19abfc",
    "#26b0fc",
    "#32b4fd",
    "#3fb9fd",
    "#4cbdfd",
    "#58c2fd",
    "#65c7fd",
    "#71cbfd",
    "#7ed0fd",
    "#8bd4fe",
    "#97d9fe",
  ]
  const retAr = []
  for (let i = 0; i < total; i++) {
    retAr.push(bAr[i % bAr.length])
  }
  return retAr
}
// not using previous tinycolor because breaks in puppeteer and chromium
export function getGreyHues(total) {
  const bAr = [
    "#9b9b9b",
    "#a1a1a1",
    "#a8a8a8",
    "#aeaeae",
    "#b4b4b4",
    "#bbbbbb",
    "#c1c1c1",
    "#c8c8c8",
    "#cecece",
    "#d4d4d4",
    "#dbdbdb",
    "#e1e1e1",
  ]
  const retAr = []
  for (let i = 0; i < total; i++) {
    retAr.push(bAr[i % bAr.length])
  }
  return retAr
}
function stringToHslColor(str, s, l) {
  var hash = 0
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }

  var h = hash % 360
  return "hsl(" + h + ", " + s + "%, " + l + "%)"
}

export function getLightBGColor(hash) {
  // create random color from hash
  const color = tinycolor(stringToHslColor(hash, 80, 80)).toHexString()
  return tinycolor(color).toHexString()
}

export function darkify(color) {
  const hslObj = tinycolor(color).toHsl()
  hslObj.s = hslObj.s - 0.2
  hslObj.l = 1 - hslObj.l + (hslObj.l > 0.9 ? 0.05 : 0)
  return tinycolor(hslObj).toRgbString()
}
