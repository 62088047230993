import {
  Box,
  Heading,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Switch,
} from "@chakra-ui/react"
import * as React from "react"
import { CompScore, CompSet } from "../../../gql/types/graphql"

export interface IWCBoulderingPointsScoreInputProps {
  compScore: CompScore
  compSet: CompSet
  updateCompScore(compScore: CompScore): void
  updateCompScore(
    key: string,
    value: string | number | boolean | number[],
  ): void
  setMessage(message: string, isError: boolean): void
}

export default function WCBoulderingPointsScoreInput({
  compScore,
  compSet,
  updateCompScore,
  setMessage,
}: IWCBoulderingPointsScoreInputProps) {
  return (
    <Box>
      {(compSet?.zoneHoldPoints ?? []).map((zoneHoldPoint, index) => (
        <React.Fragment key={index}>
          <Heading size="sm" mb={2}>
            Attempts to zone {index + 1}
          </Heading>
          <NumberInput
            width={"70px"}
            isDisabled={(compScore.controlledZone ?? 0) < index}
            value={compScore?.attemptsToZones?.[index] ?? 1}
            min={1}
            onChange={(val) => {
              if (!compScore.userId && compSet.judgeType === "judge") {
                setMessage("You must select a competitor first.", true)
                return
              }
              const value = Math.max(
                compScore.controlledZone === index + 1 ? 1 : 0,
                val ? Number(val) : 0,
                compScore.attemptsToZones?.[index - 1] ?? 0,
              )
              const isDecrementing =
                value < ((compScore?.attemptsToZones ?? [])?.[index] ?? 0)
              updateCompScore("attemptsToZones", [
                ...(compScore.attemptsToZones ?? [])
                  .map((n) => Number(n))
                  .slice(0, index),
                value,
                ...(compScore.attemptsToZones ?? [])
                  .map((att) => {
                    return isDecrementing &&
                      (compScore.controlledZone ?? 0) <= index
                      ? value
                      : Math.max(att ?? 0, value)
                  })
                  .slice(index + 1),
              ])
              if (
                (compScore?.controlledZone ?? 0) < index + 1 ||
                value > (compScore?.attemptsToTop ?? 0)
              ) {
                updateCompScore("attemptsToTop", value)
              }
            }}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>

          <Switch
            isChecked={(compScore?.controlledZone ?? 0) >= index + 1}
            onChange={({ target: { checked } }) => {
              if (!compScore.userId && compSet.judgeType === "judge") {
                setMessage("You must select a competitor first.", true)
                return
              }
              updateCompScore("controlledZone", !!checked ? index + 1 : index)
              if (!!checked && !compScore?.attemptsToZones?.[index]) {
                updateCompScore("attemptsToZones", [
                  ...(compScore.attemptsToZones ?? [])
                    .map((n) => Number(n))
                    .slice(0, index),
                  1,
                  ...(compScore.attemptsToZones ?? [])
                    .slice(index + 1)
                    .map((v) => Math.max(v ?? 1, 1)),
                ])
                updateCompScore("attemptsToTop", 1)
              }
              if (!checked) {
                // make sure top isn't controlled if turn off
                updateCompScore("isTopControlled", false)
                // reset all attempts of this zone and top to same value as one before
                updateCompScore("attemptsToZones", [
                  ...(compScore.attemptsToZones ?? [])
                    .map((n) => Number(n))
                    .slice(0, index + 1),
                  ...(compScore.attemptsToZones ?? [])
                    .slice(index + 1)
                    .map(() =>
                      Math.max(compScore?.attemptsToZones?.[index] ?? 1, 1),
                    ),
                ])
                updateCompScore(
                  "attemptsToTop",
                  Math.max(compScore?.attemptsToZones?.[index] ?? 1, 1),
                )
              }
            }}
          >{`Did ${
            compSet.judgeType === "judge" ? "they" : "you"
          } control zone ${index + 1}?`}</Switch>
        </React.Fragment>
      ))}
      <Heading size="sm" mb={2}>
        Attempts to top
      </Heading>
      <NumberInput
        width={"70px"}
        isDisabled={
          (compScore?.controlledZone ?? 0) <
          (compSet?.zoneHoldPoints?.length ?? 0)
        }
        title="Attempts to top"
        onChange={(val) => {
          if (!compScore.userId && compSet.judgeType === "judge") {
            setMessage("You must select a competitor first.", false)
            return
          }
          updateCompScore(
            "attemptsToTop",
            Math.max(
              val ? Number(val) : 1,
              compScore?.attemptsToZones?.[
                compScore.attemptsToZones.length - 1
              ] ?? 1,
            ),
          )
        }}
        value={compScore?.attemptsToTop ?? 1}
        min={1}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <Switch
        isChecked={compScore?.isTopControlled ?? false}
        onChange={({ target: { checked: val } }) => {
          if (!compScore.userId && compSet.judgeType === "judge") {
            setMessage("You must select a competitor first.", true)
            return
          }
          updateCompScore("isTopControlled", val)
          const index = compScore?.attemptsToZones?.length ?? 0
          updateCompScore("controlledZone", index)
          console.log("attemptsToZones: ", compScore.attemptsToZones)
          console.log(
            "updating attemptsToZones: ",
            !!val
              ? compScore.attemptsToZones || Array(index).fill(1)
              : (compScore.attemptsToZones ?? []).slice(0, index),
          )
          updateCompScore(
            "attemptsToZones",
            !!val
              ? compScore.attemptsToZones || Array(index).fill(1)
              : (compScore.attemptsToZones ?? []).slice(0, index),
          )
          updateCompScore("isCreatingSend", !!val)
        }}
      >{`Did ${
        compSet.judgeType === "judge" ? "they" : "you"
      } control the top?`}</Switch>
    </Box>
  )
}
