import gql from "graphql-tag"

export const characteristicGroupFragment = gql`
  fragment characteristicGroupFragment on CharacteristicGroup {
    _id
    name
    description
    isShownOnGymPage
    image
    link
    linkTitle
    isDeleted
    characteristics {
      _id
      name
      description
      image
      isShownOnClimbPage
      isDeleted
    }
  }
`
