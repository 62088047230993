import _ from "lodash"
import { useQuery } from "@apollo/client"
import { GET_CURRENT_USER_COMP_SETS } from "../graphql/comps"

export function useCompSets(isLoggedInUser = true) {
  const {
    loading,
    error,
    data: { getCurrentUserCompSets } = {},
    refetch,
  } = useQuery(GET_CURRENT_USER_COMP_SETS, {
    fetchPolicy: "network-only",
    skip: !isLoggedInUser,
  })
  // only return ones that don't have proper judging (because only useing to get sets for competitor to log sends)
  return {
    currentCompSets: getCurrentUserCompSets?.length
      ? _.sortBy(
          getCurrentUserCompSets.filter((cS) => cS.judgeType !== "judge"),
          (cS) => new Date(cS.start).getTime(),
        )
      : [],
    refetchUserCompSets: refetch,
    loadingUserCompSets: loading,
  }
}

export default useCompSets
