// this file is not used
import { useQuery } from "@apollo/client"
import { GET_UPCOMING_USER_COMPS } from "../graphql/comps"

export function useCompSets(isLoggedInUser = true) {
  const {
    loading,
    error,
    data: { getUpcomingUserComps } = {},
    refetch,
  } = useQuery(GET_UPCOMING_USER_COMPS, {
    fetchPolicy: "network-only",
    skip: !isLoggedInUser,
  })
  return {
    upcomingComps: getUpcomingUserComps || [],
    refetchUserUpcomingComps: refetch,
    loadingUserUpcomingComps: loading,
  }
}

export default useCompSets
