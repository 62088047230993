import React, { createContext, useState } from "react"

const NavContext = createContext({})

const NavProvider = ({ children }) => {
  const [selectedNav, setSelectedNav] = useState("")
  return (
    <NavContext.Provider
      value={{
        selectedNav,
        setSelectedNav,
      }}
    >
      {children}
    </NavContext.Provider>
  )
}

export { NavContext, NavProvider }
