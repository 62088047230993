export const logout = (client) => {
  localStorage.removeItem("jsonwebtoken")
  localStorage.removeItem("loggedInUser")
  if (client) {
    window.location.replace("/login")
    client.resetStore()
  } else {
    window.location.replace("/login")
    window.location.reload()
  }
}

export default {
  logout,
}
