import gql from "graphql-tag"
import { basicUserFragment } from "../../users/graphql/basicUserFragment"
import { basicGymAreaFragment } from "../../gyms/graphql/basicGymAreaFragment"
import { basicProblemFragment } from "../../guides/graphql/basicProblemFragment"
import { basicRouteFragment } from "../../guides/graphql/basicRouteFragment"
import { basicGuideFragment } from "../../guides/graphql/basicGuideFragment"
import { basicAreaFragment } from "../../guides/graphql/basicAreaFragment"
import { basicMediaFragment } from "../../media/graphql/basicMediaFragment"

export const exerciseFragment = gql`
  fragment exerciseFragment on Exercise {
    _id
    problem {
      ...basicProblemFragment
      numUserSends
      numUserAttempts
      gymArea {
        ...basicGymAreaFragment
        gym {
          _id
          name
          slug
        }
      }
      area {
        _id
        name
        slug
      }
    }
    route {
      ...basicRouteFragment
      numUserSends
      numUserAttempts
      gymArea {
        ...basicGymAreaFragment
        gym {
          _id
          name
          slug
        }
      }
      area {
        _id
        name
        slug
      }
    }
    exerciseType
    name
    description
    grade
    climbType
    repetitions
    duration
    restTime
    videoExerciseTypes
    image
    youtubeId
    videoLink
  }
  ${basicProblemFragment}
  ${basicRouteFragment}
  ${basicGymAreaFragment}
`
export const workoutFragment = gql`
  fragment workoutFragment on Workout {
    _id
    slug
    type
    name
    description
    creator {
      ...basicUserFragment
    }
    gym {
      _id
      slug
      name
      isOverlappingGrades
      isShowingGrades
      problemGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
      routeGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
    }
    gymAreas {
      ...basicGymAreaFragment
    }
    guide {
      ...basicGuideFragment
    }
    areas {
      ...basicAreaFragment
    }
    exercises {
      ...exerciseFragment
    }
    customExercises {
      ...exerciseFragment
    }
    maxProblemGrade
    maxRouteGrade
    totalTime
    pebbleIntensity
    isArchived
    isPublic
    isFeatured
    isTimed
    created
    popularity
    taggedCharacteristics
    isCharCircuit
    circuitType
    image
    youtubeId
    videoLink
  }
  ${basicUserFragment}
  ${basicGymAreaFragment}
  ${basicGuideFragment}
  ${basicAreaFragment}
  ${exerciseFragment}
`

export const GET_WORKOUT = gql`
  query GetWorkout($id: String, $slug: String) {
    getWorkout(id: $id, slug: $slug) {
      ...workoutFragment
    }
  }
  ${workoutFragment}
`

export const GET_CUSTOM_WORKOUT = gql`
  query GetCustomWorkout(
    $id: String
    $slug: String
    $maxProblemGrade: Float
    $maxRouteGrade: Float
  ) {
    getWorkout(
      id: $id
      slug: $slug
      maxProblemGrade: $maxProblemGrade
      maxRouteGrade: $maxRouteGrade
    ) {
      ...workoutFragment
    }
  }
  ${workoutFragment}
`

export const GET_WORKOUTS = gql`
  query GetWorkouts(
    $query: WorkoutQueryInput!
    $sortValue: String
    $searchStr: String
    $cursor: String
  ) {
    getWorkouts(
      query: $query
      sortValue: $sortValue
      searchStr: $searchStr
      cursor: $cursor
    ) {
      items {
        ...workoutFragment
      }
      cursor
      hasMore
    }
  }
  ${workoutFragment}
`

export const CREATE_WORKOUT = gql`
  mutation CreateWorkout($workout: WorkoutInput!) {
    createWorkout(workout: $workout) {
      ...workoutFragment
    }
  }
  ${workoutFragment}
`

export const EDIT_WORKOUT = gql`
  mutation EditWorkout(
    $workout: WorkoutInput!
    $maxProblemGrade: Float
    $maxRouteGrade: Float
  ) {
    editWorkout(
      workout: $workout
      maxProblemGrade: $maxProblemGrade
      maxRouteGrade: $maxRouteGrade
    ) {
      ...workoutFragment
    }
  }
  ${workoutFragment}
`

export const DELETE_WORKOUT = gql`
  mutation DeleteWorkout($id: String!) {
    deleteWorkout(id: $id) {
      ...workoutFragment
    }
  }
  ${workoutFragment}
`

export const GET_WORKOUT_GRADE_DISTRIBUTION = gql`
  query getWorkoutGradeDistribution($slug: String!) {
    getWorkout(slug: $slug) {
      _id
      type
      name
      gym {
        _id
        problemGradeColors {
          color
          name
          minGrade
          maxGrade
          image
        }
        routeGradeColors {
          color
          name
          minGrade
          maxGrade
          image
        }
      }
      exercises {
        ...exerciseFragment
      }
    }
  }
  ${exerciseFragment}
`

export const GET_GUIDES_WITH_CIRCUITS = gql`
  query GetGuidesWithCircuits($searchStr: String) {
    getGuidesWithCircuits(searchStr: $searchStr) {
      guides {
        ...basicGuideFragment
        numWorkouts
      }
    }
  }
  ${basicGuideFragment}
`

export const GET_NUM_CLIMBS_WITH_CHARS = gql`
  query GetNumClimbsWithChars(
    $climbType: String!
    $gymId: String!
    $chars: String!
  ) {
    getNumClimbsWithChars(climbType: $climbType, gymId: $gymId, chars: $chars) {
      numClimbs
    }
  }
`

export const CREATE_WORKOUT_FROM_SESSION = gql`
  mutation CreateWorkoutFromSession($sessionId: String!) {
    createWorkoutFromSession(sessionId: $sessionId) {
      ...workoutFragment
    }
  }
  ${workoutFragment}
`

export const GET_GYMS_COACHES = gql`
  query GetGymsCoaches {
    getGymsCoaches {
      coachUsers {
        ...basicUserFragment
      }
    }
  }
  ${basicUserFragment}
`

export const RECOMMENDED_CIRCUITS = gql`
  query RecommendedCircuits {
    recommendedCircuits {
      lastSessionType
      circuits {
        session {
          _id
          type
          user {
            ...basicUserFragment
          }
          gym {
            _id
            name
            slug
            logo
            popMedia {
              ...basicMediaFragment
            }
          }
        }
      }
    }
  }
  ${basicUserFragment}
  ${basicMediaFragment}
`
