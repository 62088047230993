import React, { useState } from "react"
import { Avatar, Flex, Heading, IconButton, useTheme } from "@chakra-ui/react"
import Card from "../../common/components/Card"
import { useMutation } from "@apollo/client"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Tooltip from "../../common/components/Tooltip"
import { faPencilAlt, faTimes } from "@fortawesome/pro-light-svg-icons"
import {
  REMOVE_FROM_COMP,
  GET_USER_COMPETITOR_FROM_COMP,
} from "../graphql/comps"
import { Box } from "@chakra-ui/react"
import ConfirmModal from "../../common/components/ConfirmModal"
import IndividualRegistrationModal from "./IndividualRegistrationModal"
import { faStar } from "@fortawesome/pro-solid-svg-icons"
import useColors from "../../common/hooks/useColors"

const CompetitorCard = ({
  comp = {},
  competitor,
  isAdmin = false,
  openRegistration = () => null,
  loggedInUser = null,
  isShowingLeave = false,
  setAlertMessage = () => null,
  size = "100px",
}) => {
  const { blueColor } = useColors()
  const [removeFromComp] = useMutation(REMOVE_FROM_COMP)

  const [isConfirmLeave, setIsConfirmLeave] = useState(false)
  const [isEditingCompetitor, setIsEditingCompetitor] = useState(false)

  const isNonAdminRelativeCompetitor =
    !isAdmin && isShowingLeave && comp.isRelativeScoringEnabled
  const isBeforeRegistrationEnds =
    !!comp && Date.parse(comp.endRegistrationDate) > Date.now()
  const isTeamAdmin = competitor?.team?.admin?._id === competitor?.user?._id
  return (
    <>
      <Card
        height={size}
        m={0}
        p={0}
        display="flex"
        alignItems="center"
        pr={5}
        overflow="hidden"
      >
        {!!isTeamAdmin && (
          <Box
            zIndex={1}
            position="absolute"
            top={0}
            left={size}
            ml={-3}
            data-tip
            data-tooltip-id={`team-captain-${competitor._id}`}
          >
            <FontAwesomeIcon color={blueColor} icon={faStar} size="lg" />
          </Box>
        )}
        <Avatar
          height={size}
          width={size}
          borderRadius={0}
          name={competitor.user.name}
          src={competitor.user?.profile?.picture}
        />
        <Flex
          flex={1}
          alignItems="flex-start"
          ml={3}
          direction="column"
          justifyContent="space-between"
        >
          {competitor?.user?._id === loggedInUser?._id ? (
            <>
              <Heading mb={1} size="md" as="h5" noOfLines={2}>
                {competitor.name}
              </Heading>
              <Heading islight="true" as="h6" size="xs" noOfLines={1} mb={0}>
                Scoring Code:{"\n "}
              </Heading>
              <Heading islight="true" as="h6" size="xs" noOfLines={1} mb={0}>
                <strong>{`${competitor.slug}-${competitor._id.slice(
                  -1,
                )}`}</strong>
              </Heading>
              {!!competitor.timeSlot && (
                <Heading islight="true" as="h6" size="xs" noOfLines={1} mb={0}>
                  {competitor.timeSlot.name}
                </Heading>
              )}
              {!!competitor.category && (
                <Heading islight="true" as="h6" size="xs" noOfLines={1} mb={0}>
                  Category: {competitor.category}
                </Heading>
              )}
            </>
          ) : (
            <Heading size="sm" as="h5" noOfLines={2} mb={0}>
              {competitor.name}
            </Heading>
          )}
        </Flex>
        <Box
          position="absolute"
          top={-1}
          right={-1}
          display="flex"
          justifyContent={"flex-end"}
        >
          {((isAdmin && openRegistration) ||
            isNonAdminRelativeCompetitor ||
            !comp.isTeamComp) &&
          competitor?.user?._id === loggedInUser?._id ? (
            <Box>
              <IconButton
                variant="text"
                onClick={() => setIsEditingCompetitor(true)}
              >
                <FontAwesomeIcon
                  color={blueColor}
                  icon={faPencilAlt}
                  size="xs"
                />
              </IconButton>
            </Box>
          ) : null}
          {/* {isShowingLeave && isBeforeRegistrationEnds && ( */}
          <Box ml={-3}>
            <IconButton
              variant="text"
              data-tip
              data-tooltip-id={"leave-comp"}
              onClick={() => setIsConfirmLeave(true)}
            >
              <FontAwesomeIcon color={blueColor} icon={faTimes} size="1x" />
            </IconButton>
          </Box>
          {/* )} */}
        </Box>
      </Card>
      <ConfirmModal
        isOpen={isConfirmLeave}
        title={`Are you sure you want to unregister for ${comp.name}?`}
        description={
          comp?.paymentType === "stripe" &&
          !comp?.isChargingTeam &&
          "This will refund your card the cost of the competition and unregister you from the comp."
        }
        actionString={"Unregister"}
        action={async () => {
          await removeFromComp({
            variables: {
              competitorId: competitor._id,
            },
            refetchQueries: [
              {
                query: GET_USER_COMPETITOR_FROM_COMP,
                variables: { compId: comp?._id },
              },
            ],
          })
        }}
        closeModal={() => {
          setIsConfirmLeave(false)
        }}
      />
      {isShowingLeave && (
        <IndividualRegistrationModal
          isOpen={isEditingCompetitor}
          closeModal={() => setIsEditingCompetitor(false)}
          loggedInUser={loggedInUser}
          comp={comp}
          competitor={competitor}
          setAlertMessage={setAlertMessage}
        />
      )}
      <Tooltip class="card-tooltip" id={"leave-comp"}>
        <h6 className="no-wrap">Unregister for competition</h6>
      </Tooltip>
      <Tooltip class="card-tooltip" id={`team-captain-${competitor._id}`}>
        <h6 className="no-wrap">Team Captain</h6>
      </Tooltip>
    </>
  )
}

export default CompetitorCard
