import React, { memo } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons"
import { Box, useTheme } from "@chakra-ui/react"
import Container from "./Container"
import useColors from "../../common/hooks/useColors"

const Loader = ({ isRelative = false, size = "", color = "" }) => {
  const { blueColor } = useColors()
  return isRelative ? (
    <Box
      display="flex"
      width="100%"
      key="loader"
      alignItems="center"
      justifyContent="center"
    >
      <FontAwesomeIcon
        color={color === "" ? blueColor : color}
        icon={faSpinnerThird}
        spin
        size={size === "" ? "3x" : size}
      />
    </Box>
  ) : (
    <Container
      isFull
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        flex={1}
        alignSelf="stretch"
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight={"50vh"}
      >
        <FontAwesomeIcon
          color={color === "" ? blueColor : color}
          icon={faSpinnerThird}
          spin
          size={size === "" ? "6x" : size}
        />
      </Box>
    </Container>
  )
}

export default memo(Loader, () => true)
