import { Component } from "react"
import { withRouter } from "next/router"

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    const path1 = this.props.router.pathname?.split("?")[0]
    const path2 = prevProps.pathname?.split("?")[0]
    if (path2 && path1 !== path2) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return null
  }
}

export default withRouter(ScrollToTop)
