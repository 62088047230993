import _ from "lodash"
import { useState, useEffect } from "react"

export function useUpdateObject(obj, setIsDirty = () => {}, ignoreSlug) {
  const [updatedObject, setUpdatedObject] = useState(obj)

  useEffect(() => {
    // may be a better way to compare, but without this, the object can be a stale value
    // noticed when creating a send from a SendRowButton and then immediately Editing the same send.
    if (
      (obj?.slug && obj.slug !== updatedObject?.slug && !ignoreSlug) ||
      (obj?.type && obj.type !== updatedObject?.type) ||
      (obj?._id && obj._id !== updatedObject?._id)
    ) {
      setUpdatedObject(obj)
    }
  }, [
    obj,
    updatedObject?.slug,
    updatedObject?.type,
    updatedObject?._id,
    ignoreSlug,
  ])

  function updateObject(key, val) {
    setIsDirty(true)
    let updatedObj
    if (_.isObject(key)) {
      setUpdatedObject((obj) => {
        updatedObj = {
          ...obj,
          ...key,
        }
        return updatedObj
      })
    } else {
      setUpdatedObject((obj) => {
        updatedObj = {
          ...obj,
          [key]: val,
        }
        return updatedObj
      })
    }
    return updatedObj
  }
  return { updatedObject, updateObject }
}

export default useUpdateObject
