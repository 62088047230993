import { Box, Flex, Switch, useColorMode } from "@chakra-ui/react"
import { faMoon, faSunBright } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as React from "react"
import useColors, { isDarkModeAtom } from "../hooks/useColors"
import { useAtom } from "jotai"

export interface IDarkModeToggleProps {}

export default function DarkModeToggle(props: IDarkModeToggleProps) {
  const [isDarkMode, setIsDarkMode] = useAtom(isDarkModeAtom)
  const { headingColor } = useColors(isDarkMode ? "dark" : "light")

  return (
    <Flex align="center">
      <FontAwesomeIcon size="lg" icon={faSunBright} color={headingColor} />
      <Switch
        mt={2}
        ml={4}
        size="lg"
        aria-label="Dark mode toggle"
        isChecked={isDarkMode}
        onChange={({ target: { checked } }) => {
          console.log("Dark mode toggle:", checked)
          localStorage.setItem("darkMode", checked.toString())
          setIsDarkMode(checked)
        }}
      />
      <FontAwesomeIcon size="lg" icon={faMoon} color={headingColor} />
    </Flex>
  )
}
