import React, { useState } from "react"
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { useMutation } from "@apollo/client"
import theme from "../../theme"
import { Button, Heading, useTheme } from "@chakra-ui/react"
import Card from "../../common/components/Card"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { Box } from "@chakra-ui/react"
import { stripePublicKey, stripePublicKeyTest } from "../../config/platforms"
import { CREATE_CONNECTED_PAYMENT_INTENT } from "../graphql/billing"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons"
import useColors from "../../common/hooks/useColors"

const stripePromise = loadStripe(
  process.env.NODE_ENV === "production" ? stripePublicKey : stripePublicKeyTest,
)

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: theme.fonts.headingFont,
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
}
const CompCheckoutForm = ({
  stripeConnectedAccountId,
  setAlertMessage,
  saveCompetitor,
  closeModal = () => {},
  cost,
  errorMsg,
  compName,
  gymName,
  // userEmail,
  validateSignup,
}) => {
  const { whiteColor } = useColors()
  const stripe = useStripe()
  const elements = useElements()
  const [isLoading, setIsLoading] = useState(false)
  const [isSaved, setIsSaved] = useState(false)

  const [createConnectedPaymentIntent] = useMutation(
    CREATE_CONNECTED_PAYMENT_INTENT,
  )

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault()
    const isValid = await validateSignup()
    if (!isValid) {
      setIsLoading(false)
      return
    }
    if (errorMsg) {
      setAlertMessage(errorMsg, true)
      return
    }
    setIsLoading(true)

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement)
    cardElement.on("change", ({ error }) => {
      if (error) {
        setAlertMessage(error.message, true)
      }
    })
    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    })
    if (error) {
      setIsLoading(false)
      setAlertMessage(error.message, true)
      return
    }
    const { data: intentData } = await createConnectedPaymentIntent({
      variables: {
        cost: cost * 100,
        stripeConnectedAccountId,
        description: `${compName} at ${gymName} comp registration`,
      },
    })
    if (!intentData.createConnectedPaymentIntent) {
      setIsLoading(false)
      setAlertMessage("Something went wrong. Please try again later.", true)
      return
    }
    const { paymentIntentClientSecret } =
      intentData.createConnectedPaymentIntent
    if (paymentIntentClientSecret) {
      const result = await stripe.confirmCardPayment(
        paymentIntentClientSecret,
        {
          payment_method: { card: cardElement },
          return_url: window.location.href,
        },
      )
      if (result.error) {
        setAlertMessage(
          result.error.code === "card_declined"
            ? "We're sorry, this card has been declined. Please try with a different card."
            : "There was an error processing your card. Please try again or try a different card.",
          true,
        )
        setIsLoading(false)
        return
      }
      // The payment has been processed!
      if (result.paymentIntent.status === "requires_capture") {
        // success
        const res = await saveCompetitor(result.paymentIntent.id)
        setIsLoading(false)
        if (!res?._id) {
          return
        }
        setIsSaved(true)
        setTimeout(() => setIsSaved(false), 2000)
        closeModal()
        return
      }

      setAlertMessage("Something went wrong. Please try again")
      setIsLoading(false)
      return
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <label>
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </label>
      </Card>
      <Box display="flex" mt={5}>
        {isSaved ? (
          <Heading as="h4" size="md">
            You’re registered!
          </Heading>
        ) : (
          <Button flex={1} variant="primary" type="submit" disabled={!stripe}>
            {isLoading ? (
              <FontAwesomeIcon color={whiteColor} icon={faSpinnerThird} spin />
            ) : (
              "Register"
            )}
          </Button>
        )}
      </Box>
    </form>
  )
}

const FormWrapper = (props) => (
  <Elements stripe={stripePromise}>
    <CompCheckoutForm {...props} />
  </Elements>
)

export default FormWrapper
