import { Heading } from "@chakra-ui/react"
import React from "react"
import styles from "../../users/components/css/UserSearch.module.css"

const CompClimbRow = ({ climb, onClick, isBottom }) => {
  return (
    <div
      className={styles[isBottom ? "search-item-bottom" : "search-item"]}
      onClick={onClick}
    >
      {climb.picture && (
        <img
          className={styles["user-img"]}
          alt={climb.name}
          src={climb.picture}
        />
      )}
      <Heading as="h5" size="sm" variant="link">
        {climb.name}
      </Heading>
    </div>
  )
}

export default CompClimbRow
