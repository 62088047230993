import gql from "graphql-tag"
import { basicMediaFragment } from "../../media/graphql/basicMediaFragment"

export const basicGuideFragment = gql`
  fragment basicGuideFragment on Guide {
    _id
    slug
    name
    type
    location {
      coordinates
    }
    popMedia {
      ...basicMediaFragment
    }
    numProblems
    numRoutes
  }
  ${basicMediaFragment}
`

export default {
  basicGuideFragment,
}
