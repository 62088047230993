import React from "react"
import {
  FormControl,
  Radio,
  Input,
  Heading,
  Flex,
  Textarea,
  RadioGroup,
  Stack,
} from "@chakra-ui/react"
import { Box } from "@chakra-ui/react"

const CompetitorQuestion = ({
  question: { _id, title, questionType, options, isRequired, isYesRequired },
  answer,
  setAnswer,
}) => {
  return (
    <Box mb={6} key={_id}>
      <Heading size="sm" as="h6">
        {title}
      </Heading>
      {questionType === "text" && (
        <Box display="flex">
          <Textarea
            value={answer || ""}
            onChange={(e) => {
              setAnswer(e.target.value)
            }}
          />
        </Box>
      )}
      {questionType === "yesOrNo" && (
        <>
          {!!isYesRequired && (
            <Heading size="sm" as="h6" variant="error">
              You are required to answer “Yes” to this question in order to
              compete in this competition.
            </Heading>
          )}
          <RadioGroup onChange={setAnswer} value={answer}>
            <Flex align="center" wrap="wrap">
              <Radio size="lg" value="yes">
                Yes
              </Radio>
              <Radio size="lg" value="no">
                No
              </Radio>
            </Flex>
          </RadioGroup>
        </>
      )}
      {questionType === "multipleChoice" && (
        <RadioGroup onChange={setAnswer} value={answer}>
          <Flex align="center" wrap="wrap">
            {options.map((option) => (
              <Radio key={option} size="lg" value={option}>
                {option}
              </Radio>
            ))}
          </Flex>
        </RadioGroup>
      )}
    </Box>
  )
}

export default CompetitorQuestion
