const showGymSidebarPaths = [
  "/gyms/",
  "/edit/gym/",
  "/edit/gympermissions",
  "/dashboards",
  "/settinganalytics",
  "/setting-scheduler",
  "/setting-distribution",
  "/gym-labels",
  "/gym-scanners",
  "/member-dashboards/",
  "/billing-settings",
  "/edit/gym-topo-map/",
]
const excludeGymSidebarPaths = ["/create/comp/gyms/"]

const showCompSidebarPaths = [
  "/comps/",
  "/edit/comp",
  "/manage-competitors",
  "/download-scores",
  "/download-report",
]
export default function useHasSidebar(pathname: string) {
  if (
    showGymSidebarPaths.some((path) => pathname.includes(path)) &&
    !excludeGymSidebarPaths.some((path) => pathname.includes(path))
  ) {
    return "GYM"
  }
  if (showCompSidebarPaths.some((path) => pathname.includes(path))) {
    return "COMP"
  }
  return null
}
