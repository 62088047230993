import React, { useState } from "react"
import { useQuery } from "@apollo/client"
import { SEARCH_COMPETITORS } from "../graphql/comps"
import { Input } from "@chakra-ui/react"
import Loader from "../../common/components/Loader"
import CompetitorRow from "./CompetitorRow"
import Card from "../../common/components/Card"

const CompetitorSearch = ({
  title,
  compId,
  setCompetitor,
  isClearing = true,
}) => {
  const [searchStr, setSearchStr] = useState("")
  const { data, loading, error } = useQuery(SEARCH_COMPETITORS, {
    variables: {
      searchStr,
      compId,
    },
    skip: !compId || (searchStr.length < 3 && searchStr.slice(-1) !== " "),
  })

  const competitors = data?.searchCompetitors?.competitors || []

  return (
    <Card variant="input">
      <Input
        autoComplete="off"
        placeholder={title}
        value={searchStr}
        onChange={(e) => {
          setSearchStr(e.target.value)
        }}
      />
      {loading ? (
        <Loader isRelative size={"3x"} />
      ) : (
        competitors.map((competitor) => (
          <CompetitorRow
            key={competitor._id}
            competitor={competitor}
            onClick={() => {
              setCompetitor(competitor)
              if (isClearing) {
                setSearchStr("")
              }
            }}
          />
        ))
      )}
    </Card>
  )
}

export default CompetitorSearch
