import React from "react"
import { useMutation } from "@apollo/client"
import { unfollowMutate } from "../graphql/users"

const Unfollow = (props) => {
  const { loggedInUser, user, render } = props

  const [unfollow] = useMutation(unfollowMutate)

  if (!loggedInUser || !loggedInUser.slug || loggedInUser.slug === user.slug) {
    return null
  }

  return render({
    unfollow,
  })
}

export default Unfollow
