import { useMutation } from "@apollo/client"
import { Flex, Button, Heading, useTheme } from "@chakra-ui/react"
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import {
  RECALCULATE_SCORE_FROM_COMPETITOR,
  GET_COMP_COMPETITOR_IDS,
} from "../graphql/comps"
import useColors from "../../common/hooks/useColors"

type Props = {
  title?: string
  size?: string
  compId?: string
  compSetId?: string
  competitorId?: string
}

const RecalcRelativeScoresButton = ({
  title = "Recalculate Competitors’ Scores",
  size = "md",
  compId,
  compSetId,
  competitorId,
}: Props): React.ReactElement => {
  const { blueColor } = useColors()
  const [isRecalculatingBumped, setIsRecalculatingBumped] =
    React.useState(false)
  const [totalCompetitorIds, setTotalCompetitorIds] = React.useState(0)
  const [currRecalc, setCurrRecalc] = React.useState(0)
  const [recalculateCompetitorScore] = useMutation(
    RECALCULATE_SCORE_FROM_COMPETITOR,
  )
  const [getCompCompetitors] = useMutation(GET_COMP_COMPETITOR_IDS)
  return (
    <Flex align="center">
      <Button
        size={size}
        variant="secondary"
        isLoading={isRecalculatingBumped}
        spinner={<FontAwesomeIcon icon={faSpinnerThird} color={blueColor} />}
        onClick={async () => {
          setCurrRecalc(0)
          setIsRecalculatingBumped(true)
          const res = !!compId
            ? await getCompCompetitors({
                variables: { compId },
              })
            : null
          setTotalCompetitorIds(res ? res.data.getCompCompetitorIds.length : 1)
          const competitorIds = !!compId
            ? res?.data?.getCompCompetitorIds ?? []
            : [competitorId]
          for (const competitor of competitorIds) {
            await recalculateCompetitorScore({
              variables: {
                competitorId: competitor._id,
                compSetId,
              },
            })
            setCurrRecalc((cR) => cR + 1)
          }
          setIsRecalculatingBumped(false)
        }}
      >
        {title}
      </Button>
      {!!isRecalculatingBumped && (
        <Heading mb={0} ml={5} size="sm" as="h5">
          Recalculating {currRecalc} of {totalCompetitorIds}
        </Heading>
      )}
    </Flex>
  )
}

export default RecalcRelativeScoresButton
