import React, { useState, useEffect } from "react"
import { Box, Flex, Heading, IconButton, useToast } from "@chakra-ui/react"
import useColors from "../../common/hooks/useColors"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle, faTimes } from "@fortawesome/pro-light-svg-icons"
import Card from "../../common/components/Card"

const ErrorAlert = ({ useApolloNetworkStatus }) => {
  let status
  status = useApolloNetworkStatus()
  const toast = useToast()
  const [isFullscreen, setIsFullscreen] = useState(false)
  const { orangeColor, whiteColor } = useColors()
  useEffect(() => {
    const fullscreenHandler = () => {
      // document.fullscreenElement will point to the element that
      // is in fullscreen mode if there is one. If not, the value
      // of the property is null.
      if (document.fullscreenElement) {
        setIsFullscreen(true)
      } else {
        setIsFullscreen(false)
      }
    }
    document
      .getElementById("fullscreen-ele")
      ?.addEventListener("fullscreenchange", fullscreenHandler)
    return () => {
      try {
        document
          .getElementById("fullscreen-ele")
          ?.removeEventListener("fullscreenchange", fullscreenHandler)
      } catch (err) {}
    }
  }, [])
  const [error, setError] = useState(null)
  useEffect(() => {
    const error = status.queryError || status.mutationError
    setError(error)
  }, [status.queryError, status.mutationError])

  const message =
    error &&
    error.graphQLErrors &&
    error.graphQLErrors[0].message?.split("GraphQL")?.[0]
  const snackToastId = "errorToast"
  useEffect(() => {
    if (message && !toast.isActive(snackToastId)) {
      toast({
        id: snackToastId,
        isClosable: true,
        duration: 50000000,
        onCloseComplete: () => {
          setError(null)
        },
        render: ({ onClose }) => (
          // <Portal>
          <Box
            pointerEvents={"box-none"}
            position={"fixed"}
            zIndex={10001}
            top={window.innerHeight - 68}
            left={"50%"}
            width={470}
            marginLeft={-235}
          >
            <Card
              raised="true"
              borderColor={orangeColor}
              backgroundColor={orangeColor}
            >
              <Flex align="center" justify="center">
                <FontAwesomeIcon
                  size="2x"
                  icon={faInfoCircle}
                  color={whiteColor}
                />
                <Heading size="sm" ml={3} mr={3} mb={0} color={whiteColor}>
                  {message}
                </Heading>
                <IconButton
                  variant="transparent"
                  icon={<FontAwesomeIcon size="2x" icon={faTimes} />}
                  onClick={onClose}
                  color={whiteColor}
                />
              </Flex>
            </Card>
          </Box>
          // </Portal>
        ),
      })
    }
  })
  // too much going on with the loader
  // const isLoading =
  //   status.numPendingQueries > 0 || status.numPendingMutations > 0
  // if fullscreen, then leaderboard and don't throw error in case blip in connection
  if (isFullscreen) {
    return null
  }
  if (error && !error.graphQLErrors?.length) {
    return null
  }

  return null
}

ErrorAlert.propTypes = {}

export default ErrorAlert
