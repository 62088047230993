import { basicGymFragment } from "../../gyms/graphql/basicGymFragment"
import { basicGymAreaFragment } from "../../gyms/graphql/basicGymAreaFragment"
import gql from "graphql-tag"
import { basicProblemFragment } from "../../guides/graphql/basicProblemFragment"
import { basicRouteFragment } from "../../guides/graphql/basicRouteFragment"
import { basicMediaFragment } from "../../media/graphql/basicMediaFragment"

export const basicScannerActivityFragment = gql`
  fragment basicScannerActivityFragment on ScannerActivity {
    _id
    name
    description
    points
    minLogRate
    gym {
      _id
      slug
      isShowingScannerPoints
      name
    }
    numScanners
    isSend
    isAttempt
    isUnassigned
    image
  }
`

export const scannerFragment = gql`
  fragment scannerFragment on Scanner {
    _id
    name
    lastScannedAt
    device_mac
    errorCode
    batteryLevel
    firmwareVersion
    latestFirmwareVersion
    rssi
    problem {
      ...basicProblemFragment
      gymArea {
        _id
        slug
        name
        popMedia {
          ...basicMediaFragment
        }
      }
    }
    route {
      ...basicRouteFragment
      gymArea {
        _id
        slug
        name
        popMedia {
          ...basicMediaFragment
        }
      }
    }
    possibleGymArea {
      _id
      slug
      name
      popMedia {
        ...basicMediaFragment
      }
    }
    gym {
      ...basicGymFragment
      isShowingScannerPoints
    }
    activity {
      ...basicScannerActivityFragment
    }
  }
  ${basicProblemFragment}
  ${basicRouteFragment}
  ${basicGymFragment}
  ${basicScannerActivityFragment}
  ${basicMediaFragment}
`

export const GET_SCANNERS = gql`
  query GetScanners(
    $cursor: String
    $sortValue: String
    $isFilteringUnassigned: Boolean
  ) {
    getScanners(
      cursor: $cursor
      sortValue: $sortValue
      isFilteringUnassigned: $isFilteringUnassigned
    ) {
      cursor
      hasMore
      numErrorScanners
      numClimbsWithoutScanners
      scanners {
        _id
        name
        lastScannedAt
        device_mac
        errorCode
        batteryLevel
        firmwareVersion
        latestFirmwareVersion
        rssi
        activity {
          ...basicScannerActivityFragment
        }
        possibleGymArea {
          _id
          slug
          name
        }
        gym {
          _id
          slug
          name
        }
        problem {
          _id
          name
          slug
          type
          setterGrade
          grade
          gradeColor
          gradeName
          isIndoor
          picture
          color
          gym {
            _id
            slug
            isShowingGrades
            isShowingOutdoorRouteGradeForm
            isOverlappingGrades
            hasScanners
            isTopoMapStarted
            isTopoMapEnabled
            problemGradeColors {
              color
              name
              minGrade
              maxGrade
              image
            }
          }
          gymArea {
            _id
            slug
            name
          }
        }
        route {
          _id
          name
          slug
          type
          setterGrade
          grade
          gradeColor
          gradeName
          isIndoor
          picture
          color
          gym {
            _id
            slug
            isShowingGrades
            isShowingOutdoorRouteGradeForm
            isOverlappingGrades
            hasScanners
            isTopoMapStarted
            isTopoMapEnabled
            routeGradeColors {
              color
              name
              minGrade
              maxGrade
              image
            }
          }
          gymArea {
            _id
            slug
            name
          }
        }
      }
    }
  }
  ${basicScannerActivityFragment}
`

export const GET_CLIMBS_WITHOUT_SCANNERS = gql`
  query GetClimbsWithoutScanners($gymId: String, $climbType: String!) {
    getClimbsWithoutScanners(gymId: $gymId, climbType: $climbType) {
      problems {
        ...basicProblemFragment
      }
      routes {
        ...basicRouteFragment
      }
    }
  }
  ${basicProblemFragment}
  ${basicRouteFragment}
`

export const GET_SCANNER = gql`
  query GetScanner($id: String, $device_mac: String) {
    getScanner(id: $id, device_mac: $device_mac) {
      ...scannerFragment
    }
  }
  ${scannerFragment}
`

export const GET_SCANNER_WITH_GYMS_AREAS = gql`
  query GetScannerWithGymsAreas($id: String, $device_mac: String) {
    getScanner(id: $id, device_mac: $device_mac) {
      ...scannerFragment
      gym {
        ...basicGymFragment
        gymAreas {
          ...basicGymAreaFragment
        }
      }
    }
  }
  ${scannerFragment}
  ${basicGymFragment}
  ${basicGymAreaFragment}
`

export const GET_GYM_SCANNERS = gql`
  query GetGymScanners($slug: String!) {
    gym(slug: $slug) {
      _id
      slug
      name
      type
      hasScanners
      hasPremiumPlan
      isTopoMapStarted
      isTopoMapEnabled
      isShowingScannerPoints
      scanners {
        _id
        name
        lastScannedAt
        device_mac
        errorCode
        batteryLevel
        firmwareVersion
        latestFirmwareVersion
        activity {
          ...basicScannerActivityFragment
        }
        rssi
        problem {
          ...basicProblemFragment
          gymArea {
            _id
            slug
            name
          }
        }
        route {
          ...basicRouteFragment
          gymArea {
            _id
            slug
            name
          }
        }
        possibleGymArea {
          _id
          slug
          name
          popMedia {
            ...basicMediaFragment
          }
        }
      }
      scannerActivities {
        ...basicScannerActivityFragment
      }
    }
  }
  ${basicProblemFragment}
  ${basicRouteFragment}
  ${basicScannerActivityFragment}
`
export const GET_GYM_AREA_SCANNERS = gql`
  query GetGymAreaScanners($slug: String!) {
    gymArea(slug: $slug) {
      _id
      slug
      name
      type
      scanners {
        _id
        lastScannedAt
        device_mac
        errorCode
        batteryLevel
        firmwareVersion
        latestFirmwareVersion
        problem {
          ...basicProblemFragment
        }
        route {
          ...basicRouteFragment
        }
      }
    }
  }
  ${basicProblemFragment}
  ${basicRouteFragment}
`

export const ENABLE_WRISTBAND_DISCOVERY = gql`
  query EnableWristbandDiscovery {
    enableWristbandDiscovery {
      isSuccessful
    }
  }
`
export const DISABLE_WRISTBAND_DISCOVERY = gql`
  query DisableWristbandDiscovery {
    disableWristbandDiscovery {
      isSuccessful
    }
  }
`

export const WRISTBAND_DISCOVERY = gql`
  query WristbandDiscovery {
    wristbandDiscovery {
      scannerId
      error
    }
  }
`

export const CREATE_SCANNER_ACTIVITY = gql`
  mutation CreateScannerActivity($activity: ScannerActivityInput!) {
    createScannerActivity(activity: $activity) {
      ...basicScannerActivityFragment
    }
  }
  ${basicScannerActivityFragment}
`

export const EDIT_SCANNER_ACTIVITY = gql`
  mutation EditScannerActivity($activity: ScannerActivityInput!) {
    editScannerActivity(activity: $activity) {
      ...basicScannerActivityFragment
    }
  }
  ${basicScannerActivityFragment}
`

export const DELETE_SCANNER_ACTIVITY = gql`
  mutation DeleteScannerActivity($activityId: String!) {
    deleteScannerActivity(activityId: $activityId) {
      _id
    }
  }
`

export const EDIT_SCANNER = gql`
  mutation EditScanner($scanner: ScannerInput!) {
    editScanner(scanner: $scanner) {
      ...scannerFragment
    }
  }
  ${scannerFragment}
`

export const CLEAR_SCANNER_ERROR = gql`
  mutation ClearScannerError($scannerId: String!) {
    clearScannerError(scannerId: $scannerId) {
      ...scannerFragment
    }
  }
  ${scannerFragment}
`

export const CLEAR_ALL_SCANNER_ERRORS = gql`
  mutation ClearAllScannerErrors {
    clearAllScannerErrors {
      isSuccessful
    }
  }
`

export const REPORT_BROKEN_SCANNER = gql`
  mutation ReportBrokenScanner(
    $scannerId: String
    $problemId: String
    $routeId: String
  ) {
    reportBrokenScanner(
      scannerId: $scannerId
      problemId: $problemId
      routeId: $routeId
    ) {
      ...scannerFragment
    }
  }
  ${scannerFragment}
`

export const SCANNER_HISTORY = gql`
  query ScannerHistory($id: String!) {
    getScanner(id: $id) {
      _id
      name
      type
      lastScannedAt
      device_mac
      errorCode
      batteryLevel
      firmwareVersion
      latestFirmwareVersion
      rssi
      activity {
        ...basicScannerActivityFragment
      }
      problem {
        ...basicProblemFragment
        gymArea {
          _id
          slug
          name
        }
      }
      route {
        ...basicRouteFragment
        gymArea {
          _id
          slug
          name
        }
      }
      possibleGymArea {
        _id
        slug
        name
        popMedia {
          ...basicMediaFragment
        }
      }
      history {
        _id
        batteryLevel
        rssi
        firmwareVersion
        date
        rfid
      }
    }
  }
  ${basicProblemFragment}
  ${basicRouteFragment}
  ${basicScannerActivityFragment}
  ${basicMediaFragment}
`
