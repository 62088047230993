import React from "react"
import CookieConsent from "react-cookie-consent"
import TextLink from "./TextLink"
import { useTheme } from "@chakra-ui/react"
import useColors from "../../common/hooks/useColors"

const CookieConsentBanner = () => {
  const { orangeColor, whiteColor } = useColors()
  return (
    <CookieConsent
      location="bottom"
      buttonText="OK"
      style={{
        background: "#0d2b42d6",
      }}
      cookieName="PebbleCookieConsent"
      buttonStyle={{
        color: whiteColor,
        background: orangeColor,
        fontSize: "13px",
        borderRadius: "15px",
        textTransform: "uppercase",
        fontFamily: "Manrope",
        padding: "6px 20px 5px",
        lineHeight: 1.4,
        marginRight: 90,
      }}
      expires={150}
    >
      By using this website, you agree to our{" "}
      <TextLink to="/terms-of-use">Terms of Use</TextLink> and{" "}
      <TextLink to="/privacy-policy">Privacy Policy</TextLink>.
    </CookieConsent>
  )
}

export default CookieConsentBanner
