// this file is not used
import React, { createContext, useState, useMemo, useEffect } from "react"
import useUserCurrentCompSets from "../hooks/useUserCurrentCompSets"
import useUserCurrentJudgeSets from "../hooks/useUserCurrentJudgeSets"
import useUserUpcomingComps from "../hooks/useUserUpcomingComps"
import { useAuth } from "../../common/contexts/AuthContext"
const CompContext = createContext({})
const CompProvider = ({ children }) => {
  const { loggedInUser } = useAuth()
  const isLoggedInUser = loggedInUser?._id
  const [comp, setComp] = useState()
  const [compSet, setCompSet] = useState()
  const { currentCompSets, refetchUserCompSets, loadingUserCompSets } =
    useUserCurrentCompSets(isLoggedInUser)
  const { currentJudgeSets, refetchUserJudgeSets, loadingUserJudgeSets } =
    useUserCurrentJudgeSets(isLoggedInUser)
  const { upcomingComps, refetchUserUpcomingComps, loadingUserUpcomingComps } =
    useUserUpcomingComps(isLoggedInUser)
  const [judgedSet, setJudgedSet] = useState()
  useEffect(() => {
    if (!compSet && currentCompSets.length > 0) {
      setCompSet(currentCompSets[0])
      setComp(currentCompSets[0].comp)
    }
    if (!compSet && currentJudgeSets.length > 0) {
      setCompSet(currentJudgeSets[0])
      setComp(currentJudgeSets[0].comp)
    }
  }, [compSet, currentCompSets, currentJudgeSets])

  const value = useMemo(
    () => ({
      comp,
      setComp,
      compSet,
      setCompSet,
      judgedSet,
      setJudgedSet,
      currentCompSets,
      currentJudgeSets,
      refetchUserCompSets,
      refetchUserJudgeSets,
      upcomingComps,
      refetchUserUpcomingComps,
      loadingUserCompSets,
      loadingUserJudgeSets,
      loadingUserUpcomingComps,
    }),
    [
      comp,
      compSet,
      currentCompSets,
      currentJudgeSets,
      judgedSet,
      loadingUserCompSets,
      loadingUserJudgeSets,
      loadingUserUpcomingComps,
      refetchUserCompSets,
      refetchUserJudgeSets,
      refetchUserUpcomingComps,
      upcomingComps,
    ],
  )
  return <CompContext.Provider value={value}>{children}</CompContext.Provider>
}
export { CompContext, CompProvider }
