import gql from "graphql-tag"
import { basicGymFragment } from "../../gyms/graphql/basicGymFragment"
import { basicGuideFragment } from "../../guides/graphql/basicGuideFragment"
import { basicUserFragment } from "../../users/graphql/basicUserFragment"

export const gameSponsorFragment = gql`
  fragment gameSponsorFragment on GameSponsor {
    _id
    name
    type
    description
    link
    linkText
    image
    creator {
      ...basicUserFragment
    }
    startDate
    endDate
    gyms {
      ...basicGymFragment
    }
    guides {
      ...basicGuideFragment
    }
    gameTypes
    climbTypes
  }
  ${basicGymFragment}
  ${basicGuideFragment}
  ${basicUserFragment}
`

export const GET_SPONSORS = gql`
  query GetSponsors($cursor: String) {
    getSponsors(cursor: $cursor) {
      gameSponsors {
        ...gameSponsorFragment
      }
      cursor
      hasMore
    }
  }
  ${gameSponsorFragment}
`

export const GET_ACTIVE_SPONSORS = gql`
  query GetActiveSponsors {
    getActiveSponsors {
      ...gameSponsorFragment
    }
  }
  ${gameSponsorFragment}
`

export const GET_UPCOMING_SPONSORS = gql`
  query GetUpcomingSponsors {
    getUpcomingSponsors {
      ...gameSponsorFragment
    }
  }
  ${gameSponsorFragment}
`

export const GET_SPONSOR = gql`
  query GetSponsor($_id: String!) {
    getSponsor(_id: $_id) {
      ...gameSponsorFragment
    }
  }
  ${gameSponsorFragment}
`

export const CREATE_SPONSOR = gql`
  mutation CreateSponsor($sponsor: GameSponsorInput!) {
    createSponsor(sponsor: $sponsor) {
      ...gameSponsorFragment
    }
  }
  ${gameSponsorFragment}
`

export const EDIT_SPONSOR = gql`
  mutation EditSponsor($sponsor: GameSponsorInput!) {
    editSponsor(sponsor: $sponsor) {
      ...gameSponsorFragment
    }
  }
  ${gameSponsorFragment}
`

export const DELETE_SPONSOR = gql`
  mutation DeleteSponsor($_id: String!) {
    deleteSponsor(_id: $_id) {
      _id
    }
  }
`
