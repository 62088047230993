import gql from "graphql-tag"
import { basicUserFragment } from "../../users/graphql/basicUserFragment"
import { basicMediaFragment } from "../../media/graphql/basicMediaFragment"

export const gameFragment = gql`
  fragment gameFragment on Game {
    _id
    type
    gameType
    climbType
    createdAt
    startedAt
    endedAt
    daysDuration
    difficulty
    loggedInUserMaxSeedingGrade
    captain {
      ...basicUserFragment
    }
    winners {
      _id
      name
      firstName
      slug
      profile {
        _id
        picture
      }
    }
    gym {
      _id
      name
      slug
      logo
      isFrenchGrades
      isShowingGrades
      isOverlappingGrades
      popMedia {
        ...basicMediaFragment
      }
      routeGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
      problemGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
    }
    invitedPlayers {
      _id
      name
      firstName
      slug
      profile {
        _id
        picture
      }
    }
    players {
      _id
      name
      firstName
      slug
      profile {
        _id
        picture
      }
    }
    hiveBoard {
      _id
      tiles {
        _id
        type
        q
        r
        isBlocker
        grade
        send {
          _id
          grade
          sendType
          slug
        }
        player {
          _id
          name
          profile {
            _id
            picture
          }
        }
      }
    }
    qualifyingSends {
      _id
      slug
      grade
      user {
        _id
      }
      problem {
        _id
        gradeColor
        gradeName
      }
      route {
        _id
        gradeColor
        gradeName
      }
    }
  }
  ${basicUserFragment}
  ${basicMediaFragment}
`

export const GAME = gql`
  query game($gameId: String!) {
    game(gameId: $gameId) {
      ...gameFragment
    }
  }
  ${gameFragment}
`

export const GAMES_INVITED_TO = gql`
  query gamesInvitedTo {
    gamesInvitedTo {
      _id
      climbType
      startedAt
      gym {
        _id
        name
        slug
        logo
        isFrenchGrades
        isShowingGrades
        isOverlappingGrades
        popMedia {
          ...basicMediaFragment
        }
      }
    }
  }
  ${basicMediaFragment}
`

export const USER_GAMES = gql`
  query userGames {
    userGames {
      cursor
      hasMore
      games {
        _id
        climbType
        gym {
          _id
          name
          slug
          logo
          isFrenchGrades
          isShowingGrades
          isOverlappingGrades
          popMedia {
            ...basicMediaFragment
          }
        }
      }
    }
  }
  ${basicMediaFragment}
`

export const CREATE_GAME = gql`
  mutation createGame($game: GameInput!) {
    createGame(game: $game) {
      ...gameFragment
    }
  }
  ${gameFragment}
`

export const EDIT_GAME = gql`
  mutation editGame($game: GameInput!) {
    editGame(game: $game) {
      ...gameFragment
    }
  }
  ${gameFragment}
`

export const DELETE_GAME = gql`
  mutation deleteGame($gameId: String!) {
    deleteGame(gameId: $gameId) {
      _id
    }
  }
`

export const ACCEPT_GAME_INVITE = gql`
  mutation acceptGameInvite($gameId: String!, $maxSeedingGrade: Float) {
    acceptGameInvite(gameId: $gameId, maxSeedingGrade: $maxSeedingGrade) {
      ...gameFragment
    }
  }
  ${gameFragment}
`

export const DECLINE_GAME_INVITE = gql`
  mutation declineGameInvite($gameId: String!) {
    declineGameInvite(gameId: $gameId) {
      ...gameFragment
    }
  }
  ${gameFragment}
`

export const START_GAME = gql`
  mutation startGame($gameId: String!) {
    startGame(gameId: $gameId) {
      ...gameFragment
    }
  }
  ${gameFragment}
`

export const CAPTURE_HIVE_TILE = gql`
  mutation captureHiveTile(
    $gameId: String!
    $tileId: String!
    $sendId: String!
  ) {
    captureHiveTile(gameId: $gameId, tileId: $tileId, sendId: $sendId) {
      ...gameFragment
    }
  }
  ${gameFragment}
`
