import React, { useState, useRef, useEffect } from "react"
import { Box, Heading } from "@chakra-ui/react"

const Hold = ({ hold, number, rat }) => {
  if (!hold?.x || !hold?.y) {
    return null
  }
  return (
    <Box position="absolute" top={hold.y * rat} left={hold.x * rat}>
      <Box
        flexDirection="row"
        bg="#FFF"
        borderRadius={"20px"}
        height={"20px"}
        width={"20px"}
        px={0}
        py={0}
        alignItems="center"
        justifyContent="center"
      >
        <Heading size={"md"} as="h4" textAlign="center">
          {number}
        </Heading>
      </Box>
    </Box>
  )
}
const CompImage = ({ climb }) => {
  const containerRef = useRef()
  const [imageSize, setImageSize] = useState([null, null])
  const [containerSize, setContainerSize] = useState([null, null])

  useEffect(() => {
    setContainerSize([
      containerRef.current.clientWidth,
      containerRef.current.clientHeight,
    ])
  }, [containerRef?.current?.clientHeight])

  const [containerWidth, containerHeight] = containerSize
  const [imageWidth, imageHeight] = imageSize
  const rat = imageHeight ? containerHeight / imageHeight : 0
  // const widthRat = imageWidth ? containerWidth / imageWidth : 0

  return (
    <Box
      my={2}
      ref={containerRef}
      position="relative"
      width="100%"
      borderRadius={10}
      overflow="hidden"
    >
      <img
        style={{ display: "block" }}
        width="100%"
        alt="hold locations"
        onLoad={(ev) => {
          setImageSize([
            ev.currentTarget.naturalWidth,
            ev.currentTarget.naturalHeight,
          ])
        }}
        src={climb.competitionPicture}
      />
      {climb.holdLocations.map((hold, index) => (
        <Hold
          rat={rat}
          key={`${hold.x}${hold.y}`}
          number={index + 1}
          hold={hold}
        />
      ))}
    </Box>
  )
}

export default CompImage
