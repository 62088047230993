import NextLink from "next/link"
import { Link } from "@chakra-ui/react"

const TextLink = ({ children, href = null, ...props }) => {
  if (!href && !props.to) {
    return <>{children}</>
  }
  if (href) {
    return (
      <Link href={href} isExternal>
        {children}
      </Link>
    )
  }
  return (
    <NextLink passHref href={props.to}>
      <Link
        {...props}
        sx={{
          _hover: {
            textDecoration: props.isnohover ? "none" : "underline",
          },
          color: props.color || "brand.blueColor",
        }}
      >
        {children}
      </Link>
    </NextLink>
  )
}
export default TextLink
