import _ from "lodash"
import React from "react"
import {
  MenuButton,
  MenuList,
  Menu,
  MenuItem,
  Heading,
  Button,
  Portal,
  Text,
  useTheme,
} from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/pro-light-svg-icons"
import useColors from "../../common/hooks/useColors"

const SortMenu = ({
  placeholder = "",
  options,
  variableName = "",
  value,
  onChange,
  isText,
  buttonProps = {},
}) => {
  const { blueColor } = useColors()
  const curOption = _.find(options, { value })
  return (
    <Menu>
      <MenuButton
        // mt={buttonProps.size === "sm" ? -2 : 0}
        variant={isText ? "text" : "secondary"}
        as={Button}
        rightIcon={<FontAwesomeIcon color={blueColor} icon={faAngleDown} />}
        fontSize={
          buttonProps.size === "sm"
            ? "1rem"
            : buttonProps.size === "lg"
            ? "1.875rem"
            : "1.25rem"
        }
        {...buttonProps}
      >
        <Heading
          my={0}
          variant="link"
          size={buttonProps.size ?? "md"}
          isTruncated
        >
          {curOption ? curOption.label : placeholder}
        </Heading>
      </MenuButton>
      <Portal>
        <MenuList
          bg={"brand.whiteColor"}
          borderColor="brand.headingLineColor"
          maxHeight={300}
          overflow="auto"
          position="relative"
          zIndex={10000}
        >
          {options.map((opt) => (
            <MenuItem
              bg={"brand.whiteColor"}
              onClick={() => {
                onChange(opt.value)
              }}
              key={opt.value}
            >
              <Heading
                as="h6"
                size="sm"
                mb={0}
                variant={value === opt.value ? "error" : "link"}
              >
                {opt.label.replace("$variable", variableName)}
              </Heading>
            </MenuItem>
          ))}
        </MenuList>
      </Portal>
    </Menu>
  )
}

export default SortMenu
