import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons"

export const navigationTree = (loggedInUser) => {
  const firstGymSlug = [
    ...(loggedInUser?.gymsIsOwnerOf ?? []),
    ...(loggedInUser?.gymsIsManagerOrHeadSetterOf ?? []),
  ]?.[0]?.slug
  return [
    {
      link: "/feed",
      title: "Feed",
    },
    {
      link: "/map",
      title: "Guides",
      sections: [
        {
          link: "/map",
          title: "Map",
        },
        {
          link: "/create/route",
          title: "Create a Route",
          isModal: true,
        },
        {
          link: "/create/problem",
          title: "Create a Problem",
          isModal: true,
        },
      ],
    },
    {
      link: "/gyms",
      title: "Gyms",
      sections: [
        {
          link: "/create/gym",
          title: "Create a Gym",
          authentication: "owner",
        },
        ...(firstGymSlug
          ? [
              {
                link: `/gyms/${firstGymSlug}`,
                title: "Your Gyms",
              },
            ]
          : [
              {
                link: "/gym-signup",
                title: "Gym Owner Signup",
              },
            ]),
      ],
    },
    {
      link: "/athletes",
      title: "Climbers",
      sections: [
        {
          link: "/create/send",
          title: "Log a Send",
        },
      ],
    },
  ]
}

export const loginNavigationTree = [
  {
    link: "/map",
    title: "Guides",
  },
  {
    link: "/gyms",
    title: "Gyms",
  },
]

export const footerNavigationTree = [
  {
    title: "Social",
    sections: [
      {
        icon: faFacebook,
        link: "https://www.facebook.com/pebbleclimbing",
        title: "Facebook",
      },
      {
        icon: faInstagram,
        link: "https://www.instagram.com/pebble_climbing/",
        title: "Instagram",
      },
    ],
  },
  {
    title: "About",
    sections: [
      {
        link: "/history",
        title: "History",
      },
      {
        link: "/privacy-policy",
        title: "Privacy Policy",
      },
      {
        link: "/terms-of-use",
        title: "Terms of Use",
      },
      {
        link: "https://www.proxyclimbing.com/",
        title: "Partners with Proxy Climbing",
      },
    ],
  },
  {
    title: "Help",
    sections: [
      {
        youtubeId: "NXT9wXr3w4E",
        title: "User Demo Video",
      },
      // {
      //   link: "/faq",
      //   title: "Frequently Asked Questions",
      // },
      {
        link: "/contact",
        title: "Contact",
      },
      {
        youtubeId: "a_aqi2I-w-Q",
        title: "Gym Demo Video",
      },
      {
        link: "/gym-signup",
        title: "Gym Signup",
      },
      {
        link: "/deleting-account",
        title: "Deleting your account",
      },
    ],
  },
]

export default {
  navigationTree,
  loginNavigationTree,
}
