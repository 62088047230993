import { useState, useEffect, useCallback } from "react"
import { useRouter } from "next/router"
import Loader from "./Loader"
import useScrollRestoration from "../hooks/useScrollRestoration"
import { Box } from "@chakra-ui/react"
import { useAuth } from "../contexts/AuthContext"

const publicPaths = [
  "/login",
  "/guides",
  "/about",
  "/gyms",
  "/map",
  "/problems",
  "/routes",
  "/areas",
  "/gyms",
  "/gymareas",
  "/comps",
  "/forgotpassword",
  "/resetpassword",
  "/sends",
  "/media",
  "/gym-signup",
  "/deleting-account",
  "/history",
  "/faq",
  "/contact",
  "/terms-of-use",
  "/privacy-policy",
  "/partner-finder",
  "/create/comp-code-score",
  "/setting-schedule-widget",
  "/leaderboard",
  "/monthly-setter-reports",
  "/setter-audit-report",
  "/weather",
  "/gym-groups",
  "/unsubscribe",
  "/member-dashboards",
  "/pricing",
]
const mobilePrivatePaths = ["/surveys"]
function isPublic(path) {
  return publicPaths.some((p) => path.startsWith(p))
}

function isMobilePrivate(path) {
  return mobilePrivatePaths.some((p) => path.startsWith(p))
}

export const RouteGuard = (props) => {
  const { Component } = props
  const router = useRouter()
  const { loading, loggedInUser } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  // const [isTryingToOpenApp, setIsTryingToOpenApp] = useState(false)
  const [authorized, setAuthorized] = useState(
    typeof window === "undefined" ||
      isPublic(router.pathname) ||
      isMobilePrivate(router.pathname) ||
      loggedInUser?._id,
  )

  const authCheck = useCallback(
    function authCheck(url) {
      // redirect to login page if accessing a private page and not logged in
      if (!loggedInUser?._id && !isPublic(url) && !isMobilePrivate(url)) {
        setAuthorized(false)
        router.push({
          pathname: "/login",
          query: router.asPath !== "/" ? { returnUrl: router.asPath } : {},
        })
      } else {
        setAuthorized(true)
      }
      setIsLoading(false)
    },
    [loggedInUser?._id, router],
  )

  const scrollPositions = useScrollRestoration(router)
  const showLoader = useCallback(
    (url, { shallow }) => {
      if (!shallow && !Object.keys(scrollPositions.current).includes(url)) {
        setIsLoading(true)
      }
    },
    [scrollPositions],
  )

  useEffect(() => {
    if (loading) {
      return
    }
    // on initial load - run auth check
    authCheck(router.asPath)

    // on route change start - hide page content by setting authorized to false
    router.events.on("routeChangeStart", showLoader)

    // on route change complete - run auth check
    router.events.on("routeChangeComplete", authCheck)

    // unsubscribe from events in useEffect return function
    return () => {
      // router.events.off("routeChangeStart", checkForMobilePrivate)
      router.events.off("routeChangeStart", showLoader)
      router.events.off("routeChangeComplete", authCheck)
    }
  }, [
    authCheck,
    // checkForMobilePrivate,
    loading,
    router.asPath,
    router.events,
    showLoader,
  ])

  return isLoading || !authorized ? (
    <div
      style={{
        height: "calc(100vh - 152px)",
        width: "calc(100vw - 20px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* <Loader /> */}
    </div>
  ) : (
    <Component {...props} loggedInUser={loggedInUser} loading={loading} />
  )
}
