import React from "react"

import { Avatar, Flex, Heading, useTheme } from "@chakra-ui/react"
import useColors from "../../common/hooks/useColors"

const CompetitorRow = ({ competitor, onClick, isBottom }) => {
  const { headingLineColor } = useColors()
  return (
    <Flex
      my={2}
      pb={2}
      align="center"
      onClick={onClick}
      borderBottom={isBottom ? null : `1px solid ${headingLineColor}`}
    >
      <Avatar
        size="md"
        alt={competitor.name}
        name={competitor.name}
        src={competitor?.user?.profile?.picture}
      />
      <Heading mb={0} ml={3} as="h4" variant="link" size="md">
        {competitor.name}
      </Heading>
    </Flex>
  )
}

export default CompetitorRow
