import React from "react"
import Card from "../../common/components/Card"
import dayjs from "dayjs"
import LocalizedFormat from "dayjs/plugin/localizedFormat"
import FollowUserBtnCont from "../containers/FollowUserBtnCont"
import FollowUserBtn from "../components/FollowUserBtn"
import {
  Avatar,
  Box,
  Flex,
  Heading,
  Link,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react"
import TextLink from "../../common/components/TextLink"
import NextLink from "next/link"

dayjs.extend(LocalizedFormat)

function getClimb(send) {
  if (send.problem) {
    return (
      <Heading size="xs" as="h6">
        Problem:&nbsp;
        <TextLink to={"/problems/" + send.problem.slug}>
          {send.problem.name}
        </TextLink>
      </Heading>
    )
  } else if (send.route) {
    return (
      <Heading size="xs" as="h6">
        Problem:&nbsp;
        <TextLink to={"/routes/" + send.route.slug}>{send.route.name}</TextLink>
      </Heading>
    )
  }
}

const UserCard = ({
  user,
  send = undefined,
  loggedInUser = undefined,
  isShowingClimb = false,
  size = "60px",
  isSmall = false,
  isDisabled = false,
  onClick = () => null,
}) => {
  const LBox = isDisabled || onClick ? Box : LinkBox
  return (
    <LBox>
      <Card
        onClick={onClick ? () => onClick(user) : null}
        height={size}
        variant={isDisabled || isSmall ? "" : "link"}
        m={0}
        p={0}
        display="flex"
        alignItems="center"
        overflow="hidden"
        pr={5}
      >
        <Avatar
          height={size}
          width={size}
          borderRadius={0}
          name={user.name}
          src={user?.profile?.picture}
        />
        <Flex
          flex={1}
          alignItems="flex-start"
          ml={3}
          direction="column"
          justifyContent="space-between"
        >
          <Heading
            noOfLines={2}
            variant="link"
            my={!!send ? 1 : 0}
            as="h5"
            size="sm"
          >
            {send ? (
              <NextLink passHref href={"/sends/" + send.slug}>
                <LinkOverlay>{send.name}</LinkOverlay>
              </NextLink>
            ) : isDisabled || onClick ? (
              user.name
            ) : (
              <NextLink passHref href={"/users/" + user.slug}>
                <LinkOverlay>{user.name}</LinkOverlay>
              </NextLink>
            )}
          </Heading>
          {isShowingClimb && send ? getClimb(send) : null}
          {send ? (
            <Heading as="h6" size="xs" islight="true">
              {dayjs(send.date).format("LL")}
            </Heading>
          ) : null}
        </Flex>
        {!!loggedInUser !== user._id && (
          <FollowUserBtnCont
            loggedInUser={loggedInUser}
            user={user}
            render={({ follow, unfollow, isFollowing, isWaiting }) => (
              <FollowUserBtn
                follow={follow}
                unfollow={unfollow}
                user={user}
                loggedInUser={loggedInUser}
                isFollowing={isFollowing}
                isWaiting={isWaiting}
              />
            )}
          />
        )}
      </Card>
    </LBox>
  )
}

UserCard.propTypes = {}

export default UserCard
