import React, { useState } from "react"
import newScore from "../../structures/compScore"
import { getScoreDelta } from "../../utilities/scores"
import useSaveCompScore from "../hooks/useSaveCompScore"
import useUpdateObject from "../../common/hooks/useUpdateObject"
import _ from "lodash"
import { Input, Button, Checkbox, Heading, Switch } from "@chakra-ui/react"
import Cell from "../../common/components/Cell"
import Grid from "../../common/components/Grid"
import CompImage from "./CompImage"
import ClimbScoreCard from "./ClimbScoreCard"
import { calcScore } from "../../utilities/comps"
import { Box } from "@chakra-ui/react"
import JudgeFinder from "./JudgeFinder"
import WCBoulderingPointsScoreInput from "../../comps/components/WCBoulderingPointsScoreInput"
import WCBoulderingScoreInput from "../../comps/components/WCBoulderingScoreInput"
import WCSportScoreInput from "../../comps/components/WCSportScoreInput"

const CreateCompScoreAdmin = ({
  climb,
  comp,
  compSet,
  competitor,
  competitorCode,
  score,
  setAlertMessage,
  compSetScores,
  finishSaving,
  cancel,
}) => {
  const [isSaving, setIsSaving] = useState(false)
  const [isSend, setIsSend] = useState(
    !!score?.send || (!compSet.isGradingHolds && !compSet.isWorldCupSport),
  )
  const initialCompScore = score
    ? _.chain({
        ...score,
        userId: competitor.user._id,
      })
        .cloneDeep()
        .value()
    : {
        ...newScore,
        numAttempts: 1,
        climbId: climb._id,
        climbType: climb.type,
        compId: comp._id,
        compSetId: compSet._id,
        relativeDelta: getScoreDelta({ competitor, climb }),
        userId: competitor.user._id,
        isCreatingSend: !compSet.isGradingAttempts,
        controlledZone: 0,
      }

  const { updatedObject: compScore, updateObject: updateCompScore } =
    useUpdateObject(initialCompScore)

  const saveCompScore = useSaveCompScore({
    isEditing: !!score?._id,
    compScore,
    climb,
    isSend,
    compSet,
    competitorCode,
    competitorId: competitor?._id,
    isMobile: true,
    setMessage: (msg) => {
      setIsSaving(false)
      setAlertMessage(msg, true)
    },
    returnFromSave: (savedCompScore) => {
      setIsSaving(false)
      if (!savedCompScore) {
        return
      }
      finishSaving()
    },
  })
  const {
    highpoint,
    numAttempts,
    scoreBonuses,
    isTopControlled,
    isZoneControlled,
    attemptsToTop,
    attemptsToZone,
    controlledZone,
    attemptsToZones,
    isPlusGiven,
  } = compScore
  const { score: finalScore } = calcScore({
    climb,
    compSet,
    highpoint,
    isSend,
    numAttempts,
    competitor,
    allScores: compSetScores,
    scoreBonuses,
    isTopControlled,
    isZoneControlled,
    attemptsToTop,
    attemptsToZone,
    controlledZone,
    attemptsToZones,
    isPlusGiven,
  })
  console.log("finalScore", finalScore)
  return (
    <div>
      <Grid>
        <Cell col={12}>
          <ClimbScoreCard
            climb={climb}
            compScore={compScore}
            compSet={compSet}
          />
          {!!compSet.isGradingHolds && (
            <Box display="flex" alignItems="center" my={2}>
              <Checkbox
                onChange={({ target: { checked } }) => {
                  setIsSend(checked)
                  updateCompScore({
                    highpoint: 0,
                    isCreatingSend: checked && !score?.send,
                    isRemovingSend: !checked && !!score?.send,
                  })
                }}
                isChecked={isSend}
              >
                Check here if the competitor sent this climb
              </Checkbox>
            </Box>
          )}
        </Cell>
        {!!compSet?.isGradingHolds && !isSend && (
          <>
            <Cell col={12}>
              {climb.competitionPicture && <CompImage climb={climb} />}
            </Cell>
            <Cell col={6}>
              <Heading size="sm" as="h6">
                Highpoint Hold Number (out of {climb.numHolds})
              </Heading>
              <Input
                placeholder="Highest Hold Reached*"
                value={compScore.highpoint}
                onChange={(e) => {
                  if (e.target.value) {
                    updateCompScore("highpoint", parseInt(e.target.value, 10))
                  } else {
                    updateCompScore("highpoint", e.target.value)
                  }
                }}
              />
            </Cell>
          </>
        )}
        {!!competitorCode && compSet?.judgeType === "competitor" && (
          <Cell col={6}>
            <Heading size="sm" as="h6">
              Competitor to act as judge
            </Heading>
            <JudgeFinder
              excludedUserId={competitor.user._id}
              judge={compScore.judge}
              setJudge={(judge) => updateCompScore("judge", judge)}
            />
          </Cell>
        )}
        {!!compSet?.isGradingAttempts && (
          <Cell col={6}>
            <Heading size="sm" as="h6">
              Total attempts (including send)
            </Heading>
            <Input
              placeholder="Total tries"
              value={compScore.numAttempts}
              onChange={(e) => {
                if (e.target.value) {
                  updateCompScore("numAttempts", parseInt(e.target.value, 10))
                } else {
                  updateCompScore("numAttempts", e.target.value)
                }
              }}
            />
          </Cell>
        )}
        {!!compSet.isWorldCupSport && (
          <Cell col={6}>
            <WCSportScoreInput
              compScore={compScore}
              compSet={compSet}
              updateCompScore={updateCompScore}
              setMessage={setAlertMessage}
            />
          </Cell>
        )}
        {!!compSet.isWorldCupBouldering && (
          <Cell col={6}>
            <WCBoulderingScoreInput
              compScore={compScore}
              compSet={compSet}
              updateCompScore={updateCompScore}
              setMessage={setAlertMessage}
            />
          </Cell>
        )}
        {!!compSet.isWorldCupBoulderingPoints && (
          <Cell col={6}>
            <WCBoulderingPointsScoreInput
              compScore={compScore}
              compSet={compSet}
              updateCompScore={updateCompScore}
              setMessage={setAlertMessage}
            />
          </Cell>
        )}
        {!!compSet?.scoreBonuses?.length && (
          <Cell col={6}>
            <Heading size="sm" as="h6">
              Bonuses
            </Heading>
            {compSet.scoreBonuses.map((bonus) => (
              <Switch
                key={bonus._id}
                isChecked={compScore.scoreBonuses
                  ?.map((b) => b._id)
                  ?.includes(bonus._id)}
                onChange={(e) => {
                  if (e.target.checked) {
                    updateCompScore(
                      "scoreBonuses",
                      (compScore.scoreBonuses || []).concat(bonus),
                    )
                  } else {
                    updateCompScore(
                      "scoreBonuses",
                      compScore.scoreBonuses?.filter(
                        (b) => b._id !== bonus._id,
                      ),
                    )
                  }
                }}
              >
                {bonus.name}
              </Switch>
            ))}
          </Cell>
        )}
        <Cell col={12}>
          <Heading as="h4" size="md">
            Final score:{" "}
            <span className="orange-text">
              {compSet.isWorldCupBouldering
                ? finalScore
                : Math.round(finalScore * 100) / 100}
            </span>
          </Heading>
          <Box mt={4} />
          <Button
            raised="true"
            variant="primary"
            onClick={async () => {
              setIsSaving(true)
              await saveCompScore()
              setIsSaving(false)
            }}
          >
            {isSaving ? "Saving" : "Save"}
          </Button>
          <Button
            ml={2}
            raised="true"
            onClick={() => {
              cancel()
            }}
          >
            Cancel
          </Button>
        </Cell>
      </Grid>
    </div>
  )
}

export default CreateCompScoreAdmin
