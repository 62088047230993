import React from "react"
import { Box, Heading, MenuButton, Portal, useTheme } from "@chakra-ui/react"
import { Button, MenuList, Menu, MenuItem } from "@chakra-ui/react"
import GradeContext from "../../context/GradeContext"
import GradeSlider from "../../gyms/components/GradeSlider"
import {
  getGradeColor,
  getGradeImage,
  getGradeLabel,
  getGradeScale,
} from "../../utilities/grades"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/pro-light-svg-icons"
import useColors from "../../common/hooks/useColors"

const GradesMenu = ({
  gym,
  type,
  grade,
  changeGrade,
  // optional props
  isSlider = false,
  isDisabled = false,
  initGrade = null,
  size = "md",
}) => {
  const { blueColor } = useColors()
  console.log("blueColor", blueColor)
  const isShowingColors = !!gym?.[`${type}GradeColors`]?.[0]?.color
  const isShowingImages = !!gym?.[`${type}GradeColors`]?.[0]?.image
  const isShowingSlider = isShowingColors && isSlider

  const gradeColor = getGradeColor(grade, gym?.[`${type}GradeColors`])
  const gradeImage = getGradeImage(grade, gym?.[`${type}GradeColors`])

  return (
    <GradeContext.Consumer>
      {(gradeType) => {
        const gradesOptions = getGradeScale(type, gradeType, gym)
        const gradeLabel = getGradeLabel(grade, type, gradeType, gym)
        return (
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            alignItems="stretch"
          >
            {isShowingSlider ? (
              <GradeSlider
                grade={grade}
                setterGrade={initGrade || grade}
                type={type}
                gym={gym}
                changeGrade={(grade) => {
                  changeGrade(grade)
                }}
              />
            ) : (
              <Menu>
                <MenuButton
                  size={size}
                  variant="input"
                  as={Button}
                  disabled={isDisabled}
                  borderColor="brand.blueColor"
                  rightIcon={
                    <FontAwesomeIcon icon={faAngleDown} color={blueColor} />
                  }
                >
                  {!!grade ? (
                    isShowingColors ? (
                      <Box
                        as="span"
                        mt={1}
                        display="inline-block"
                        height={"22px"}
                        width={"22px"}
                        borderRadius={"100%"}
                        bg={gradeColor}
                      />
                    ) : isShowingImages ? (
                      <img
                        src={gradeImage}
                        alt={gradeLabel}
                        width={30}
                        height={30}
                      />
                    ) : (
                      gradeLabel
                    )
                  ) : (
                    <Heading
                      islight="true"
                      size="sm"
                      as="span"
                      color="gray.400"
                    >
                      Grade*
                    </Heading>
                  )}
                </MenuButton>
                <Portal>
                  <MenuList
                    background={"brand.whiteColor"}
                    maxHeight={300}
                    overflow="auto"
                    position="relative"
                    zIndex={10000}
                    borderColor="brand.headingLineColor"
                  >
                    {gradesOptions.map((gradeItem) => (
                      <MenuItem
                        sx={{
                          "&.active": {
                            color: "brand.blueColor",
                          },
                          "&:hover": {
                            bg: "brand.bgColor",
                          },
                        }}
                        background={"brand.whiteColor"}
                        onClick={() => {
                          changeGrade(gradeItem.value || gradeItem.maxGrade)
                        }}
                        key={gradeItem.value || gradeItem.color}
                      >
                        <Box display="flex" alignItems="center">
                          {isShowingColors && (
                            <Box
                              ml={10}
                              height={22}
                              width={22}
                              borderRadius={"100%"}
                              bg={gradeItem.color}
                              mr={2}
                            />
                          )}
                          {isShowingImages && (
                            <Box mr={2} width={22} height={22}>
                              <img
                                src={gradeItem.image}
                                alt={gradeItem.name || gradeItem.label}
                                width={22}
                                height={22}
                              />
                            </Box>
                          )}
                          <Heading mb={0} as="h5" size="sm" variant="link">
                            {gradeItem.label || gradeItem.name}
                          </Heading>
                        </Box>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Portal>
              </Menu>
            )}
          </Box>
        )
      }}
    </GradeContext.Consumer>
  )
}

export default GradesMenu
