import React from "react"
import { Heading, IconButton, useTheme } from "@chakra-ui/react"
import Tooltip from "../../common/components/Tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserTimes, faUserPlus } from "@fortawesome/pro-light-svg-icons"
import useColors from "../../common/hooks/useColors"

import styles from "./css/FollowUserBtn.module.css"

const FollowUserBtn = (props) => {
  const { blueColor } = useColors()
  const {
    loggedInUser,
    user,
    follow,
    unfollow,
    isFollowing,
    isWaiting,
    setAlertMessage,
  } = props

  const clickToggle = async () => {
    if (isFollowing || isWaiting) {
      await unfollow({
        variables: {
          followerSlug: loggedInUser.slug,
          followeeSlug: user.slug,
        },
      })
    } else {
      await follow({
        variables: {
          followerSlug: loggedInUser.slug,
          followeeSlug: user.slug,
        },
      })
      if (setAlertMessage) {
        setAlertMessage(
          user.isPrivate
            ? "Your request has been sent!"
            : `You are following ${user.name}`,
        )
      }
    }
  }

  return (
    <span className={styles["follow-user-btn"]}>
      <IconButton
        variant="text"
        data-tip
        data-tooltip-id={`friend-user-tt-${user._id}`}
        onClick={clickToggle}
        icon={
          isFollowing || isWaiting ? (
            <FontAwesomeIcon icon={faUserTimes} color={blueColor} />
          ) : (
            <FontAwesomeIcon icon={faUserPlus} color={blueColor} />
          )
        }
      />
      <Tooltip class="card-tooltip" id={`friend-user-tt-${user._id}`}>
        <Heading as="h6" size="xs">
          {(isWaiting
            ? "Waiting for Approval from "
            : isFollowing
            ? "Unfollow "
            : user.isPrivate
            ? "Request to follow "
            : "Follow ") + user.name}
        </Heading>
      </Tooltip>
    </span>
  )
}

export default FollowUserBtn
