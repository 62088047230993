"use client"
import _ from "lodash"
import { withRouter } from "next/router"
import { faUser } from "@fortawesome/free-solid-svg-icons"
import { forwardRef, Box, Flex, IconButton, useTheme } from "@chakra-ui/react"
import Menu from "../../common/components/Menu"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import useColors from "../../common/hooks/useColors"

const MenuBut = forwardRef((props, ref) => {
  const { blueColor } = useColors()
  return (
    <Box ref={ref}>
      <IconButton
        variant={"text"}
        size={"lg"}
        sx={{
          backgroundSize: "cover",
          backgroundImage: props.picture ? `url(${props.picture})` : "none",
          _hover: {
            backgroundSize: "cover",
            transform: "scale(1.05)",
          },
        }}
        {...props}
        icon={
          props.picture ? null : (
            <Flex
              borderRadius="100%"
              alignItems="center"
              justifyContent="center"
              borderColor={"brand.blueColor"}
              borderWidth={1}
              width={"40px"}
              height={"40px"}
            >
              <FontAwesomeIcon size="lg" icon={faUser} color={blueColor} />
            </Flex>
          )
        }
      />
    </Box>
  )
})

const ProfileMenu = ({ loggedInUser, router, logOut }) => {
  const picture = _.get(loggedInUser, "profile.picture") || null

  return (
    <Flex alignItems="center">
      <Menu
        buttonComp={MenuBut}
        buttonProps={{
          picture,
        }}
        items={[
          {
            label: "Profile",
            onClick: () => {
              router.push({
                pathname: "/users/" + loggedInUser.slug,
              })
            },
          },
          // ...(loggedInUser?.isGymOwner
          //   ? [
          //       {
          //         label: "Billing",
          //         onClick: () => {
          //           router.push({
          //             pathname: "/billing-settings",
          //           })
          //         },
          //       },
          //     ]
          //   : []),
          {
            label: "Logout",
            onClick: () => {
              logOut()
            },
          },
        ]}
      />
    </Flex>
  )
}

export default withRouter(ProfileMenu)
