// this file is not used
import { useQuery } from "@apollo/client"
import { GET_CURRENT_USER_JUDGE_SETS } from "../graphql/comps"

export function useCompSets(isLoggedInUser = true) {
  const {
    loading,
    error,
    data: { getCurrentUserJudgeSets } = {},
    refetch,
  } = useQuery(GET_CURRENT_USER_JUDGE_SETS, {
    fetchPolicy: "network-only",
    skip: !isLoggedInUser,
  })
  return {
    currentJudgeSets: getCurrentUserJudgeSets || [],
    refetchUserJudgeSets: refetch,
    loadingUserJudgeSets: loading,
  }
}

export default useCompSets
