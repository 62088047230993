export const googleSignIn = {
  url: "https://www.googleapis.com/oauth2/v3/userinfo",
  apiKey: "AIzaSyAmSzHHbCjhssTYOiJfeI_Jk0xQY42oAW4",
  clientID:
    process.env.GOOGLE_CLIENTID ||
    "840673195906-r5gqigdc75p7iqsmref9q8h70r7a1c7c.apps.googleusercontent.com",
  scope: "profile,email",
}
export const facebookSignIn = {
  url: "https://graph.facebook.com/me",
  clientID: process.env.FACEBOOK_CLIENTID || "477495919416864",
  scope: "email,picture",
}

export const appleSignIn = {
  redirectUri: `${
    process.env.PUBLIC_URL || "https://www.pebbleclimbing.com"
  }/login`,
  clientID: "com.pebbleclimbing.server",
  scope: "email",
}
export const googleSenderId = "840673195906"
export const googleRecaptcha = "6Lf38qMZAAAAANTH0pOTqmJWi1yUIk5VUV1HDitc"

export const facebookClientAccessToken =
  "477495919416864|8529d7d4324adf0ec876e1e91672c5aa"
export const stripePublicKey = "pk_live_OGXpogvQLrC3yZVp2rudJQkU00lH5pp4Zy"
export const stripePublicKeyTest = "pk_test_gXhhRQ6FjlRZT4m3cuyXAjCD00p0iSdnxz"
// export const gymSubscriptionProductId = "prod_HoeiOwcHGdjL2T"
export const gymSubscriptionPriceId = "price_1HF1ObLErMGICim3a6OkrxAO"
// export const gymSubscriptionProductIdTest = "prod_HpRqCSnoCPiKad"
export const gymSubscriptionPriceIdTest = "price_1HFmx3LErMGICim3nZ62mfbp"
export const gymSubscriptionPriceIdNoTrial = "price_1HPVZ5LErMGICim3ZmN15iSL"
export const gymSubscriptionPriceIdNoTrialTest =
  "price_1HHzDeLErMGICim3gkz5IQSL"
export const weatherKey = "CRLUXL6EJSHA3GASUEBUXCYGL"
