import gql from "graphql-tag"

export const characteristicFragment = gql`
  fragment characteristicFragment on Characteristic {
    _id
    name
    description
    image
    isShownOnClimbPage
  }
`
