import React, { useState } from "react"
import Modal from "react-modal"
import useUpdateObject from "../../common/hooks/useUpdateObject"
import _ from "lodash"
import {
  Button,
  FormControl,
  Radio,
  Input,
  Text,
  RadioGroup,
  Flex,
  useTheme,
} from "@chakra-ui/react"
import Card from "../../common/components/Card"
import CompCheckoutForm from "./CompCheckoutForm"
import { Box, Heading } from "@chakra-ui/react"
import styles from "./css/TeamRegistrationModal.module.css"
import MenuButton from "../../common/components/MenuButton"
import useSaveCompetitor, {
  useValidateCompetitor,
} from "../hooks/useSaveCompetitor"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons"
import CompetitorQuestion from "./CompetitorQuestion"
import GradesMenu from "../../common/components/GradesMenu"
import { getCostOfComp, getDiscountId } from "../../utilities/comps"
import UserCard from "../../users/components/UserCard"
import UserSearch from "../../users/components/UserSearch"
import DiscountCodeForm from "../../comps/components/DiscountCodeForm"
import useColors from "../../common/hooks/useColors"

const IndividualRegistrationModal = ({
  isOpen,
  title,
  comp,
  team,
  loggedInUser,
  isCreatingCompetitorAsManager,
  competitor: queriedCompetitor,
  closeModal,
  onSave,
  isFromApp,
  refetch = () => {},
}) => {
  const { whiteColor } = useColors()
  const [errorMessage, setErrorMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [currentUser, setCurrentUser] = useState(null)
  const [discountedPriceId, setDiscountedPriceId] = useState(
    getDiscountId({ comp, loggedInUser }),
  )
  const isEditing = !!queriedCompetitor?._id
  const initialCompetitor =
    queriedCompetitor && queriedCompetitor.name
      ? _.chain(queriedCompetitor).cloneDeep().value()
      : {
          compId: comp._id,
          teamId: team?._id,
          userId: loggedInUser?._id,
          timeSlotId: team?.timeSlot?._id,
          discountedPriceId,
        }
  const { updatedObject: competitor, updateObject: updateCompetitor } =
    useUpdateObject(initialCompetitor)
  const validateCompetitor = useValidateCompetitor({
    competitor,
    comp,
    setAlertMessage: setErrorMessage,
  })
  const saveCompetitor = useSaveCompetitor({
    competitorToChange: competitor,
    isCreatingCompetitorAsManager,
    comp,
    team,
    isEditing,
    setMessage: setErrorMessage,
    returnFromSave: (savedCompetitor) => {
      setIsLoading(false)
      if (savedCompetitor) {
        setCurrentUser()
        refetch()
        if (isFromApp) {
          window?.ReactNativeWebView?.postMessage("close-pebble-modal")
        } else {
          closeModal()
        }
      }
    },
  })

  const isBeforeRegistrationEnds =
    !!comp && (Date.parse(comp.endRegistrationDate) > Date.now() || !isEditing)
  const availableTimeSlots = comp?.timeSlots?.filter((tS) => !tS.isFull) || []

  const [discountCode, setDiscountCode] = useState("")
  const initialCost = getCostOfComp({ comp, loggedInUser })
  const [cost, setCost] = useState(initialCost)
  const discountCodePrices = (comp.discountedPrices ?? []).filter(
    (d) =>
      !!d.code &&
      (!d.expirationDate || Date.parse(d.expirationDate) > Date.now()) &&
      (!d.numberAvailable || d.numberAvailable > d.numberUsed),
  )
  return (
    <>
      <Modal
        isOpen={isOpen}
        className={styles["modal"] + " modal-container flex-center"}
        overlayClassName="modal-overlay"
        ariaHideApp={false}
        contentLabel={title}
        onRequestClose={closeModal}
      >
        <Box alignSelf="flex-start" m={isFromApp ? -3 : 0}>
          <Card>
            {isCreatingCompetitorAsManager ? (
              <>
                <Box position="relative" zIndex={1000}>
                  <UserSearch
                    title="Find a user"
                    onClick={(user) => {
                      setCurrentUser(user)
                      updateCompetitor({ userId: user._id })
                      updateCompetitor({ name: user.name })
                    }}
                  />
                </Box>
                {!!currentUser && (
                  <>
                    <Box mt={3}>
                      <UserCard user={currentUser} />
                    </Box>
                    <Heading mt={5} as="h5" size="sm">
                      Change the name below if the competitor has a different
                      name than the user.
                    </Heading>
                    <Input
                      className="width-100"
                      value={competitor.name}
                      placeholder="Competitor Name"
                      onChange={(e) => {
                        updateCompetitor({ name: e.target.value })
                      }}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                <Heading as="h3" size="lg">
                  {isEditing ? `Edit your information for ` : `Register for `}
                  {comp.name}.
                </Heading>
                {!!team && (
                  <Heading as="md" size="h4">
                    You’re joining the team:{" "}
                    <span className="orange-text">{team.name}</span>
                  </Heading>
                )}
                {!!comp.registrationInstructions && (
                  <Text>{comp.registrationInstructions}</Text>
                )}
                {!!comp.isAllowingFloaters && !competitor.team && (
                  <Text>
                    By registering as an individual, you will be added to a team
                    with others registering as individuals.
                  </Text>
                )}
              </>
            )}
            {!!isEditing && (
              <Box my={5}>
                <Heading size="sm" as="h6">
                  Competitor Name
                </Heading>
                <Input
                  mb={3}
                  value={competitor.name}
                  placeholder="Competitor name"
                  onChange={(e) => {
                    updateCompetitor({ name: e.target.value })
                  }}
                />
                <Heading mt={-1} size="xs" as="h6">
                  This is probably your name, but if you are registering someone
                  that will compete using your phone and account, you can enter
                  in their name here.
                </Heading>
              </Box>
            )}
            {!!comp.categories?.length &&
              (!comp.isTeamCategory || !!comp.isAllowingFloaters) && (
                <>
                  <Heading as="md" size="h4">
                    Competition Category
                  </Heading>
                  <Text>Choose the category you want to compete in:</Text>
                  {!isBeforeRegistrationEnds && (
                    <Heading as="h6" size="sm" variant="error">
                      Registration has ended, so you cannot change your
                      category.
                    </Heading>
                  )}
                  <Box
                    display="flex"
                    position="relative"
                    zIndex={100}
                    my={5}
                    flexDirection="column"
                  >
                    <Heading as="h4" size="md">
                      Categories:{" "}
                    </Heading>
                    <Box ml={-3}>
                      <MenuButton
                        isLeft
                        isDisabled={!isBeforeRegistrationEnds}
                        placeholder="Choose a category"
                        current={competitor.category}
                        options={comp.categories}
                        onSelect={(opt) => {
                          updateCompetitor("category", opt)

                          // update cost if cost is different per competitor
                          if (comp.categoryCosts?.length > 0) {
                            setCost(
                              comp.categoryCosts.find(
                                (obj) => obj.category === opt,
                              )?.cost,
                            )
                          }
                        }}
                      />
                    </Box>
                  </Box>
                </>
              )}
            {!!comp.isRelativeScoringEnabled && (
              <Box mt={5} zIndex={1} position="relative">
                <Heading as="md" size="h4">
                  {currentUser ? "Their" : "Your"} hardest grade
                </Heading>
                <Box
                  position="relative"
                  zIndex={1}
                  display="flex"
                  alignItems="center"
                >
                  {comp.isScoringRoutes && (
                    <Box>
                      <Text>
                        The scoring for this comp will be adjusted based on your
                        ability. If you end up climbing harder than you thought,
                        your scores will be adjusted accordingly. Choose the
                        hardest route grade you can climb in 2 sessions.
                      </Text>
                      {!isBeforeRegistrationEnds && (
                        <Heading as="h6" size="sm" variant="error">
                          Registration has ended, so you cannot adjust your
                          grade.
                        </Heading>
                      )}
                      <Box position="relative" width={150}>
                        <GradesMenu
                          type="route"
                          isDisabled={!isBeforeRegistrationEnds}
                          grade={competitor.maxRouteGrade}
                          changeGrade={(grade) => {
                            updateCompetitor("maxRouteGrade", grade)
                          }}
                          gym={comp?.gym}
                        />
                      </Box>
                    </Box>
                  )}
                  {!!comp.isScoringProblems && (
                    <Box>
                      <Text>
                        The scoring for this comp will be adjusted based on your
                        ability. If you end up climbing harder than you thought,
                        your scores will be adjusted accordingly. Choose the
                        hardest boulder problem grade you can climb in 2
                        sessions.
                      </Text>
                      {!isBeforeRegistrationEnds && (
                        <Heading as="h6" size="sm" variant="error">
                          Registration has ended, so you cannot adjust your
                          grade.
                        </Heading>
                      )}
                      <Box zIndex={2} position="relative" width={150}>
                        <GradesMenu
                          type="problem"
                          isDisabled={!isBeforeRegistrationEnds}
                          gym={comp?.gym}
                          grade={competitor.maxProblemGrade}
                          changeGrade={(grade) => {
                            updateCompetitor("maxProblemGrade", grade)
                          }}
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
            {!!comp.timeSlots?.length && !team && (
              <>
                <Box my={5}>
                  <hr />
                </Box>

                <Heading as="md" size="h4">
                  Session Times
                </Heading>
                <Text>Choose the time slot you want to climb during:</Text>
                {availableTimeSlots.length ? (
                  <Box display="flex">
                    <RadioGroup
                      id={"time-slots"}
                      value={comp.timeSlotId}
                      onChange={(value) => {
                        updateCompetitor("timeSlotId", value)
                      }}
                    >
                      {availableTimeSlots.map((timeSlot) => (
                        <Radio key={timeSlot._id} value={timeSlot._id}>
                          {timeSlot.name}
                        </Radio>
                      ))}
                    </RadioGroup>
                  </Box>
                ) : (
                  <Heading as="h6" size="sm" variant="error">
                    All time slots are full. Please contact the gym to let them
                    know.
                  </Heading>
                )}
              </>
            )}
            <Box my={5}>
              <hr />
            </Box>
            {!!comp?.competitorQuestions?.length && (
              <>
                <Heading as="h4" size="md">
                  Competitor Questions
                </Heading>
                <Text>Please answer the following questions:</Text>
                {comp.competitorQuestions.map((ques) => (
                  <CompetitorQuestion
                    key={ques._id}
                    question={ques}
                    answer={
                      competitor?.competitorAnswers?.find(
                        (ans) => ans.questionId === ques._id,
                      )?.answer || ""
                    }
                    setAnswer={(answer) => {
                      const newAnswers = competitor?.competitorAnswers?.some(
                        (ans) => ans.questionId === ques._id,
                      )
                        ? competitor.competitorAnswers
                        : [
                            ...(competitor?.competitorAnswers || []),
                            { questionId: ques._id, answer: "" },
                          ]
                      updateCompetitor(
                        "competitorAnswers",
                        newAnswers.map((ans) =>
                          ans.questionId === ques._id
                            ? { questionId: ans.questionId, answer }
                            : ans,
                        ),
                      )
                    }}
                  />
                ))}
                <Box mt={-3} mb={5}>
                  <hr />
                </Box>
              </>
            )}
            {errorMessage && (
              <Heading variant="error" as="h6" size="sm">
                {errorMessage}
              </Heading>
            )}
            {!isCreatingCompetitorAsManager &&
            !isEditing &&
            comp.paymentType === "stripe" &&
            cost &&
            !comp.isChargingTeam ? (
              <>
                {/* ask for coupon code if available */}
                {!!discountCodePrices?.length && (
                  <DiscountCodeForm
                    discountCodePrices={discountCodePrices}
                    initialCost={initialCost}
                    cost={cost}
                    setCost={setCost}
                    discountCode={discountCode}
                    setDiscountCode={setDiscountCode}
                    setMessage={setErrorMessage}
                    setDiscountedPriceId={setDiscountedPriceId}
                  />
                )}

                <Heading as="h5" size="md">
                  Please fill out your credit card details to pay the $
                  {cost.toFixed(2)} for registration.
                </Heading>
                <CompCheckoutForm
                  validateSignup={validateCompetitor}
                  stripeConnectedAccountId={comp.stripeConnectedAccountId}
                  setAlertMessage={setErrorMessage}
                  saveCompetitor={async (paymentIntentId) => {
                    if (!paymentIntentId) {
                      setErrorMessage(
                        "There was an issue charging your card. Please try again later.",
                      )
                    }
                    if (onSave) {
                      await onSave({
                        ...competitor,
                        paymentIntentId,
                      })
                    } else {
                      await saveCompetitor(paymentIntentId)
                    }
                  }}
                  cost={cost}
                  compName={comp.name}
                  gymName={comp?.gym?.name}
                  userEmail={loggedInUser?.email}
                />
              </>
            ) : (
              <Flex>
                <Button
                  flex={1}
                  variant="primary"
                  onClick={async () => {
                    setIsLoading(true)
                    await validateCompetitor()
                    await saveCompetitor()
                  }}
                >
                  {isLoading ? (
                    <FontAwesomeIcon
                      color={whiteColor}
                      icon={faSpinnerThird}
                      spin
                    />
                  ) : (
                    <>{isEditing ? "Save" : "Register"}</>
                  )}
                </Button>
              </Flex>
            )}
            <Flex mt={2}>
              <Button
                flex={1}
                variant="secondary"
                onClick={() => {
                  if (isFromApp) {
                    window?.ReactNativeWebView?.postMessage(
                      "close-pebble-modal",
                    )
                  } else {
                    closeModal()
                  }
                }}
              >
                Cancel
              </Button>
            </Flex>
          </Card>
        </Box>
      </Modal>
    </>
  )
}

export default IndividualRegistrationModal
