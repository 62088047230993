import React, { useState, useEffect } from "react"
import Slider from "rc-slider"
import { getGradeColorObject } from "../../utilities/grades"

import styles from "./css/GradeSlider.module.css"

const GradeSlider = ({ grade, setterGrade, type, gym, changeGrade }) => {
  const [defaultGrade, setDefaultGrade] = useState(setterGrade || grade)

  useEffect(() => {
    if (!defaultGrade) {
      // can remove this whole useEffect once we are certain all indoor climbs have a setterGrade
      setDefaultGrade(setterGrade || grade)
    }
  }, [defaultGrade, grade, setterGrade])
  const colors = gym?.[`${type}GradeColors`]
  const gradeColorObj = getGradeColorObject(defaultGrade, colors)
  // get index of gradeColor and get min and max values from
  const ind = colors.findIndex((obj) => obj.maxGrade === gradeColorObj.maxGrade)
  const prevColorObj = colors[ind - 1]
  const nextColorObj = colors[ind + 1]
  const maxGrade = nextColorObj
    ? gradeColorObj.maxGrade + 2
    : type === "problem"
    ? 25
    : 34
  const minGrade = prevColorObj ? prevColorObj.maxGrade - 1 : 2
  return (
    <div className={styles["slider-cont"]}>
      <div className={styles["slider"]}>
        <Slider
          min={minGrade}
          max={maxGrade}
          value={grade || defaultGrade}
          onChange={(value) => {
            changeGrade(value)
          }}
        />
      </div>
      <div className={styles["color-cont"]}>
        {prevColorObj && (
          <div
            className={styles["color-bar"]}
            style={{
              flex: 2,
              backgroundColor: prevColorObj.color,
            }}
          />
        )}
        <div
          className={styles["color-bar"]}
          style={{
            backgroundColor: gradeColorObj.color,
            flex: maxGrade - minGrade,
          }}
        />
        {nextColorObj && (
          <div
            className={styles["color-bar"]}
            style={{
              flex: 2,
              backgroundColor: nextColorObj.color,
            }}
          />
        )}
      </div>
    </div>
  )
}

export default GradeSlider
