import gql from "graphql-tag"

export const basicMediaFragment = gql`
  fragment basicMediaFragment on Media {
    _id
    name
    slug
    type
    url
    image
    editorId
    problemIds
    routeIds
    isBetaVideo
    video
    oembed {
      provider_name
      thumbnail_url
      author_name
      author_url
      url
      title
      html
    }
  }
`

export default {
  basicMediaFragment,
}
