import React, { useState } from "react"
import { useQuery } from "@apollo/client"
import { SEARCH_COMP_CLIMBS } from "../graphql/comps"
import { Heading, Input } from "@chakra-ui/react"
import Loader from "../../common/components/Loader"
import { Box } from "@chakra-ui/react"
import CompClimbRow from "./CompClimbRow"
import _ from "lodash"

const CompClimbSearch = ({ title, compSetId, setCompClimb }) => {
  const [searchStr, setSearchStr] = useState("")
  const { data, loading, error } = useQuery(SEARCH_COMP_CLIMBS, {
    variables: {
      searchStr,
      compSetId,
    },
    skip: !compSetId,
  })

  const problems = _.sortBy(data?.searchCompClimbs?.problems || [], "grade")
  const routes = _.sortBy(data?.searchCompClimbs?.routes || [], "grade")
  return (
    <>
      <Input
        className="width-100"
        autoComplete="off"
        placeholder={title}
        value={searchStr}
        onChange={(e) => {
          setSearchStr(e.target.value)
        }}
      />
      {loading ? (
        <Loader isRelative size={"3x"} />
      ) : (
        <Box maxHeight={300} overflow="auto">
          {!!problems.length && (
            <Heading size="sm" mt={2}>
              Boulders
            </Heading>
          )}
          {problems.map((problem) => (
            <CompClimbRow
              key={problem._id}
              climb={problem}
              onClick={() => setCompClimb(problem)}
            />
          ))}
          {!!routes.length && (
            <Heading size="sm" mt={2}>
              Routes
            </Heading>
          )}
          {routes.map((route) => (
            <CompClimbRow
              key={route._id}
              climb={route}
              onClick={() => setCompClimb(route)}
            />
          ))}
        </Box>
      )}
    </>
  )
}

export default CompClimbSearch
