import React from "react"
import { Box, BoxProps, useTheme } from "@chakra-ui/react"
import useColors from "../hooks/useColors"

export interface ICardProps extends BoxProps {
  children: React.PropsWithChildren<React.ReactNode>
  variant?: string
  raised?: boolean
}

const Card = ({
  children = <></>,
  variant = "",
  raised = false,
  ...props
}: ICardProps) => {
  const { whiteColor, headingLineColor } = useColors()
  return (
    <Box
      sx={
        variant === "link" && {
          transition: "all 0.1s ease-out",
          ":hover": {
            boxShadow: "0 3px 14px 2px rgb(0 0 0 / 12%)",
            transform: "scale(1.02)",
          },
        }
      }
      cursor={variant === "link" ? "pointer" : "default"}
      overflow={
        ["link", "list", "no-space"].includes(variant) ? "hidden" : null
      }
      p={variant === "no-space" || variant === "list" ? 0 : 5}
      bg={"brand.whiteColor"}
      borderRadius={"lg"}
      position="relative"
      boxShadow={
        variant === "link" || raised ? "0px 1px 10px rgb(0 0 0 / 10%)" : "none"
      }
      mb={variant === "input" ? 5 : 0}
      border={`1px solid`}
      borderColor={"brand.headingLineColor"}
      borderWidth={variant === "link" || raised ? 0 : 1}
      {...props}
    >
      {children}
    </Box>
  )
}

export default Card
