import React from "react"
import NoSSR from "./NoSSR"
import { Tooltip as ReactTooltip } from "react-tooltip"
import { useAtom } from "jotai"
import { isDarkModeAtom } from "../hooks/useColors"

const Tooltip = ({ children, ...props }) => {
  const [isDarkMode] = useAtom(isDarkModeAtom)
  return (
    <NoSSR>
      <ReactTooltip
        className="card-tooltip"
        variant={isDarkMode ? "dark" : "light"}
        {...props}
      >
        {children}
      </ReactTooltip>
    </NoSSR>
  )
}
export default Tooltip
