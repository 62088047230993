import gql from "graphql-tag"

export const newsFragment = gql`
  fragment newsFragment on News {
    _id
    type
    title
    image
    description
    link
    action
    startDate
    endDate
    imageRatio
    isHidingMoreBtn
  }
`
