import gql from "graphql-tag"

export const customGymScreenFragment = gql`
  fragment customGymScreenFragment on CustomGymScreen {
    _id
    name
    description
    image
    link
    parentId
    isPublished
    survey {
      _id
      type
      name
      slug
      description
      picture
    }
  }
`
