import React from "react"
import { useMutation } from "@apollo/client"
import { followMutate, LoggedInUserQuery } from "../graphql/users"

const Follow = (props) => {
  const { loggedInUser, user, render } = props

  const [follow] = useMutation(followMutate, {
    refetchQueries: [{ query: LoggedInUserQuery }],
  })

  if (!loggedInUser || !loggedInUser.slug || loggedInUser.slug === user.slug) {
    return null
  }

  return render({
    follow,
    user,
    loggedInUser,
  })
}

export default Follow
