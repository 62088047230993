import React from "react"
import { getColorPalette, ColorPaletteType } from "../../config/colors"
import { atom, useAtom } from "jotai"

const urlParams =
  typeof window !== "undefined" && new URLSearchParams(window.location.search)
const darkModeParam =
  typeof window !== "undefined" && urlParams.get("dark-mode")
export const isDarkModeAtom = atom(false)
interface UseColorsType extends ColorPaletteType {
  isDarkMode: boolean
  setIsDarkMode: (isDark: boolean) => void
}

export default function useColors(
  initColorScheme: "light" | "dark" | null = null,
): UseColorsType {
  const isDarkModeFn = React.useCallback(() => {
    if (typeof window === "undefined") {
      return true
    }
    if (darkModeParam) {
      return darkModeParam === "true"
    }
    const localStorageDarkMode = localStorage.getItem("darkMode")
    if (localStorageDarkMode) {
      return localStorageDarkMode === "true"
    }
    return (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    )
  }, [])
  const [isDark, setIsDark] = useAtom(isDarkModeAtom)
  React.useEffect(() => {
    setIsDark(isDarkModeFn())
  }, [isDarkModeFn, setIsDark])
  const systemColorScheme = isDarkModeFn() ? "dark" : "light"
  const colorScheme = initColorScheme || systemColorScheme
  return {
    ...getColorPalette(colorScheme),
    isDarkMode: isDark || systemColorScheme === "dark",
    setIsDarkMode: (isD) => {
      localStorage.setItem("darkMode", isD.toString())
      setIsDark(isDarkModeFn())
    },
  }
}
