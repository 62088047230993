import gql from "graphql-tag"
import { basicUserFragment } from "../../users/graphql/basicUserFragment"
import { basicProblemFragment } from "../../guides/graphql/basicProblemFragment"
import { basicRouteFragment } from "../../guides/graphql/basicRouteFragment"

export const basicSendFragment = gql`
  fragment basicSendFragment on Send {
    _id
    slug
    sendType
    name
    type
    grade
    tries
    date
    user {
      ...basicUserFragment
    }
    problem {
      ...basicProblemFragment
      numUserSends
      numUserAttempts
      userCompScores {
        _id
        score
        highpoint
        numAttempts
        createdAt
        comp {
          _id
        }
        compSet {
          _id
        }
        competitor {
          _id
          type
          maxProblemGrade
          maxRouteGrade
          previousMaxRouteGrades {
            grade
            maxDate
          }
          previousMaxProblemGrades {
            grade
            maxDate
          }
        }
      }
    }
    route {
      ...basicRouteFragment
      numUserSends
      numUserAttempts
      userCompScores {
        _id
        score
        highpoint
        numAttempts
        createdAt
        comp {
          _id
        }
        compSet {
          _id
        }
        competitor {
          _id
          type
          maxProblemGrade
          maxRouteGrade
          previousMaxRouteGrades {
            grade
            maxDate
          }
          previousMaxProblemGrades {
            grade
            maxDate
          }
        }
      }
    }
    isIndoor
  }
  ${basicUserFragment}
  ${basicProblemFragment}
  ${basicRouteFragment}
`

export default { basicSendFragment }
