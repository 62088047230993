import _ from "lodash"
import React, { useContext, useEffect, useState } from "react"
import { useApolloClient } from "@apollo/client"
import { useRouter, withRouter } from "next/router"
import {
  IconButton,
  List,
  ListItem,
  Link,
  Heading,
  Box,
} from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes, faBars } from "@fortawesome/pro-light-svg-icons"
import { faUser } from "@fortawesome/free-solid-svg-icons"
import ProfileMenu from "../../users/components/ProfileMenu"
import { navigationTree, loginNavigationTree } from "../../config/navigation"
import NotificationsMenu from "../../users/components/NotificationsMenu"
import { logout } from "../../utilities/logout"
import { NavContext } from "../../context/NavContext"
import ScrollToTop from "../../common/components/ScrollToTop"
import TextLink from "../../common/components/TextLink"
import NextLink from "next/link"
import styles from "./css/Navigation.module.css"
import Image from "next/image"
import useColors from "../../common/hooks/useColors"
import DarkModeToggle from "../../common/components/DarkModeToggle"
import { useAuth } from "../contexts/AuthContext"

function checkAuthentication(section, loggedInUser) {
  if (!section.authentication) {
    return true
  }
  if (section.authentication === "owner" && loggedInUser?.isGymOwner) {
    return true
  }
  return false
}

const NavLink = ({
  ind,
  router,
  setIsMobileNavOpen,
  selectedNav,
  loggedInUser,
}) => {
  const isLoginPage = router.pathname && router.pathname.includes("/login")
  const navTree = navigationTree(loggedInUser)
  const obj = isLoginPage ? loginNavigationTree[ind] : navTree[ind]

  if (!obj) {
    return null
  }
  return (
    <div className={styles["nav__item"]}>
      <NextLink passHref href={obj.link || ""}>
        <Link
          onClick={() => {
            setIsMobileNavOpen(false)
          }}
          className={`${styles["item-link"]} ${
            styles[selectedNav === obj.link ? "active" : ""]
          } no-hover-underline`}
        >
          {isLoginPage ? null : <span className={styles["item__background"]} />}
          <span>{obj.title}</span>
        </Link>
      </NextLink>
      {obj.sections && isLoginPage === false ? (
        <List className={styles["item__submenu"]}>
          {obj.sections
            .filter((sect) => checkAuthentication(sect, loggedInUser))
            .map((sub) => {
              return (
                <ListItem key={sub.link} className={styles["item__listitem"]}>
                  <TextLink
                    onClick={() => {
                      setIsMobileNavOpen(false)
                    }}
                    to={{
                      pathname: sub.link,
                    }}
                    isnohover="true"
                  >
                    <Heading
                      sx={{
                        _hover: {
                          background: "blackAlpha.100",
                        },
                      }}
                      textAlign={["right", "left"]}
                      py={4}
                      mx={-5}
                      px={5}
                      color={["brand.blueColor", "brand.whiteColor"]}
                      as="h5"
                      size="md"
                    >
                      {sub.title}
                    </Heading>
                  </TextLink>
                </ListItem>
              )
            })}
        </List>
      ) : null}
      <ScrollToTop />
    </div>
  )
}

const Navigation = () => {
  const router = useRouter()
  const { blueColor } = useColors()
  const { selectedNav } = useContext(NavContext)
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
  const client = useApolloClient()
  const { loggedInUser } = useAuth()
  const isIndexLoading = router.pathname === "/" && !loggedInUser?._id

  if (_.get(loggedInUser, "isBanned")) {
    logout(client)
  }
  const isLoginPage = router.pathname.includes("/login")
  const notifications = _.get(loggedInUser, "notifications")

  // hide nav if logging in
  const [isHidingNav, setIsHidingNav] = useState(false)
  const hideNav = () => setIsHidingNav(true)
  const showNav = () => setIsHidingNav(false)
  useEffect(() => {
    // on route change start - hide page content by setting authorized to false
    router.events.on("routeChangeStart", () => hideNav())

    // on route change complete - run auth check
    router.events.on("routeChangeComplete", showNav)

    // unsubscribe from events in useEffect return function
    return () => {
      router.events.off("routeChangeStart", hideNav)
      router.events.off("routeChangeComplete", showNav)
    }
  }, [router.events])

  if ((isHidingNav && isLoginPage) || isIndexLoading) {
    return null
  }

  return (
    <div
      className={"navigation " + styles[isLoginPage ? "nav--login" : "nav"]}
      role="navigation"
    >
      <IconButton
        variant="ghost"
        className={styles["hamburger"]}
        onClick={() => {
          setIsMobileNavOpen(true)
        }}
      >
        <FontAwesomeIcon color={blueColor} icon={faBars} />
      </IconButton>
      <div
        className={
          styles[isMobileNavOpen ? "open" : ""] + " " + styles["nav__items"]
        }
      >
        <div className={styles["close-menu"]}>
          <IconButton
            variant="ghost"
            className={styles["close-icon"]}
            onClick={() => {
              setIsMobileNavOpen(false)
            }}
          >
            <FontAwesomeIcon color={blueColor} icon={faTimes} />
          </IconButton>
        </div>
        {/* {isLoginPage && (
          <NavLink
            ind={0}
            selectedNav={selectedNav}
            router={router}
            setIsMobileNavOpen={setIsMobileNavOpen}
            loggedInUser={loggedInUser}
          />
        )} */}
        <NextLink href="/" className={"no-hover-underline"}>
          <span className={styles["logo-cont"]}>
            <Image
              objectFit="contain"
              alt="logo"
              className={styles["logo"]}
              src={"/images/pebble-logo.svg"}
              width={55}
              height={55}
            />
            {!isMobileNavOpen && (
              <>
                <span className={styles["logo-line"]} />
                <h1>
                  pebble<span className={styles["sub-head"]}>climbing</span>
                </h1>
              </>
            )}
          </span>
        </NextLink>
        {!isLoginPage && (
          <NavLink
            ind={0}
            selectedNav={selectedNav}
            router={router}
            setIsMobileNavOpen={setIsMobileNavOpen}
            loggedInUser={loggedInUser}
          />
        )}
        {!isLoginPage && (
          <NavLink
            ind={1}
            selectedNav={selectedNav}
            router={router}
            setIsMobileNavOpen={setIsMobileNavOpen}
            loggedInUser={loggedInUser}
          />
        )}
        {isLoginPage ? null : (
          <NavLink
            ind={2}
            selectedNav={selectedNav}
            router={router}
            setIsMobileNavOpen={setIsMobileNavOpen}
            loggedInUser={loggedInUser}
          />
        )}
        {!isLoginPage && (
          <NavLink
            ind={3}
            selectedNav={selectedNav}
            router={router}
            setIsMobileNavOpen={setIsMobileNavOpen}
            loggedInUser={loggedInUser}
          />
        )}
        {isLoginPage ? null : (
          <Box
            ml="auto"
            mr={2}
            display="flex"
            position={"relative"}
            zIndex={1000}
            alignItems="center"
          >
            <Box mt={1} mr={8} display={["none", "block"]}>
              <DarkModeToggle />
            </Box>
            {notifications && notifications.length !== 0 ? (
              <NotificationsMenu notifications={notifications} />
            ) : null}
            {loggedInUser ? (
              <ProfileMenu
                loggedInUser={loggedInUser}
                logOut={() => {
                  logout(client)
                }}
              />
            ) : isLoginPage ? null : (
              <div>
                <IconButton
                  size="lg"
                  variant="secondary"
                  onClick={() => {
                    router.push({
                      pathname: "/login",
                      query: {
                        returnUrl: router.asPath,
                      },
                    })
                  }}
                >
                  <FontAwesomeIcon size="lg" color={blueColor} icon={faUser} />
                </IconButton>
              </div>
            )}
          </Box>
        )}
      </div>
    </div>
  )
}

export default Navigation
