import { getColorPalette } from "./config/colors"

export function getTheme(colorScheme) {
  const colors = getColorPalette(colorScheme)
  return {
    fonts: {
      logoFont: `'Comfortaa', Helvetica, Arial, sans-serif`,
      headingFont: `'Manrope', Helvetica, Arial, sans-serif`,
      copyFont: `'Open Sans', Helvetica, Arial, sans-serif`,
    },
    fontSizes: ["0px", "36px", "28px", "22px", "18px", "14px", "12px"],
    colors,
    space: [
      "0px",
      "4px",
      "8px",
      "16px",
      "20px",
      "30px",
      "40px",
      "50px",
      "60px",
      "80px",
    ],
  }
}
const theme = getTheme("light")
export default theme
