import gql from "graphql-tag"

export const GET_PREMIUM_GYM_SUBSCRIPTION_PRODUCT = gql`
  query GetPremiumGymSubscriptionProduct {
    premiumGymSubscriptionProduct {
      _id
      name
      description
      price
      priceId
    }
  }
`

export const CREATE_PAYMENT_SUBSCRIPTION_INTENT = gql`
  mutation CreatePaymentSubscriptionIntent($paymentMethodId: String!) {
    createPaymentSubscriptionIntent(paymentMethodId: $paymentMethodId) {
      _id
      paymentIntentClientSecret
      paymentIntentStatus
    }
  }
`

export const CREATE_GYM_SUBSCRIPTION = gql`
  mutation createGymSubscription($priceId: String!, $paymentMethodId: String!) {
    createGymSubscription(
      priceId: $priceId
      paymentMethodId: $paymentMethodId
    ) {
      _id
      stripeCustomerId
      stripeSubscriptionInfo {
        id
        status
      }
    }
  }
`

export const CHANGE_CARD = gql`
  mutation ChangeCard($paymentMethodId: String!) {
    changeCard(paymentMethodId: $paymentMethodId) {
      _id
      stripeCustomerId
      stripeSubscriptionInfo {
        id
        status
      }
      paymentIntentClientSecret
    }
  }
`

export const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription {
    cancelSubscription {
      _id
      stripeCustomerId
      stripeSubscriptionInfo {
        id
        status
      }
    }
  }
`

export const GET_CURRENT_BILLING = gql`
  query GetCurrentBilling {
    getCurrentBilling {
      gyms {
        _id
        name
        slug
      }
      items {
        quantity
        description
        trialEnds
        startDate
        endDate
        hasStripePaymentIssue
      }
    }
  }
`

export const REDIRECT_TO_PORTAL = gql`
  mutation RedirectToPortal {
    redirectToPortal {
      url
    }
  }
`

export const CREATE_CONNECTED_STRIPE_ACCOUNT = gql`
  mutation CreateConnectedStripeAccount($returnURL: String!, $compId: String!) {
    createConnectedStripeAccount(returnURL: $returnURL, compId: $compId) {
      url
    }
  }
`

export const GET_USER_CONNECTED_ACCOUNT = gql`
  query GetUserConnectedAccount {
    getUserConnectedAccount {
      _id
      areStripeChargesEnabled
      stripeConnectedAccountId
    }
  }
`

export const GET_COMP_CONNECTED_ACCOUNT = gql`
  query GetCompConnectedAccount($compId: String!) {
    getCompConnectedAccount(compId: $compId) {
      _id
      areStripeChargesEnabled
      stripeConnectedAccountId
    }
  }
`

export const CREATE_CONNECTED_PAYMENT_INTENT = gql`
  mutation CreateConnectedPaymentIntent(
    $cost: Int!
    $stripeConnectedAccountId: String!
    $description: String
  ) {
    createConnectedPaymentIntent(
      cost: $cost
      stripeConnectedAccountId: $stripeConnectedAccountId
      description: $description
    ) {
      paymentIntentClientSecret
      paymentIntentStatus
    }
  }
`

export const CREATE_COMP_PAYMENT_INTENT_MOBILE = gql`
  mutation CreateCompPaymentIntentMobile(
    $cost: Int!
    $stripeConnectedAccountId: String!
    $description: String
  ) {
    createCompPaymentIntentMobile(
      cost: $cost
      stripeConnectedAccountId: $stripeConnectedAccountId
      description: $description
    ) {
      paymentIntentClientSecret
      stripeCustomerId
      stripeEphemeralKey
      paymentIntentId
    }
  }
`
