import gql from "graphql-tag"
import { basicSendFragment } from "../../sends/graphql/basicSendFragment"
import { basicGymFragment } from "../../gyms/graphql/basicGymFragment"
import { basicGuideFragment } from "../../guides/graphql/basicGuideFragment"
import { basicUserFragment } from "../../users/graphql/basicUserFragment"
import { basicMediaFragment } from "../../media/graphql/basicMediaFragment"
import { gameSponsorFragment } from "../../gameSponsors/graphql/gameSponsors"

export const pyramidSendFragment = gql`
  fragment pyramidSendFragment on Send {
    ...basicSendFragment
    isGrade
    problem {
      _id
      type
      grade
      gradeColor
      setterGrade
      gym {
        _id
        name
        type
        slug
        isOverlappingGrades
        isShowingGrades
        routeGradeColors {
          color
          name
          minGrade
          maxGrade
          image
        }
        problemGradeColors {
          color
          name
          minGrade
          maxGrade
          image
        }
      }
      gymArea {
        _id
        slug
        type
        name
        sortOrder
        setterPicture
        popMedia {
          ...basicMediaFragment
        }
      }
    }
    route {
      _id
      slug
      type
      grade
      gradeColor
      setterGrade
      gym {
        _id
        name
        type
        slug
        isOverlappingGrades
        isShowingGrades
        routeGradeColors {
          color
          name
          minGrade
          maxGrade
          image
        }
        problemGradeColors {
          color
          name
          minGrade
          maxGrade
          image
        }
      }
      gymArea {
        _id
        slug
        type
        name
        sortOrder
        setterPicture
        popMedia {
          ...basicMediaFragment
        }
      }
    }
  }
  ${basicSendFragment}
  ${basicMediaFragment}
`

export const gamePyramidFragment = gql`
  fragment gamePyramidFragment on GamePyramid {
    _id
    type
    sends {
      ...pyramidSendFragment
    }
    gym {
      ...basicGymFragment
    }
    guide {
      ...basicGuideFragment
    }
    date
    climbType
    isComplete
    gradeRows
    level
    numCompletedLevels
    previousTimesTried
    previousLevel
    gymPointsPerLevel
    wasAwardedRetailCredits
    user {
      ...basicUserFragment
    }
    gameSponsor {
      ...gameSponsorFragment
    }
  }
  ${pyramidSendFragment}
  ${basicGymFragment}
  ${basicGuideFragment}
  ${basicUserFragment}
  ${gameSponsorFragment}
`

export const GET_PYRAMID_SENDS = gql`
  query GetPyramidSends(
    $sinceDate: Date!
    $userId: String!
    $climbType: String!
    $isIndoor: Boolean!
    $gradeIndDiff: Int
    $gymId: String
    $guideId: String
  ) {
    getPyramidSends(
      sinceDate: $sinceDate
      userId: $userId
      climbType: $climbType
      isIndoor: $isIndoor
      gradeIndDiff: $gradeIndDiff
      gymId: $gymId
      guideId: $guideId
    ) {
      ...pyramidSendFragment
    }
  }
  ${pyramidSendFragment}
`

export const GAME_PYRAMID = gql`
  query GamePyramid(
    $_id: String
    $date: Date
    $climbType: String
    $gymId: String
  ) {
    gamePyramid(_id: $_id, date: $date, climbType: $climbType, gymId: $gymId) {
      ...gamePyramidFragment
    }
  }
  ${gamePyramidFragment}
`

export const USER_GAME_PYRAMIDS = gql`
  query UserGamePyramids(
    $userId: String
    $climbType: String
    $gymId: String
    $cursor: String
  ) {
    userGamePyramids(
      userId: $userId
      climbType: $climbType
      gymId: $gymId
      cursor: $cursor
    ) {
      pyramids {
        _id
        type
      }
      cursor
      hasMore
    }
  }
`
