import { useLazyQuery } from '@apollo/client';
import { Box, Button, Flex, Heading, Input } from '@chakra-ui/react';
import * as React from 'react';
import { Competitor } from "../../../gql/types/graphql"
import Loader from '../../common/components/Loader';
import { QUERY_COMPETITOR_FROM_CODE } from '../graphql/comps';
import CompetitorCard from './CompetitorCard';

export interface ICompetitorFromCompCodeInputProps {
  competitor: Competitor;
  setCompetitor: (competitor: Competitor) => void;
  setCompetitorCode?: (competitorCode: string) => void;
}

export default function CompetitorFromCompCodeInput ({ competitor, setCompetitor, setCompetitorCode = () => null }: ICompetitorFromCompCodeInputProps) {
  const [code, setCode] = React.useState<string>("");
  const [getCompetitor, { data, loading, error }] = useLazyQuery(QUERY_COMPETITOR_FROM_CODE, {
    variables: { code },
  })
  React.useEffect(() => {
    if (data && data.queryCompetitorFromCode) {
      setCompetitor(data.queryCompetitorFromCode);
      setCompetitorCode(code);
    }
  }, [data, setCompetitor, setCompetitorCode, code])
  return (
    <>
      {competitor ? (
        <Box>
          <CompetitorCard competitor={competitor} />
        </Box>
      ) : (
        <Box>
          <Heading as="h4" size="sm">
            Enter in your competitor code found in your
            registration email. You can also ask a manager to
            find your code for you.
          </Heading>
          <Flex align="center">
            <Input
              value={code}
              placeholder="Competitor code"
              onChange={(e) => {
                setCode(e.target.value)
              }}
            />
            {loading
              ? <Box ml={3}><Loader isRelative size="2x" /></Box>
              : <Button
                ml={3}
                onClick={async () => {
                  getCompetitor()
                }}
              >
                Submit
              </Button>}
          </Flex>
        </Box>
      )}
    </>
  );
}
