import { useMutation } from "@apollo/client"
import {
  CREATE_SCANNER_COMP_SCORE,
  GET_COMPETITORS_SCORES,
} from "src/app/gyms/graphql/comps"
import { CompScore } from "src/gql/types/graphql"

export function useSaveScannerCompScore({
  setMessage,
  returnFromSave,
}: {
  setMessage: (message: string) => void
  returnFromSave?: (score: CompScore) => void
}) {
  const [createScannerCompScore] = useMutation(CREATE_SCANNER_COMP_SCORE)
  let returnScore = null
  async function saveScannerCompScore({
    activityId,
    score,
    compSetId,
    competitorId,
  }) {
    try {
      const { data } = await createScannerCompScore({
        variables: {
          scannerCompScore: {
            activityId,
            score,
            compSetId,
            competitorId,
          },
        },
        refetchQueries: [
          {
            query: GET_COMPETITORS_SCORES,
            variables: {
              compSetId,
              competitorId,
            },
          },
        ],
      })
      returnScore = data.createScannerCompScore
    } catch (e) {
      setMessage("Error saving score")
    }
    returnFromSave(returnScore)
  }
  return saveScannerCompScore
}

export default useSaveScannerCompScore
