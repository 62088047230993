import React from "react"

import Router from "next/router"

// Save the scroll position for the given url
function saveScrollPosition(url, savePosition) {
  if (window.scrollY !== 0) {
    savePosition(url, window.scrollY)
  }
}

// Restore the scroll position for the given url is possible
function restoreScrollPosition(url, positions) {
  const position = positions.current[url]
  if (position) {
    window.scrollTo({ top: position })
  } else {
    window.scrollTo({ top: 0 })
  }
}

export default function useScrollRestoration(router) {
  const positions = React.useRef({})

  const updatePosition = (url, pos) => {
    positions.current = {
      ...positions.current,
      [url]: pos,
    }
  }

  React.useEffect(() => {
    const onScroll = () => {
      saveScrollPosition(router.asPath.split("?")[0], updatePosition)
    }

    const onRouteChangeComplete = (url) => {
      restoreScrollPosition(url.split("?")[0], positions)
    }

    window.addEventListener("scroll", onScroll)
    Router.events.on("routeChangeComplete", onRouteChangeComplete)

    return () => {
      window.removeEventListener("scroll", onScroll)
      Router.events.off("routeChangeComplete", onRouteChangeComplete)
    }
  }, [router.asPath])

  return positions
}
