import gql from "graphql-tag"

export const basicUserFragment = gql`
  fragment basicUserFragment on User {
    _id
    name
    firstName
    lastName
    slug
    isFrenchGrades
    isScrambledUser
    profile {
      _id
      picture
    }
    brandsRepresenting {
      _id
      logo
      isHidden
    }
  }
`

export default { basicUserFragment }
