import React from "react"
import Unfollow from "../graphqlComponents/Unfollow"
import Follow from "../graphqlComponents/Follow"

const FollowUserBtnCont = (props) => {
  const { loggedInUser, user, searchStr, render } = props
  const isFollowing =
    loggedInUser &&
    (loggedInUser.following || []).map((u) => u.slug).includes(user.slug)
  const isWaiting =
    loggedInUser && (loggedInUser.awaitingFollowing || []).includes(user._id)
  return (
    <Unfollow
      loggedInUser={loggedInUser}
      user={user}
      searchStr={searchStr}
      render={({ unfollow }) => (
        <Follow
          loggedInUser={loggedInUser}
          user={user}
          searchStr={searchStr}
          render={({ follow }) =>
            render({
              follow,
              unfollow,
              isFollowing,
              isWaiting,
            })
          }
        />
      )}
    />
  )
}

FollowUserBtnCont.propTypes = {}

export default FollowUserBtnCont
