import React from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useQuery } from "@apollo/client"
import { AthletesSearchQuery } from "../../users/graphql/users"
import { Box, Heading } from "@chakra-ui/react"

const AthletesSearchList = ({
  searchStr,
  compId,
  filterFn = () => true,
  Row,
  loggedInUser,
  onClick,
  listHeight = 181,
}) => {
  const { data, loading, error, fetchMore } = useQuery(AthletesSearchQuery, {
    skip: !searchStr,
    variables: {
      searchStr,
      compId,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  })
  // if (loading && !data?.athletesSearch)
  //   return (
  //     <Box my={2}>
  //       <Loader isRelative size="2x" />
  //     </Box>
  //   )
  if (!data?.athletesSearch?.athletes && !loading) {
    return null
  }
  const { athletesSearch: { athletes = [], cursor, hasMore } = {} } = data || {}

  const filteredAthletes = filterFn ? athletes.filter(filterFn) : athletes

  return (
    <Box
      overflow="auto"
      my={3}
      bg={"brand.whiteColor"}
      border={`1px solid `}
      borderColor="brand.headingLineColor"
      minWidth={300}
      height={listHeight}
      borderRadius={5}
    >
      <InfiniteScroll
        initialLoad={false}
        pageStart={0}
        loadMore={(page) => {
          fetchMore({
            variables: { cursor, searchStr, compId },
          })
        }}
        hasMore={hasMore}
        loader={<Heading size="sm">Loading ...</Heading>}
      >
        {filteredAthletes.map((athlete, ind) => {
          return (
            <Row
              ind={ind}
              key={athlete.slug}
              cursor={cursor}
              loggedInUser={loggedInUser}
              athlete={athlete}
              user={athlete}
              searchStr={searchStr}
              onClick={onClick}
            />
          )
        })}
      </InfiniteScroll>
    </Box>
  )
}

export default AthletesSearchList
