import React, { useState } from "react"
import { Button, Flex, Heading, Text, useTheme } from "@chakra-ui/react"
import Card from "../../common/components/Card"
import Modal from "react-modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons"
import useColors from "../../common/hooks/useColors"

import styles from "./css/ConfirmModal.module.css"

const ConfirmModal = (props) => {
  const {
    isOpen,
    title,
    description,
    highlightedValues,
    actionString,
    closeString = "Cancel",
    action,
    closeModal,
  } = props
  const { whiteColor } = useColors()

  const [isConfirming, setIsConfirming] = useState(false)
  return (
    <Modal
      isOpen={isOpen === true}
      className={styles["confirm-modal"] + " modal-container flex-center"}
      overlayClassName="modal-overlay"
      onRequestClose={closeModal}
      ariaHideApp={false}
      contentLabel={title}
    >
      <Card>
        <Heading size="lg" as="h3">
          {title}
        </Heading>
        <Text>{description}</Text>
        <ul className={styles["list"]}>
          {highlightedValues
            ? highlightedValues.map((obj) => (
                <li key={obj.key}>
                  <span className="mdl-color-text--slice-highlight">
                    {obj.value}
                  </span>
                </li>
              ))
            : null}
        </ul>
        <Flex justify="flex-end">
          <Button mr={3} variant="secondary" onClick={closeModal}>
            {closeString}
          </Button>
          <Button
            mr={3}
            isLoading={isConfirming}
            variant="primary"
            onClick={async () => {
              setIsConfirming(true)
              try {
                await action()
              } catch (e) {
                console.error(e)
              }
              setIsConfirming(false)
              closeModal()
            }}
          >
            {isConfirming ? (
              <FontAwesomeIcon color={whiteColor} icon={faSpinnerThird} spin />
            ) : (
              actionString
            )}
          </Button>
        </Flex>
      </Card>
    </Modal>
  )
}

export default ConfirmModal
