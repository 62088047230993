import gql from "graphql-tag"
import { basicMediaFragment } from "../../media/graphql/basicMediaFragment"

export const basicAreaFragment = gql`
  fragment basicAreaFragment on Area {
    _id
    name
    slug
    type
    location {
      coordinates
    }
    numProblems
    numRoutes
    numAreas
    popMedia {
      ...basicMediaFragment
    }
    parentArea {
      _id
      parentArea {
        _id
      }
    }
  }
  ${basicMediaFragment}
`

export default {
  basicAreaFragment,
}
