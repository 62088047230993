import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"

dayjs.extend(timezone)

//hack for dayjs timezone issue
export function patchDate() {
  // Fix for DayJS timezone issue:
  // https://github.com/iamkun/dayjs/issues/1377#issuecomment-1191900686
  ;(Date.prototype as any)._toLocaleString = Date.prototype.toLocaleString
  Date.prototype.toLocaleString = function toLocaleStringFix(
    a?: Intl.LocalesArgument,
    b?: Intl.DateTimeFormatOptions,
  ): string {
    if (b && Object.keys(b).length === 1 && "timeZone" in b && a === "en-US") {
      return Intl.DateTimeFormat("en-us", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
        timeZone: b.timeZone,
      })
        .format(this)
        .replace(/(\d{2})\/(\d{2})\/(\d{4}),/g, "$3-$1-$2")
    }

    return this._toLocaleString(a, b)
  }
}

export function isInEuropeanUnion() {
  const EU_TIMEZONES = [
    "Europe/Vienna",
    "Europe/Brussels",
    "Europe/Sofia",
    "Europe/Zagreb",
    "Asia/Famagusta",
    "Asia/Nicosia",
    "Europe/Prague",
    "Europe/Copenhagen",
    "Europe/Tallinn",
    "Europe/Helsinki",
    "Europe/Paris",
    "Europe/Berlin",
    "Europe/Busingen",
    "Europe/Athens",
    "Europe/Budapest",
    "Europe/Dublin",
    "Europe/Rome",
    "Europe/Riga",
    "Europe/Vilnius",
    "Europe/Luxembourg",
    "Europe/Malta",
    "Europe/Amsterdam",
    "Europe/Warsaw",
    "Atlantic/Azores",
    "Atlantic/Madeira",
    "Europe/Lisbon",
    "Europe/Bucharest",
    "Europe/Bratislava",
    "Europe/Ljubljana",
    "Africa/Ceuta",
    "Atlantic/Canary",
    "Europe/Madrid",
    "Europe/Stockholm",
  ]

  return EU_TIMEZONES.includes(dayjs.tz.guess())
}
