import _, { has } from "lodash"
import dayjs from "dayjs"
import {
  getGradeCollectionFromGym,
  getGradeObject,
} from "../../app/utilities/grades"
import {
  getRelativeGradeDelta,
  getTopNScores,
} from "../../app/utilities/scores"
import exportFromJSON from "export-from-json"
export const ALL_CATEGORIES = "All categories"
import LocalizedFormat from "dayjs/plugin/localizedFormat"

dayjs.extend(LocalizedFormat)

export const getCostOfComp = ({ comp, loggedInUser, category }) => {
  if (!comp.cost) {
    return 0
  }
  let cost =
    category && comp.categoryCosts?.length > 0
      ? comp.categoryCosts.find((obj) => obj.category === category)?.cost
      : comp.cost
  const rewardGyms =
    loggedInUser?.gymMembershipRewards?.map((rew) => rew.gymId) ?? []
  const compGyms = comp.gyms.map((gym) => gym._id) ?? []
  const isGymMember = rewardGyms.some((gym) => compGyms.includes(gym))
  if (isGymMember && comp.gymMemberCost) {
    cost = comp.gymMemberCost
  }
  const isEarlyBird =
    comp.earlyBirdAmountOffDate &&
    dayjs(comp.earlyBirdAmountOffDate).isAfter(dayjs())

  if (isEarlyBird) {
    cost = cost - comp.earlyBirdAmountOff
  }
  // get discount prices if expiration date and/or number used is not yet reached
  const discountToUse = _.sortBy(comp.discountedPrices ?? [], "price")?.find(
    (discount) => {
      const isNotExpired =
        !discount.expirationDate ||
        dayjs(discount.expirationDate).isAfter(dayjs())
      const isNotUsedUp =
        !discount.numberAvailable ||
        !discount.numberUsed ||
        discount.numberUsed < discount.numberAvailable
      const isInCategory =
        !comp.categoryCosts?.length || discount.categories?.includes(category)
      const isForMembers = !discount.isForMembers || isGymMember
      return (
        isNotExpired &&
        isNotUsedUp &&
        isInCategory &&
        !discount.code &&
        isForMembers
      )
    },
  )
  if (discountToUse && discountToUse.price < cost) {
    cost = discountToUse.price
  }

  return cost
}

export const getDiscountId = ({ comp, loggedInUser, category }) => {
  if (!comp.cost) {
    return null
  }
  const rewardGyms =
    loggedInUser?.gymMembershipRewards?.map((rew) => rew.gymId) ?? []
  const compGyms = comp.gyms.map((gym) => gym._id) ?? []
  const isGymMember = rewardGyms.some((gym) => compGyms.includes(gym))
  const discountToUse = _.sortBy(comp.discountedPrices ?? [], "price")?.find(
    (discount) => {
      const isNotExpired =
        !discount.expirationDate ||
        dayjs(discount.expirationDate).isAfter(dayjs())
      const isNotUsedUp =
        !discount.numberAvailable ||
        !discount.numberUsed ||
        discount.numberUsed < discount.numberAvailable
      const isInCategory =
        !comp.categoryCosts?.length || discount.categories?.includes(category)
      const isForMembers = discount.isForMembers && isGymMember
      return (
        isNotExpired &&
        isNotUsedUp &&
        isInCategory &&
        !discount.code &&
        isForMembers
      )
    },
  )
  return discountToUse?._id
}

export const getDiscountMessages = (discounts, numTotalCategories) => {
  const sortedDiscounts = _.sortBy(discounts, (d) => d?.categories?.length ?? 0)

  const msgs = sortedDiscounts.reduce((acc, discount, ind) => {
    const prevDiscounts = sortedDiscounts.slice(0, ind)
    const prevSimilarDiscountIndex = _.findLastIndex(
      prevDiscounts,
      (d) =>
        d.expirationDate === discount.expirationDate &&
        d.isForMembers === discount.isForMembers &&
        !d.numberAvailable,
    )

    if (prevSimilarDiscountIndex !== -1) {
      const repStr = acc[prevSimilarDiscountIndex].includes("category")
        ? "category"
        : "categories"
      acc[prevSimilarDiscountIndex] = acc[prevSimilarDiscountIndex].replace(
        repStr,
        `${repStr}, and $${discount.price
          .toFixed(2)
          .replace(/[.,]0+$/, "")} for all other categories`,
      )
      return acc
    } else {
      let msg =
        `It’s ${discount.expirationDate ? "currently" : ""} only $` +
        discount.price.toFixed(2).replace(/[.,]0+$/, "")
      if (discount.isForMembers) {
        msg += " for members"
      }
      if (
        discount.categories?.length &&
        numTotalCategories !== discount.categories?.length
      ) {
        msg += ` ${
          discount.isForMembers ? "in" : "for"
        } the ${discount.categories.join(
          discount.categories.length === 2 ? " and " : ", ",
        )} ${discount.categories.length === 1 ? "category" : "categories"}`
      }
      if (discount.numberAvailable) {
        const numLeft = discount.numberAvailable - discount.numberUsed
        msg += `, but there ${numLeft === 1 ? "is" : "are"} only ${
          discount.numberAvailable - discount.numberUsed
        } left at this price`
      }
      if (discount.expirationDate) {
        msg += `${
          discount.numberAvailable ? ". This discount is" : ", "
        } valid until ${dayjs(discount.expirationDate).format("LLL")}`
      }
      msg += ". "
      return [...acc, msg]
    }
  }, [])
  return msgs
}

export const getDiscountCodePrices = ({ comp, category, loggedInUser }) => {
  const rewardGyms =
    loggedInUser?.gymMembershipRewards?.map((rew) => rew.gymId) ?? []
  const compGyms = comp.gyms.map((gym) => gym._id) ?? []
  const isGymMember = rewardGyms.some((gym) => compGyms.includes(gym))
  return (comp.discountedPrices ?? []).filter(
    (d) =>
      !!d.code &&
      (!d.expirationDate || Date.parse(d.expirationDate) > Date.now()) &&
      (!d.numberAvailable || d.numberAvailable > d.numberUsed) &&
      (!d.isForMembers || isGymMember) &&
      (!comp.categoryCosts?.length || d.categories?.includes(category)),
  )
}

export const validateCompetitor = ({
  competitor,
  comp,
  setAlertMessage,
  isCreatingCompetitorAsManager,
}) => {
  if (isCreatingCompetitorAsManager && !competitor.userId) {
    setAlertMessage("Please choose a Pebble user that will be the competitor.")
    return false
  }
  const errMsg = "You have errors above. "
  if (comp.categories?.length && !comp.isTeamCategory && !competitor.category) {
    setAlertMessage(errMsg + "Please select a category.", true)
    return false
  }
  if (
    comp.isRelativeScoringEnabled &&
    ((!competitor.maxProblemGrade && comp.isScoringProblems) ||
      (!competitor.maxRouteGrade && comp.isScoringRoutes))
  ) {
    setAlertMessage(
      errMsg + "Please choose a max grade that you can climb in 2 sessions",
      true,
    )
    return false
  }

  // if (comp.timeSlots?.length && !competitor.timeSlotId) {
  //   setAlertMessage(errMsg + "Please select a session time to climb during. ")
  //   return false
  // }
  // competitor questions
  let isReturning = false
  const quests = comp.competitorQuestions || []
  quests.forEach((question) => {
    const answer = competitor?.competitorAnswers?.find(
      (ans) => ans.questionId === question._id,
    )
    if (!answer?.answer && question.isRequired) {
      setAlertMessage(
        errMsg + `An answer for the question: ${question.title} is required.`,
        true,
      )
      isReturning = true
    }
    if (answer?.answer !== "yes" && question.isYesRequired) {
      setAlertMessage(
        errMsg +
          `You are required to answer "yes" to the question: ${question.title}`,
        true,
      )
      isReturning = true
    }
  })
  if (isReturning) {
    return false
  }
  return true
}

export const validateTeam = ({
  team,
  comp,
  setErrorMessage,
  category,
  maxProblemGrade,
  maxRouteGrade,
  competitorAnswers,
}) => {
  const errMsg = "You have errors above. "
  if (!team.name) {
    setErrorMessage(errMsg + "You have to name your team. ")
    return false
  }
  if (comp.categories?.length && comp.isTeamCategory && !team.category) {
    setErrorMessage(errMsg + "Please select a team category. ")
    return false
  }
  const numUsers = team.userIds.length + team.emails.length
  if (
    team.isAllowingFloaters ? numUsers < 2 : comp.minNumberOnTeam > numUsers
  ) {
    setErrorMessage(
      errMsg + comp.isAllowingFloaters && !team.isAllowingFloaters
        ? "Please add more climbers to you team or allow individuals to join."
        : "Please add more climbers to your team. ",
    )
    return false
  }
  if (comp.maxNumberOnTeam < numUsers) {
    setErrorMessage(errMsg + "Please remove some climbers from your team. ")
    return false
  }
  if (comp.timeSlots?.length && !team.timeSlotId) {
    setErrorMessage(errMsg + "Please select a session time to climb during. ")
    return false
  }
  if (
    !validateCompetitor({
      comp,
      competitor: {
        maxProblemGrade,
        maxRouteGrade,
        category,
        competitorAnswers,
        timeSlotId: team.timeSlotId,
      },
      setAlertMessage: setErrorMessage,
    })
  ) {
    return false
  }
  return true
}

export const attemptsPenalty = (compSet, numAttempts) => {
  if (!!compSet?.isGradingAttempts) {
    return _.max([0, (numAttempts - 1) * compSet?.attemptPoints])
  }

  return 0
}

export const holdCompletionQuotient = ({
  climb,
  isSend,
  compSet,
  highpoint,
  isThrowing,
}) => {
  if (!compSet?.isGradingHolds || (!!isSend && !highpoint)) {
    return 1
  }
  if (isThrowing) {
    // if (!highpoint) {
    //   throw new Error(
    //     "PebbleError: If you didn't send the climb. You must enter a highpoint.",
    //   )
    // }
    if (highpoint > climb.numHolds) {
      throw new Error(
        `PebbleError: Your highpoint must be less than or equal to ${climb.numHolds}`,
      )
    }
  }
  return !highpoint ? 0 : highpoint / climb.numHolds
}

export const getGymGrades = ({ gym, climbType }) => {
  return getGradeCollectionFromGym({ gym, climbType })
}

export const bumpClimbGrade = ({
  scores,
  gradeCollection,
  competitor,
  climb,
  diff,
  numClimbsBeforeBump,
}) => {
  // bump climber grade to lowest high score and recalculate all scores for this round
  // get diff to add to new max climb grade
  const climbDiffs = scores
    .map((sc) => sc.relativeDelta)
    .sort((a, b) => b - a)
    .slice(0, numClimbsBeforeBump)
  const climbMinDiff = Math.min(...climbDiffs, diff)

  // update competitor to new score
  const curGradeInd = gradeCollection.findIndex((obj) => {
    return (
      obj.value >=
      competitor[climb.type === "route" ? `maxRouteGrade` : `maxProblemGrade`]
    )
  })
  const newMaxClimbGrade = gradeCollection[curGradeInd + climbMinDiff]
  return {
    diff: diff - climbMinDiff,
    newMaxClimbGrade,
  }
}

export const checkClimberGradeBump = ({
  compSet,
  competitor,
  climb,
  diff,
  // scores that are over max
  scores,
  gradeCollection,
}) => {
  const { numClimbsBeforeBump, _id } = compSet
  // + 1 is to include current score that hasn't been saved yet
  if (scores.length + 1 >= numClimbsBeforeBump && numClimbsBeforeBump) {
    const { diff: newDiff } = bumpClimbGrade({
      scores,
      gradeCollection,
      competitor,
      climb,
      diff,
    })

    // let know will bump competitor
    return { diff: newDiff, isBumping: true }
  }
  return { diff, isBumping: false }
}

// TODO: merge this with datasource/CompScores.js calcScore so they are the same
export const calcScore = ({
  climb,
  compSet,
  highpoint,
  isSend,
  numAttempts,
  competitor,
  isSkippingBump,
  allScores,
  scoreBonuses,
  isTopControlled,
  controlledZone,
  attemptsToTop,
  attemptsToZones,
  isZoneControlled,
  attemptsToZone,
  isPlusGiven,
}) => {
  if (compSet.isWorldCupBoulderingPoints) {
    return {
      score: getMultiZoneWCBoulderingScorePoints({
        isTopControlled,
        controlledZone,
        attemptsToTop,
        attemptsToZones,
        compSet,
      }),
    }
  }
  if (compSet.isWorldCupBouldering) {
    return {
      score: getWCBoulderingScore({
        isTopControlled,
        isZoneControlled,
        attemptsToTop,
        attemptsToZone,
      }),
    }
  }
  if (compSet.isWorldCupSport) {
    return {
      score: getWCSportScore({
        highpoint,
        isPlusGiven,
        numHolds: climb.numHolds,
      }),
    }
  }
  const ap = attemptsPenalty(compSet, numAttempts)
  const hcq = holdCompletionQuotient({ climb, isSend, compSet, highpoint })
  let maxClimbScore =
    climb.maxCompScores.find((cS) => cS.compSet === compSet._id)
      ?.maxCompScore ?? 0
  let diff = 0
  // get relative score based on user max grade before calculating rest
  // TODO: this is only goign to work for outdoor grades, need to adjust for indoor
  if (compSet.isRelativeScoring) {
    const gradeCollection = getGymGrades({
      gym: climb.gym,
      climbType: climb.type,
    })
    // difference between index of max project grade and index of grade of climb in gym
    diff = getRelativeGradeDelta({
      gradeCollection,
      competitor,
      climb,
    })

    // if climber is climbing better than their max grade,
    // then check for a bump in their max grade score and return new diff if changes
    if (diff > 0 && !isSkippingBump) {
      const scores = _.uniqBy(
        allScores.filter((sc) => sc.relativeDelta > 0 && !!sc[climb.type]),
        (score) => score[climb.type],
      )
      diff = checkClimberGradeBump({
        compSet,
        competitor,
        climb,
        diff,
        scores,
        gradeCollection,
      })?.diff
    }
    // get difference between two indexes and then divide climb score by
    const ptsPerGradeDiff = compSet.isRelativeScoring
      ? compSet.pointsPerGradeDiff
      : 0
    // get score for climb (scoreDiff is diff for relative scoring unless scores
    // aren't allowed to be bigger than relative score)
    const scoreDiff = compSet.isRelativeScoreMaxScore ? Math.min(diff, 0) : diff
    maxClimbScore = compSet.relativeScore + ptsPerGradeDiff * scoreDiff
  }
  const bonusTotal = (scoreBonuses || [])
    .map((b) => b.points)
    .reduce((acc, cur) => acc + cur, 0)
  return {
    score: _.max([0, maxClimbScore * hcq - ap + bonusTotal]),
    relativeDelta: diff,
  }
}

export const getLeadersWithPluses = ({ leaders, key }) => {
  return leaders.map((leader) => {
    if (
      leaders.find(
        (lead) =>
          lead[key]?._id &&
          lead[key]?._id !== leader[key]?._id &&
          lead.score === leader.score &&
          lead.scorePlusOne === leader.scorePlusOne &&
          lead.scorePlusTwo === leader.scorePlusTwo,
      )
    ) {
      return {
        ...leader,
        plus: leader.scorePlusThree - leader.score,
        numPluses: 6,
      }
    }
    if (
      leaders.find(
        (lead) =>
          lead[key]?._id &&
          lead[key]?._id !== leader[key]?._id &&
          lead.score === leader.score &&
          lead.scorePlusOne === leader.scorePlusOne,
      )
    ) {
      return {
        ...leader,
        plus: leader.scorePlusTwo - leader.score,
        numPluses: 5,
      }
    }
    if (
      leaders.find(
        (lead) =>
          lead[key]?._id &&
          lead[key]?._id !== leader[key]?._id &&
          lead.score === leader.score,
      )
    ) {
      return {
        ...leader,
        plus: leader.scorePlusOne - leader.score,
        numPluses: 4,
      }
    }
    return leader
  })
}

export const getMultiZoneWCBoulderingScorePoints = ({
  isTopControlled,
  controlledZone,
  attemptsToTop,
  attemptsToZones,
  compSet,
}) => {
  if (controlledZone === 0) {
    return 0
  }
  const { zoneHoldPoints, topPoints, attemptPoints } = compSet
  if (isTopControlled) {
    return topPoints - (Math.max(1, attemptsToTop ?? 0) - 1) * attemptPoints
  }
  const attempts = Math.max(1, attemptsToZones?.[controlledZone - 1] ?? 1)

  return zoneHoldPoints[controlledZone - 1] - (attempts - 1) * attemptPoints
}

// converts world cup bouldering score to points value
export const getTraditionalWCBoulderingScorePoints = ({
  isTopControlled,
  isZoneControlled,
  attemptsToTop,
  attemptsToZone,
}) => {
  let score = 0
  if (isTopControlled) {
    score += 100000000
  }
  if (isZoneControlled) {
    score += 1000000
  }
  if (isTopControlled) {
    score -= attemptsToTop * 1000
  }
  if (isZoneControlled) {
    score -= attemptsToZone
  }
  return score
}

export const getWCSportScore = ({ highpoint, isPlusGiven, numHolds }) => {
  return numHolds && highpoint < numHolds
    ? highpoint + (isPlusGiven ? 0.5 : 0)
    : numHolds
}

export const getWCBoulderingScore = ({
  isTopControlled,
  isZoneControlled,
  attemptsToTop,
  attemptsToZone,
}) => {
  let scoreStr = ""
  if (isTopControlled) {
    scoreStr += `t${attemptsToTop} `
  }
  if (isZoneControlled) {
    scoreStr += `z${attemptsToZone}`
  }
  if (!isTopControlled && !isZoneControlled) {
    scoreStr = "z0"
  }
  return scoreStr
}

export const getWCBoulderingScoreFromPoints = ({ pts, isTotal }) => {
  if (isTotal) {
    const numTops = Math.floor(pts / 100000000) ?? 0
    const leftover = pts - numTops * 100000000
    const numZones = Math.ceil(leftover / 1000000)
    const attemptPts = numZones * 1000000 - leftover
    const topAttempts = Math.floor(attemptPts / 1000)
    const zoneAttempts = attemptPts % 1000
    return {
      numTops,
      numZones,
      topAttempts,
      zoneAttempts,
    }
  } else {
    const isTop = pts > 100000000
    const isZone = pts > 0
    const tryPts = 101000000 - pts
    const topTries = Math.floor(tryPts / 1000)
    const zoneTries = tryPts - topTries * 1000
    if (isTop) {
      return `t${topTries} z${zoneTries}`
    }
    if (isZone) {
      return `z${zoneTries}`
    }
    return "z0"
  }
}

// download to excel functions

export function downloadCompetitorList(comp, isReturningJSON) {
  const { competitorQuestions } = comp
  const isShowingMaxProblemGrade =
    comp.isRelativeScoringEnabled && comp.isScoringProblems
  const isShowingMaxRouteGrade =
    comp.isRelativeScoringEnabled && comp.isScoringRoutes
  const data = comp.competitors.map((competitor) => {
    const problemGrade = isShowingMaxProblemGrade
      ? getGradeObject(
          competitor.maxProblemGrade,
          "problem",
          null,
          comp.gym || comp.gyms[0],
        )
      : null
    const routeGrade = isShowingMaxRouteGrade
      ? getGradeObject(
          competitor.maxRouteGrade,
          "route",
          null,
          comp.gym || comp.gyms[0],
        )
      : null
    return {
      // in order to reference competitor if adding scores
      ...(isReturningJSON
        ? { _id: competitor.user._id }
        : { "User ID": competitor.user._id }),
      ...(isReturningJSON ? { competitorId: competitor._id } : {}),
      Name: competitor.name?.replaceAll(",", ""),
      FirstName: competitor.name?.split(" ")[0]?.replaceAll(",", ""),
      LastName: competitor.name?.split(" ")[1]?.replaceAll(",", ""),
      "Checked In": competitor.isCheckedIn ? "Yes" : "No",
      ...(comp.categories?.length
        ? {
            Category:
              competitor.category?.replaceAll(",", "") ||
              competitor.team?.category?.replaceAll(",", ""),
          }
        : {}),
      ...(competitor.discountedPrice
        ? {
            Discount: competitor.discountedPrice?.name?.replaceAll(",", ""),
          }
        : {}),
      ...(comp.isTeamComp
        ? { Team: competitor?.team?.name?.replaceAll(",", "") }
        : {}),
      ...(comp.isTeamComp ? { "Team Slug": competitor.team?.slug } : {}),
      ...(!!comp.timeSlots?.length
        ? { Session: competitor.timeSlot?.name?.replaceAll(",", "") }
        : {}),
      ...(problemGrade?.label
        ? { "Boulder Grade": problemGrade?.label?.replaceAll(",", "") }
        : {}),
      ...(routeGrade?.label
        ? { "Route Grade": routeGrade?.label?.replaceAll(",", "") }
        : {}),
      "Competitor Code": `${competitor.slug}-${competitor._id.slice(-1)}`,
      ...(competitor.competitorAnswers || []).reduce((acc, ans) => {
        const question = competitorQuestions.find(
          (ques) => ques._id === ans.questionId,
        )
        return {
          ...acc,
          [`"${question?.title || ""}"`]: `"${ans.answer}"`,
        }
      }, {}),
    }
  })

  if (isReturningJSON) {
    return data
  }

  const fileName = `${comp.name} Competitor List`
  const exportType = "csv"
  exportFromJSON({
    data,
    fileName,
    exportType,
  })
}

export function downloadCompetitorListWithScores(comp) {
  const cptrInfo = downloadCompetitorList(comp, true)
  const setOrder = _.sortBy(comp.compSets, (cS) => cS.start).map((cS) => cS._id)
  // augment data with scores
  const data = cptrInfo.map((cptr) => {
    const competitor = {
      ...cptr,
      compScores: comp.competitors.find((c) => c._id === cptr.competitorId)
        ?.compScores,
      user: comp.competitors.find((c) => c._id === cptr.competitorId)?.user,
      competitorBonusActivities:
        comp.competitors.find((c) => c._id === cptr.competitorId)
          ?.competitorBonusActivities || [],
    }
    const topNScores = getTopNScores({
      comp,
      competitors: [competitor],
      isTakingAll: true,
    })
    return {
      ..._.omit(competitor, [
        "_id",
        "competitorId",
        "compScores",
        "user",
        "competitorBonusActivities",
      ]),
      ...Object.keys(topNScores).reduce((acc, setSlug) => {
        const set = comp.compSets.find((set) => set.slug === setSlug)
        const setName = set?.name
        const setIndex = setOrder.indexOf(set._id) + 1
        const scores = topNScores[setSlug]
        const bonusActivities = competitor.competitorBonusActivities
          .filter((bA) => bA.compSet.slug === setSlug)
          .reduce(
            (acc, bA) => ({
              ...acc,
              [`(${setIndex}Bonus) ${setName}: ${bA.activity.name}`]:
                bA.activity.points,
            }),
            {},
          )
        return {
          ...acc,
          ...scores.reduce((acc, score) => {
            const climb = score.problem || score.route
            const activity = score.activity
            return {
              ...acc,
              [`(${setIndex}) ${setName}: ${
                climb?.name ?? activity?.name ?? ""
              } ${climb ? `(${climb?.slug})` : ""}`]: set.isWorldCupBouldering
                ? getWCBoulderingScoreFromPoints({ pts: score.score })
                : set.isWorldCupBoulderingPoints
                ? getWCBoulderingScoreFromPoints({
                    pts: score.score,
                    compSet: score.compSet,
                  })
                : score.score,
            }
          }, {}),
          ...bonusActivities,
        }
      }, {}),
    }
  })
  const fileName = `${comp.name} Competitor Scores`
  const exportType = "csv"
  exportFromJSON({
    data,
    fileName,
    exportType,
    beforeTableEncode: (rows) =>
      rows.sort((p, c) =>
        p.fieldName.startsWith("(")
          ? c.fieldName.startsWith("(")
            ? p.fieldName.localeCompare(c.fieldName)
            : 1
          : 0,
      ),
  })
}

function getGradeGroups(climbs, gym) {
  const gradeScaleNames = getGradeCollectionFromGym({
    gym,
    climbType: climbs[0]?.type,
  })?.map((g) => g.name)
  const gradeGroups = climbs.reduce((acc, climb) => {
    const grade = getGradeObject(
      climb.grade,
      climb.type,
      climb,
      gym,
      climb.gradeColor,
      climb.gradeName,
    )
    const gradeGroup = acc.find((g) => g.label === grade.name)
    if (gradeGroup) {
      gradeGroup.climbs.push(climb)
    } else {
      acc.push({
        label: grade?.name,
        climbs: [climb],
      })
    }
    return acc
  }, [])
  return _.sortBy(gradeGroups, (g) => gradeScaleNames.indexOf(g.label))
}

function getScoreGroups(scores, gym) {
  const gradeScaleNames = getGradeCollectionFromGym({
    gym,
    climbType: scores[0]?.problem ? "problem" : "route",
  })?.map((g) => g.name)
  const scoreGroups = scores.reduce((acc, score) => {
    const climb = score.problem || score.route
    const grade = getGradeObject(climb.grade, climb.type, "us", gym)
    const scoreGroup = acc.find((g) => g.label === grade.name)
    if (scoreGroup) {
      scoreGroup.scores.push(score)
    } else {
      acc.push({
        label: grade?.name,
        scores: [score],
      })
    }
    return acc
  }, [])
  return _.sortBy(scoreGroups, (g) => gradeScaleNames.indexOf(g.label))
}

export function downloadCompReport(comp, isServer = false) {
  const hasProblems = comp.problems?.length > 0
  const hasRoutes = comp.routes?.length > 0
  const hasScores = comp.compScores?.length > 0
  const hasCategories = comp.categories?.length > 0

  const problemGradeGroups = hasProblems
    ? getGradeGroups(comp.problems, comp.gyms[0])
    : []
  const routeGradeGroups = hasRoutes
    ? getGradeGroups(comp.routes, comp.gyms[0])
    : []
  const scoreGradeGroups = hasScores
    ? getScoreGroups(comp.compScores, comp.gyms[0])
    : []

  let hasMultipleGyms = false
  let gymScoreCounts = []
  if (comp.gyms.length > 1) {
    hasMultipleGyms = true
    // count how many climbs were sent in each gym
    gymScoreCounts = comp.compScores.reduce((acc, score) => {
      const climbId = score.problem?._id || score.route?._id
      const competitor = comp.competitors.find(
        (c) => c?._id === score.competitor?._id,
      )
      const climb =
        comp.problems.find((p) => p._id === climbId) ||
        comp.routes.find((r) => r._id === climbId)
      const gym = comp.gyms.find((g) => g._id === climb.gym._id)
      const gymIndex = acc.findIndex((g) => g.gym._id === gym._id)
      if (gymIndex === -1) {
        acc.push({
          gym,
          count: 1,
          competitorIds: [competitor._id],
        })
      } else {
        acc[gymIndex].count++
        if (
          competitor?._id &&
          !acc[gymIndex].competitorIds.includes(competitor?._id)
        ) {
          acc[gymIndex].competitorIds.push(competitor?._id)
        }
      }

      return acc
    }, [])
  }

  // create array with key value objects for excel
  const data = [
    {
      Competition: "Dates",
      [comp.name]: `${dayjs(comp.startDate).format("ll")} - ${dayjs(
        comp.endDate,
      ).format("ll")}`,
    },
    {
      Competition: "",
      [comp.name]: "",
    },
    {
      Competition: "Competitors",
      [comp.name]: "",
    },
    ...(hasCategories
      ? [
          {
            Competition: "Category",
            [comp.name]: "# of competitors",
          },
          ...(comp.categories || []).map((cat) => ({
            Competition: cat,
            [comp.name]: comp.competitors.filter((c) => c.category === cat)
              .length,
          })),
        ]
      : []),
    {
      Competition: "Total",
      [comp.name]: comp.competitors.length,
    },
    ...(comp.isTeamComp
      ? [
          {
            Competition: "",
            [comp.name]: "",
          },
          {
            Competition: "Teams",
            [comp.name]: "",
          },
          {
            Competition: "Total",
            [comp.name]: comp.teams.length,
          },
          {
            Competition: "Avg. # of competitors per team",
            [comp.name]: (comp.competitors.length / comp.teams.length).toFixed(
              2,
            ),
          },
        ]
      : []),
    ...(hasScores
      ? [
          {
            Competition: "",
            [comp.name]: "",
          },
          {
            Competition: "Scores",
            [comp.name]: "",
          },
          {
            Competition: "Grade",
            [comp.name]: "# of scores",
          },
          ...(scoreGradeGroups || []).map((gradeGroup) => ({
            Competition: gradeGroup.label,
            [comp.name]: gradeGroup.scores.length,
          })),
          {
            Competition: "Total",
            [comp.name]: comp.compScores.length,
          },
        ]
      : []),
    ...(hasProblems
      ? [
          {
            Competition: "",
            [comp.name]: "",
          },
          {
            Competition: "Problems",
            [comp.name]: "# of problems",
          },
          ...(problemGradeGroups || []).map((gradeGroup) => ({
            Competition: gradeGroup.label,
            [comp.name]: gradeGroup.climbs.length,
          })),
          {
            Competition: "Total",
            [comp.name]: comp.problems.length,
          },
        ]
      : []),
    ...(hasRoutes
      ? [
          {
            Competition: "",
            [comp.name]: "",
          },
          {
            Competition: "Routes",
            [comp.name]: "# of routes",
          },
          ...(routeGradeGroups || []).map((gradeGroup) => ({
            Competition: gradeGroup.label,
            [comp.name]: gradeGroup.climbs.length,
          })),
          {
            Competition: "Total",
            [comp.name]: comp.routes.length,
          },
        ]
      : []),
    {
      Competition: "",
      [comp.name]: "",
    },
    ...(hasMultipleGyms
      ? [
          {
            Competition: "Gym",
            [comp.name]: "# of scores",
          },
          ...(gymScoreCounts || []).map((gym) => ({
            Competition: gym.gym.name,
            [comp.name]: gym.count,
          })),
          {
            Competition: "Total",
            [comp.name]: comp.compScores.length,
          },
        ]
      : []),
    {
      Competition: "",
      [comp.name]: "",
    },
    ...(hasMultipleGyms
      ? [
          {
            Competition: "Gym",
            [comp.name]: "# of competitors logging at least one score",
          },
          ...(gymScoreCounts || []).map((gym) => ({
            Competition: gym.gym.name,
            [comp.name]: gym.competitorIds.length,
          })),
          {
            Competition: "Total",
            [comp.name]: comp.competitors.length,
          },
        ]
      : []),
    {
      Competition: "",
      [comp.name]: "",
    },
    ...(comp.financials
      ? [
          {
            Competition: "Financials",
            [comp.name]: "",
          },
          {
            Competition: "Net profit",
            [comp.name]: ((comp.financials?.net || 0) / 100).toFixed(2),
          },
          {
            Competition: "Paying competitors",
            [comp.name]: comp.financials?.payingCompetitors || 0,
          },
        ]
      : []),
  ]
  const fileName = `${comp.name} Final Report`
  const exportType = "csv"
  return exportFromJSON({
    data,
    fileName,
    exportType,
    ...(isServer
      ? {
          processor: (content) => {
            return content
          },
        }
      : {}),
  })
}

export function doCompSetClimbsHavePoints(compSet) {
  return compSet.gymAreas?.some((area) => {
    const climbs = [...(area.problems ?? []), ...(area.routes ?? [])]
    return climbs?.some((climb) => {
      return climb.maxCompScores.find((cS) => cS.compSet === compSet._id)
        ?.maxCompScore
    })
  })
}
