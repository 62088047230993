// based on chart on wikipedia: https://en.wikipedia.org/wiki/Grade_(climbing)

export const frenchBoulderingGrades = [
  {
    label: "3",
    value: 1,
  },
  {
    label: "4",
    value: 2,
  },
  {
    label: "4+",
    value: 3,
  },
  {
    label: "5",
    value: 4,
  },
  {
    label: "5+",
    value: 5,
  },
  {
    label: "6a",
    value: 6,
  },
  {
    label: "6a+",
    value: 7,
  },
  {
    label: "6b",
    value: 8,
  },
  {
    label: "6b+",
    value: 9,
  },
  {
    label: "6c",
    value: 10,
  },
  {
    label: "6c+",
    value: 11,
  },
  {
    label: "7a",
    value: 12,
  },
  {
    label: "7a+",
    value: 13,
  },
  {
    label: "7b",
    value: 14,
  },
  {
    label: "7b+",
    value: 15,
  },
  {
    label: "7c",
    value: 16,
  },
  {
    label: "7c+",
    value: 17,
  },
  {
    label: "8a",
    value: 18,
  },
  {
    label: "8a+",
    value: 19,
  },
  {
    label: "8b",
    value: 20,
  },
  {
    label: "8b+",
    value: 21,
  },
  {
    label: "8c",
    value: 22,
  },
  {
    label: "8c+",
    value: 23,
  },
  {
    label: "9a",
    value: 24,
  },
  {
    label: "9a+",
    value: 25,
  },
  {
    label: "Proj",
    value: 100,
  },
]

export const usBoulderingGrades = [
  {
    label: "V0",
    value: 3,
  },
  {
    label: "V1",
    value: 4,
  },
  {
    label: "V2",
    value: 5,
  },
  {
    label: "V3",
    value: 6,
  },
  {
    label: "V4",
    value: 8,
  },
  {
    label: "V5",
    value: 10,
  },
  {
    label: "V6",
    value: 12,
  },
  {
    label: "V7",
    value: 13,
  },
  {
    label: "V8",
    value: 14,
  },
  {
    label: "V9",
    value: 16,
  },
  {
    label: "V10",
    value: 17,
  },
  {
    label: "V11",
    value: 18,
  },
  {
    label: "V12",
    value: 19,
  },
  {
    label: "V13",
    value: 20,
  },
  {
    label: "V14",
    value: 21,
  },
  {
    label: "V15",
    value: 22,
  },
  {
    label: "V16",
    value: 23,
  },
  {
    label: "V17",
    value: 24,
  },
  {
    label: "V18",
    value: 25,
  },
  {
    label: "Proj",
    value: 100,
  },
]

export const frenchRouteGrades = [
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
  {
    label: "4a",
    value: 4,
  },
  {
    label: "4b",
    value: 5,
  },
  {
    label: "4c",
    value: 6,
  },
  {
    label: "5a",
    value: 7,
  },
  {
    label: "5b",
    value: 8,
  },
  {
    label: "5c",
    value: 9,
  },
  {
    label: "6a",
    value: 10,
  },
  {
    label: "6a+",
    value: 11,
  },
  {
    label: "6b",
    value: 12,
  },
  {
    label: "6b+",
    value: 13,
  },
  {
    label: "6c",
    value: 15,
  },
  {
    label: "6c+",
    value: 16,
  },
  {
    label: "7a",
    value: 17,
  },
  {
    label: "7a+",
    value: 18,
  },
  {
    label: "7b",
    value: 19,
  },
  {
    label: "7b+",
    value: 20,
  },
  {
    label: "7c",
    value: 21,
  },
  {
    label: "7c+",
    value: 22,
  },
  {
    label: "8a",
    value: 23,
  },
  {
    label: "8a+",
    value: 24,
  },
  {
    label: "8b",
    value: 25,
  },
  {
    label: "8b+",
    value: 26,
  },
  {
    label: "8c",
    value: 27,
  },
  {
    label: "8c+",
    value: 28,
  },
  {
    label: "9a",
    value: 29,
  },
  {
    label: "9a+",
    value: 30,
  },
  {
    label: "9b",
    value: 31,
  },
  {
    label: "9b+",
    value: 32,
  },
  {
    label: "9c",
    value: 33,
  },
  {
    label: "9c+",
    value: 34,
  },
  {
    label: "Proj",
    value: 100,
  },
]

export const usRouteGrades = [
  {
    label: "5.1",
    value: 1,
  },
  {
    label: "5.2",
    value: 2,
  },
  {
    label: "5.3",
    value: 3,
  },
  {
    label: "5.4",
    value: 4,
  },
  {
    label: "5.5",
    value: 5,
  },
  {
    label: "5.6",
    value: 6,
  },
  {
    label: "5.7",
    value: 7,
  },
  {
    label: "5.8",
    value: 8,
  },
  {
    label: "5.9",
    value: 9,
  },
  {
    label: "5.10a",
    value: 10,
  },
  {
    label: "5.10b",
    value: 11,
  },
  {
    label: "5.10c",
    value: 12,
  },
  {
    label: "5.10d",
    value: 13,
  },
  {
    label: "5.11a",
    value: 14,
  },
  {
    label: "5.11b",
    value: 15,
  },
  {
    label: "5.11c",
    value: 16,
  },
  {
    label: "5.11d",
    value: 17,
  },
  {
    label: "5.12a",
    value: 18,
  },
  {
    label: "5.12b",
    value: 19,
  },
  {
    label: "5.12c",
    value: 20,
  },
  {
    label: "5.12d",
    value: 21,
  },
  {
    label: "5.13a",
    value: 22,
  },
  {
    label: "5.13b",
    value: 23,
  },
  {
    label: "5.13c",
    value: 24,
  },
  {
    label: "5.13d",
    value: 25,
  },
  {
    label: "5.14a",
    value: 26,
  },
  {
    label: "5.14b",
    value: 27,
  },
  {
    label: "5.14c",
    value: 28,
  },
  {
    label: "5.14d",
    value: 29,
  },
  {
    label: "5.15a",
    value: 30,
  },
  {
    label: "5.15b",
    value: 31,
  },
  {
    label: "5.15c",
    value: 32,
  },
  {
    label: "5.15d",
    value: 33,
  },
  {
    label: "5.16a",
    value: 34,
  },
  {
    label: "Proj",
    value: 100,
  },
]

export default {
  frenchBoulderingGrades,
  frenchRouteGrades,
  usBoulderingGrades,
  usRouteGrades,
}
