// route setter management
// TODO: once convert app to using hooks and correct structure for context, can
// merge this with the mobile version
import React, { createContext } from "react"
import useRouteSetters from "../hooks/useRouteSetters"
import { useAuth } from "../../common/contexts/AuthContext"
const RouteSetterContext = createContext({})
const RouteSetterProvider = ({ children }) => {
  const { loggedInUser } = useAuth()
  const { setCurrentGymSlug, isSetter, isHeadSetter, setters, settingColors } =
    useRouteSetters({ loggedInUser })
  return (
    <RouteSetterContext.Provider
      value={{
        setCurrentGymSlug,
        isSetter,
        isHeadSetter,
        setters,
        settingColors,
      }}
    >
      {children}
    </RouteSetterContext.Provider>
  )
}
export { RouteSetterContext, RouteSetterProvider }
