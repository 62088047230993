import gql from "graphql-tag"
import { basicUserFragment } from "../../users/graphql/basicUserFragment"

export const feedCommentFragment = gql`
  fragment feedCommentFragment on Comment {
    _id
    comment
    user {
      ...basicUserFragment
    }
    date
  }
  ${basicUserFragment}
`

export default { feedCommentFragment }
