import React from "react"
import { getRelativeScore } from "../../utilities/scores"
import ClimbGrade from "../../common/components/ClimbGrade"
import Card from "../../common/components/Card"

import styles from "./css/ClimbScoreCard.module.css"
import { Box, Flex, Heading } from "@chakra-ui/react"

const ClimbScoreCard = ({ climb, compScore, compSet }) => {
  if (!compSet) {
    return null
  }
  const scoreBonus = compSet.scoreBonuses
    ? compSet.scoreBonuses.map((sb) => sb.points).reduce((acc, b) => acc + b, 0)
    : 0
  const climbScore = compSet?.isRelativeScoring
    ? getRelativeScore({
        compSet,
        delta: compScore.relativeDelta,
      })
    : compSet.isWorldCupSport
    ? climb.numHolds + scoreBonus
    : climb.maxCompScores?.find((sc) => sc.compSet === compSet._id)
        ?.maxCompScore
  const maxClimbScore = compSet.isWorldCupBouldering
    ? undefined
    : compSet.isWorldCupBoulderingPoints
    ? compSet.topPoints + scoreBonus
    : climbScore + scoreBonus

  return (
    <>
      <Card position="relative" overflow="hidden">
        <div
          className={styles["row-line"]}
          style={{ backgroundColor: climb.color }}
        />
        <Flex ml={"10px"}>
          <Box flex={1}>
            <Heading mb={0} size="md" as="h4">
              {climb.name}
            </Heading>
          </Box>
          <ClimbGrade climb={climb} gym={climb.gym} />
        </Flex>
      </Card>
      {!compSet.isWorldCupBouldering && (
        <h6>
          Possible score:{" "}
          <span className="orange-text">{maxClimbScore}pts</span>
        </h6>
      )}
    </>
  )
}

export default ClimbScoreCard
