import _ from "lodash"

export function getFakeSlug(str, separator = "-") {
  str = str.trim()
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  const from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;"
  const to = "aaaaaaeeeeiiiioooouuuunc------"

  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i))
  }

  return (
    str
      .replace(/[^a-z0-9   -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-") // collapse dashes
      .replace(/^-+/, "") // trim - from start of text
      .replace(/-+$/, "") // trim - from end of text
      .replace(/-/g, separator) + String(Math.round(Date.now() / 1000))
  )
}

export function getObjectId(
  m = Math,
  d = Date,
  h = 16,
  s = (s) => m.floor(s).toString(h),
) {
  return (
    s(d.now() / 1000) + " ".repeat(h).replace(/./g, () => s(m.random() * h))
  )
}

function getPointsFromGrade(grade, type) {
  return type === "route" ? Math.round(grade * 10) : Math.round(grade * 13.6)
}

export function addMissingProblemParameters(problem, userId) {
  return {
    __typename: "Problem",
    type: "problem",
    slug: "",
    picture: "",
    description: "",
    quality: 0,
    points: getPointsFromGrade(problem.grade, "problem"),
    isNameEditable: false,
    isArchived: false,
    isIndoor: false,
    archivedAt: null,
    setterGrade: null,
    totalAscents: 0,
    createDate: Date.now(),
    numUserSends: 0,
    numUserAttempts: 0,
    publishedAt: null,
    grades: [],
    qualities: [],
    color: null,
    kneebar: null,
    coordination: null,
    sun: null,
    landing: null,
    technical: false,
    undercling: false,
    isDraft: null,
    setter: null,
    guide: null,
    area: null,
    popMedia: null,
    version: 1,
    firstAscent: null,
    gradeColor: null,
    hasPrintedLabel: false,
    editors: [
      {
        __typename: "Editor",
        user: {
          __typename: "User",
          _id: userId,
        },
        version: 1,
        date: Date.now(),
      },
    ],
    sends: [],
    media: [],
    startingHoldPicture: null,
    overviewPicture: null,
    competitionPicture: null,
    maxCompScores: null,
    numHolds: null,
    setterPictureLocation: null,
    holdLocations: [],
    beta: "",
    betaVideo: null,
    oembed3d: null,
    isSponsored: false,
    comps: [],
    compSets: [],
    userCompScores: [],
    judgedSets: [],
    ...problem,
    gym: problem.gym ? addMissingGymParameters(problem.gym) : null,
    gymArea: problem.gymArea
      ? addMissingGymAreaParameters(problem.gymArea)
      : null,
    comp: problem.comp ? addMissingCompParameters(problem.comp) : null,
    compSet: problem.compSet
      ? addMissingCompSetParameters(problem.compSet)
      : null,
    userCompScore: null,
    location: {
      __typename: "PointInput",
      coordinates: problem?.location?.coordinates || [],
    },
    arBetas: problem.arBetas
      ? problem.arBetas.map((beta) => {
          return {
            __typename: "ARBeta",
            ...beta,
          }
        })
      : [],
  }
}

export function addMissingCompParameters(comp) {
  return {
    ...comp,
  }
}

export function addMissingCompSetParameters(compSet) {
  return {
    ...compSet,
  }
}

export function addMissingRouteParameters(route, userId) {
  return {
    __typename: "Route",
    type: "route",
    slug: "",
    picture: "",
    description: "",
    quality: 0,
    points: getPointsFromGrade(route.grade, "route"),
    isNameEditable: false,
    isArchived: false,
    isIndoor: false,
    archivedAt: null,
    setterGrade: null,
    totalAscents: 0,
    createDate: Date.now(),
    numUserSends: 0,
    numUserAttempts: 0,
    publishedAt: null,
    grades: [],
    qualities: [],
    color: null,
    isDraft: null,
    setter: null,
    sun: null,
    technical: false,
    heelhooks: false,
    undercling: false,
    guide: null,
    area: null,
    popMedia: null,
    version: 1,
    firstAscent: null,
    gradeColor: null,
    hasPrintedLabel: false,
    editors: [
      {
        __typename: "Editor",
        user: {
          __typename: "User",
          _id: userId,
        },
        version: 1,
        date: Date.now(),
      },
    ],
    sends: [],
    media: [],
    startingHoldPicture: null,
    overviewPicture: null,
    competitionPicture: null,
    maxCompScores: null,
    numHolds: null,
    setterPictureLocation: null,
    holdLocations: [],
    popularity: 0,
    betaVideo: null,
    oembed3d: null,
    isSponsored: false,
    comps: [],
    compSets: [],
    userCompScores: [],
    judgedSets: [],
    ...route,
    gym: route.gym ? addMissingGymParameters(route.gym) : null,
    gymArea: route.gymArea ? addMissingGymAreaParameters(route.gymArea) : null,
    comp: route.comp ? addMissingCompParameters(route.comp) : null,
    compSet: route.compSet ? addMissingCompSetParameters(route.compSet) : null,
    userCompScore: null,
    location: {
      __typename: "PointInput",
      coordinates: route?.location?.coordinates || [],
    },
    pitches: route.pitches
      ? route.pitches.map((pitch) => ({
          __typename: "Pitch",
          ...pitch,
        }))
      : [],
    arBetas: route.arBetas
      ? route.arBetas.map((beta) => {
          return {
            __typename: "ARBeta",
            ...beta,
          }
        })
      : [],
  }
}

export function addMissingAreaParameters(area, childFeature = {}, userId) {
  const routes = area.routes || []
  const problems = area.problems || []
  const areas = area.areas || []
  const routeIds = area.routeIds || []
  const problemIds = area.problemIds || []
  const areaIds = area.areaIds || []
  const numProblems = area.numProblems || 0
  const numRoutes = area.numRoutes || 0
  const numAreas = area.numAreas || 0
  return {
    __typename: "Area",
    type: "area",
    hasMedia: false,
    popMedia: null,
    editors: [
      {
        __typename: "Editor",
        user: {
          __typename: "User",
          _id: userId,
        },
        version: 1,
        date: Date.now(),
      },
    ],
    isNameEditable: false,
    version: 1,
    name: "",
    slug: "",
    media: [],
    sends: [],
    numWorkouts: 0,
    access: "",
    directions: "",
    description: "",
    ...area,
    guide: area.guide || null,
    parentArea: area.parentArea
      ? addMissingAreaParameters(area.parentArea, area, userId)
      : null,
    routes:
      childFeature?.type === "route"
        ? _.uniqBy([...routes, childFeature], "_id")
        : routes,
    problems:
      childFeature?.type === "problem"
        ? _.uniqBy([...problems, childFeature], "_id")
        : problems,
    areas:
      childFeature?.type === "area"
        ? _.uniqBy([...areas, childFeature], "_id")
        : areas,
    routeIds:
      childFeature?.type === "route"
        ? _.uniqBy([...routeIds, childFeature._id], "_id")
        : routeIds,
    problemIds:
      childFeature?.type === "problem"
        ? _.uniqBy([...problemIds, childFeature._id], "_id")
        : problemIds,
    areaIds:
      childFeature?.type === "area"
        ? _.uniqBy([...areaIds, childFeature._id], "_id")
        : areaIds,
    numProblems:
      childFeature?.type === "problem" ? numProblems + 1 : numProblems,
    numRoutes: childFeature?.type === "route" ? numRoutes + 1 : numRoutes,
    numAreas: childFeature?.type === "area" ? numAreas + 1 : numAreas,
    location: {
      __typename: "PointInput",
      ...(area.location || childFeature.location),
    },
  }
}

export function addMissingGuideParameters(guide, childFeature, userId) {
  const routes = guide.routes || []
  const problems = guide.problems || []
  const areas = guide.areas || []
  const routeIds = guide.routeIds || []
  const problemIds = guide.problemIds || []
  const areaIds = guide.areaIds || []
  const numProblems = guide.numProblems || 0
  const numRoutes = guide.numRoutes || 0
  const numAreas = guide.numAreas || 0
  return {
    __typename: "Guide",
    type: "guide",
    hasMedia: false,
    popMedia: null,
    router: "",
    camping: "",
    region: null,
    country: null,
    firstEditorId: userId,
    woeid: null,
    leaderboardScores: null,
    editors: [
      {
        __typename: "Editor",
        user: {
          __typename: "User",
          _id: userId,
        },
        version: 1,
        date: Date.now(),
      },
    ],
    version: 1,
    media: [],
    sends: [],
    advocates: null,
    minRouteGrade: 1,
    maxRouteGrade: 100,
    minProblemGrade: 1,
    maxProblemGrade: 100,
    ...guide,
    routes:
      childFeature?.type === "route"
        ? _.uniqBy([...routes, childFeature], "_id")
        : routes,
    problems:
      childFeature?.type === "problem"
        ? _.uniqBy([...problems, childFeature], "_id")
        : problems,
    areas:
      childFeature?.type === "area"
        ? _.uniqBy([...areas, childFeature], "_id")
        : areas,
    routeIds:
      childFeature?.type === "route"
        ? _.uniqBy([...routeIds, childFeature._id], "_id")
        : routeIds,
    problemIds:
      childFeature?.type === "problem"
        ? _.uniqBy([...problemIds, childFeature._id], "_id")
        : problemIds,
    areaIds:
      childFeature?.type === "area"
        ? _.uniqBy([...areaIds, childFeature._id], "_id")
        : areaIds,
    numProblems:
      childFeature?.type === "problem" ? numProblems + 1 : numProblems,
    numRoutes: childFeature?.type === "route" ? numRoutes + 1 : numRoutes,
    numAreas: childFeature?.type === "area" ? numAreas + 1 : numAreas,
    location: {
      __typename: "PointInput",
      ...(guide.location || childFeature.location),
    },
  }
}

export function addMissingDeleteAreaParameters(area, userId) {
  return {
    description: null,
    directions: null,
    access: null,
    sends: [],
    media: [],
    problems: [],
    routes: [],
    areas: [],
    guide: null,
    hasMedia: false,
    version: 1,
    ...area,
    editors: [
      {
        __typename: "Editor",
        user: {
          __typename: "User",
          _id: userId,
        },
        version: 1,
        date: Date.now(),
      },
    ],
    location: null,
  }
}

export function addMissingDeleteGuideParameters(guide, userId) {
  return {
    hasMedia: false,
    firstEditorId: userId,
    woeid: null,
    region: null,
    country: null,
    camping: null,
    router: null,
    access: null,
    directions: null,
    description: null,
    version: 1,
    ...guide,
  }
}

export function addMissingGymParameters(gym) {
  return {
    __typename: "Gym",
    isShowingGrades: false,
    isShowingCustomGradesToSetters: false,
    settingColors: [],
    routeGradeColors: [],
    problemGradeColors: [],
    isHidden: false,
    isBeta: false,
    logo: null,
    color: null,
    numWeeksForOldRoute: null,
    numWeeksForOldProblem: null,
    numWeeksForOldClimb: null,
    isFrenchGrades: false,
    location: {
      __typename: "PointInput",
      coordinates: [],
    },
    popMedia: null,
    headSetterUsers: [],
    setterUsers: [],
    hasMissingClimbEmails: false,
    ...gym,
  }
}

export function addMissingGymAreaParameters(gymArea) {
  return {
    __typename: "GymArea",
    problemIds: [],
    routeIds: [],
    model3dImage: null,
    hasProblems: false,
    hasRoutes: false,
    sortOrder: null,
    popMedia: null,
    setterPicture: null,
    ...gymArea,
  }
}

export function addMissingSendParameters(send, user) {
  return {
    __typename: "Send",
    type: "send",
    isGreatest: false,
    isFirstAscent: false,
    isOnsight: false,
    media: null,
    isAttempts: false,
    rope: null,
    harness: null,
    version: 1,
    description: null,
    comments: [],
    isIndoor: false,
    ...send,
    shoes: send.shoes || null,
    problem: send.problem
      ? addMissingProblemParameters(_.cloneDeep(send.problem))
      : null,
    route: send.route
      ? addMissingRouteParameters(_.cloneDeep(send.route))
      : null,
    user,
    habitsComplete: [],
  }
}

export default {
  getFakeSlug,
  getObjectId,
  addMissingProblemParameters,
  addMissingRouteParameters,
  addMissingAreaParameters,
  addMissingDeleteAreaParameters,
  addMissingDeleteGuideParameters,
  addMissingSendParameters,
}
