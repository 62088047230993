import { useEffect, useState } from "react"
import { useQuery } from "@apollo/client"
// import { SettingsContext } from "../../common/contexts/SettingsContext"
import { GET_GYM } from "../graphql/gyms"

export function useRouteSetters({ loggedInUser }) {
  // switch back once migrated settings in app to use same struct as mobile
  // const { loggedInUser } = useContext(SettingsContext)
  const [currentGymSlug, setCurrentGymSlug] = useState()
  const [isSetter, setIsSetter] = useState(false)
  const [isHeadSetter, setIsHeadSetter] = useState(false)
  const [setters, setSetters] = useState()
  const [settingColors, setSettingColors] = useState([])
  const {
    loading,
    error,
    data: { gym = {} } = {},
  } = useQuery(GET_GYM, {
    skip: !loggedInUser || !currentGymSlug,
    variables: {
      slug: currentGymSlug,
    },
  })
  useEffect(() => {
    if (loggedInUser && gym.slug) {
      const isHead = loggedInUser.gymsHeadSetterAt.includes(gym._id)
      setIsHeadSetter(isHead)
      setIsSetter(loggedInUser.gymsSetterAt.includes(gym._id))
      setSetters(isHead ? gym.setterUsers : false)
      setSettingColors(gym.settingColors)
    }
  }, [gym._id, gym.setterUsers, gym.settingColors, gym.slug, loggedInUser])

  return {
    setCurrentGymSlug,
    isSetter,
    isHeadSetter,
    setters,
    settingColors,
  }
}

export default useRouteSetters
