import * as React from "react"
import {
  Box,
  Heading,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Switch,
} from "@chakra-ui/react"
import { CompScore, CompSet } from "../../../gql/types/graphql"

export interface IWCBoulderingScoreInputProps {
  compScore: CompScore
  compSet: CompSet
  updateCompScore(compScore: CompScore): void
  updateCompScore(key: string, value: string | number | boolean | any[]): void
  setMessage(message: string, isError: boolean): void
}

export default function WCBoulderingScoreInput({
  compScore,
  compSet,
  updateCompScore,
  setMessage,
}: IWCBoulderingScoreInputProps) {
  return (
    <Box>
      <Heading size="sm" mb={2}>
        Attempts to zone
      </Heading>
      <NumberInput
        width={"70px"}
        isDisabled={
          (compScore?.controlledZone ?? 0) <
          (compSet?.zoneHoldPoints?.length ?? 0)
        }
        onChange={(value) => {
          if (!compScore.userId && compSet.judgeType === "judge") {
            setMessage("You must select a competitor first.", false)
            return
          }
          updateCompScore("attemptsToZone", Number(value))
          if (
            Number(value) > (compScore.attemptsToTop ?? 0) ||
            !compScore.isZoneControlled
          ) {
            updateCompScore("attemptsToTop", Number(value))
          }
        }}
        value={compScore?.attemptsToZone ?? 1}
        min={1}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <Switch
        isChecked={compScore?.isZoneControlled ?? false}
        onChange={({ target: { checked: val } }) => {
          if (!compScore.userId && compSet.judgeType === "judge") {
            setMessage("You must select a competitor first.", true)
            return
          }
          updateCompScore("isZoneControlled", val)
          updateCompScore("attemptsToZone", compScore.attemptsToZone || 1)
          if (!val) {
            updateCompScore("isTopControlled", val)
            updateCompScore("attemptsToTop", compScore?.attemptsToZone ?? 1)
          }
        }}
      >{`Did ${
        compSet.judgeType === "judge" ? "they" : "you"
      } control the zone?`}</Switch>
      <Heading size="sm" mb={2}>
        Attempts to top
      </Heading>
      <NumberInput
        width={"70px"}
        isDisabled={
          (compScore?.controlledZone ?? 0) <
          (compSet?.zoneHoldPoints?.length ?? 0)
        }
        title="Attempts to top"
        onChange={(val) => {
          if (!compScore.userId && compSet.judgeType === "judge") {
            setMessage("You must select a competitor first.", false)
            return
          }
          updateCompScore(
            "attemptsToTop",
            Math.max(Number(val), compScore?.attemptsToZone ?? 1),
          )
        }}
        value={compScore?.attemptsToTop ?? 1}
        min={1}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <Switch
        isChecked={compScore?.isTopControlled ?? false}
        onChange={({ target: { checked: val } }) => {
          if (!compScore.userId && compSet.judgeType === "judge") {
            setMessage("You must select a competitor first.", true)
            return
          }
          updateCompScore("isTopControlled", val)
          if (val) {
            updateCompScore("isZoneControlled", val)
          }
          updateCompScore("attemptsToZone", compScore.attemptsToZone || 1)
          updateCompScore("attemptsToTop", compScore.attemptsToTop || 1)
          updateCompScore("isCreatingSend", !!val)
        }}
      >{`Did ${
        compSet.judgeType === "judge" ? "they" : "you"
      } control the top?`}</Switch>
    </Box>
  )
}
