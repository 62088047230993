import gql from "graphql-tag"
import { basicUserFragment } from "../../users/graphql/basicUserFragment"
import { basicMediaFragment } from "../../media/graphql/basicMediaFragment"
import { characteristicGroupFragment } from "../../gyms/graphql/characteristicGroupFragment"

export const mediumProblemFragment = gql`
  fragment mediumProblemFragment on Problem {
    _id
    name
    slug
    type
    isArchived
    isIndoor
    archivedAt
    setterGrade
    grade
    gradeColor
    gradeName
    points
    totalAscents
    createDate
    numUserSends
    numUserAttempts
    publishedAt
    startingHoldPicture
    hasPrintedLabel
    device_mac
    popMedia {
      ...basicMediaFragment
    }
    grades {
      user
      grade
    }
    quality
    qualities {
      user
      stars
    }
    location {
      coordinates
    }
    color
    description
    picture
    overviewPicture
    competitionPicture
    numberOfSignificantMoves

    height
    start
    crimpy
    slopey
    pinchy
    juggy
    pockety
    undercling
    heelhooks
    toehooks
    kneebar
    bigFeet
    smallFeet
    compression
    tension
    dynamic
    static
    tension
    coordination
    technical
    crack
    sun
    slab
    vertical
    overhang
    steep
    roof
    arete
    dihedral
    landing
    customCharacteristics
    isDraft
    topoPosition {
      x
      y
    }
    maxCompScores {
      compSet
      maxCompScore
    }
    numHolds
    isSponsored
    setter {
      ...basicUserFragment
    }
    setterPictureLocation {
      x
      y
    }
    holdLocations {
      x
      y
    }
    gym {
      _id
      slug
      type
      name
      isShowingGrades
      isShowingCustomGradesToSetters
      isShowingOutdoorRouteGradeForm
      isFrenchGrades
      isOverlappingGrades
      problemGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
      isSetterFeedbackOn
      hasScanners
      isTopoMapStarted
      isTopoMapEnabled
      characteristicGroups {
        ...characteristicGroupFragment
      }
    }
    gymArea {
      _id
      slug
      type
      name
      sortOrder
      setterPicture
      popMedia {
        ...basicMediaFragment
      }
    }
    guide {
      _id
      slug
      type
      name
    }
    comps {
      _id
      type
      name
    }
    compSets {
      _id
      type
      name
      start
      end
      isClimbsEnabledBeforeStart
      isGradingHolds
      isRelativeScoring
      relativeScore
      numClimbsBeforeBump
      isGradingAttempts
      attemptPoints
      maxTimesClimbScored
      numClimbsToScore
      numZoneHolds
      judgeType
      pointsPerGradeDiff
      isWorldCupSport
      isWorldCupBouldering
      isWorldCupBoulderingPoints
      comp {
        _id
        type
      }
    }
    judgedSets {
      _id
      type
      name
      start
      end
      isGradingHolds
      isRelativeScoring
      relativeScore
      numClimbsBeforeBump
      isGradingAttempts
      attemptPoints
      maxTimesClimbScored
      numClimbsToScore
      numZoneHolds
      judgeType
      pointsPerGradeDiff
      isWorldCupSport
      isWorldCupBouldering
      isWorldCupBoulderingPoints
    }
    userCompScores {
      _id
      type
      score
      highpoint
      numAttempts
      createdAt
      judgedAt
      comp {
        _id
      }
      compSet {
        _id
      }
      send {
        _id
        slug
      }
      competitor {
        _id
        type
        maxProblemGrade
        maxRouteGrade
        previousMaxRouteGrades {
          grade
          maxDate
        }
        previousMaxProblemGrades {
          grade
          maxDate
        }
      }
    }
    betaVideo {
      ...basicMediaFragment
    }
    topoPosition {
      x
      y
    }
  }
  ${basicUserFragment}
  ${basicMediaFragment}
  ${characteristicGroupFragment}
`

export default { mediumProblemFragment }
