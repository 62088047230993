import gql from "graphql-tag"
import { mediumProblemFragment } from "../../guides/graphql/mediumProblemFragment"
import { mediumRouteFragment } from "../../guides/graphql/mediumRouteFragment"
import { basicUserFragment } from "../../users/graphql/basicUserFragment"
import { basicSendFragment } from "../../sends/graphql/basicSendFragment"
import { basicScannerActivityFragment } from "src/app/scanners/graphql/scanners"

export const basicCompScoreFragment = gql`
  fragment basicCompScoreFragment on CompScore {
    _id
    score
    highpoint
    numAttempts
    isPlusGiven
    isTopControlled
    isZoneControlled
    attemptsToTop
    attemptsToZone
    time
    relativeDelta
    problem {
      ...mediumProblemFragment
    }
    route {
      ...mediumRouteFragment
    }
    send {
      ...basicSendFragment
    }
    comp {
      _id
      name
      isShowingOverallScores
    }
    compSet {
      _id
      isWorldCupSport
      isWorldCupBouldering
      isWorldCupBoulderingPoints
      isShowingStarRating
      judgeType
      isTimeLeft
    }
    competitor {
      _id
      name
      scores {
        compScore
        compSets {
          compSet {
            _id
          }
          score
        }
      }
      user {
        ...basicUserFragment
      }
    }
    judge {
      ...basicUserFragment
    }
    judgedAt
    createdAt
    scoreBonuses {
      _id
      name
      description
      points
    }
    controlledZone
    attemptsToZones
    activity {
      _id
      name
      description
      points
    }
  }
  ${mediumProblemFragment}
  ${mediumRouteFragment}
  ${basicSendFragment}
  ${basicUserFragment}
`

export default {
  basicCompScoreFragment,
}
