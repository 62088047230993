import gql from "graphql-tag"
import { basicUserFragment } from "../../users/graphql/basicUserFragment"
import { basicProblemFragment } from "../../guides/graphql/basicProblemFragment"
import { basicRouteFragment } from "../../guides/graphql/basicRouteFragment"
import { basicMediaFragment } from "../../media/graphql/basicMediaFragment"
import { basicGuideFragment } from "../../guides/graphql/basicGuideFragment"
import { basicAreaFragment } from "../../guides/graphql/basicAreaFragment"
import { feedCommentFragment } from "./feedCommentFragment"
import { basicSendFragment } from "../../sends/graphql/basicSendFragment"
import { basicGymAreaFragment } from "../../gyms/graphql/basicGymAreaFragment"
import { basicGymFragment } from "../../gyms/graphql/basicGymFragment"
import { workoutFragment } from "../../workouts/graphql/workouts"
import { basicScannerActivityFragment } from "../../scanners/graphql/scanners"
import { gamePyramidFragment } from "../../gamePyramids/graphql/gamePyramids"
import { gameFragment } from "../../games/graphql/games"

export const basicFeedItemFragment = gql`
  fragment basicFeedItemFragment on FeedItem {
    _id
    eventType
    achievementType
    achievementValue
    location {
      coordinates
    }
    user {
      ...basicUserFragment
    }
    isCommentsHidden
    date
    title
    description
    link
    image
    slug
    likes {
      ...basicUserFragment
    }
    oembed {
      html
      provider_name
      thumbnail_url
      url
    }
    numComments
  }
  ${basicUserFragment}
`

export const fullFeedItemFragment = gql`
  fragment fullFeedItemFragment on FeedItem {
    _id
    eventType
    achievementType
    achievementValue
    location {
      coordinates
    }
    user {
      ...basicUserFragment
    }
    date
    title
    description
    link
    image
    slug
    isCommentsHidden
    likes {
      ...basicUserFragment
    }
    numComments
    problems {
      ...basicProblemFragment
    }
    routes {
      ...basicRouteFragment
    }
    media {
      ...basicMediaFragment
    }
    areas {
      ...basicAreaFragment
    }
    guides {
      ...basicGuideFragment
    }
    sends {
      ...basicSendFragment
      problem {
        gymArea {
          _id
          name
          popMedia {
            ...basicMediaFragment
          }
        }
      }
      route {
        gymArea {
          _id
          name
          popMedia {
            ...basicMediaFragment
          }
        }
      }
    }
    sessionType
    session {
      _id
      type
      guide {
        ...basicGuideFragment
      }
      gym {
        ...basicGymFragment
      }
      attempts {
        _id
        climbType
        problem {
          _id
          gymArea {
            _id
            name
            popMedia {
              ...basicMediaFragment
            }
          }
        }
        route {
          _id
          gymArea {
            _id
            name
            popMedia {
              ...basicMediaFragment
            }
          }
        }
      }
      sends {
        ...basicSendFragment
        problem {
          _id
          gymArea {
            _id
            name
            popMedia {
              ...basicMediaFragment
            }
          }
        }
        route {
          _id
          gymArea {
            _id
            name
            popMedia {
              ...basicMediaFragment
            }
          }
        }
      }
      workout {
        _id
        name
        type
        slug
      }
      activity {
        ...basicScannerActivityFragment
      }
    }
    gymAreas {
      ...basicGymAreaFragment
    }
    gym {
      ...basicGymFragment
    }
    gyms {
      ...basicGymFragment
    }
    gamePyramid {
      ...gamePyramidFragment
    }
    game {
      ...gameFragment
    }
    survey {
      _id
      name
      type
      slug
      description
      picture
    }
    comp {
      _id
      type
      slug
      name
      description
      picture
      gym {
        ...basicGymFragment
      }
      currentCompSets {
        _id
        type
        gymAreas {
          _id
          type
          slug
          name
        }
      }
    }
    workout {
      ...workoutFragment
    }
    oembed {
      html
      provider_name
      thumbnail_url
      url
    }
  }
  ${basicUserFragment}
  ${basicProblemFragment}
  ${basicRouteFragment}
  ${basicMediaFragment}
  ${basicAreaFragment}
  ${basicGuideFragment}
  ${basicSendFragment}
  ${basicGymAreaFragment}
  ${basicGymFragment}
  ${workoutFragment}
  ${basicScannerActivityFragment}
  ${gamePyramidFragment}
  ${gameFragment}
`

export const FeedQuery = gql`
  query FeedQuery(
    $cursor: String
    $isAcceptingWorkouts: Boolean
    $version: Int
  ) {
    feed(
      cursor: $cursor
      isAcceptingWorkouts: $isAcceptingWorkouts
      version: $version
    ) {
      cursor
      hasMore
      feedItems {
        ...fullFeedItemFragment
      }
    }
  }
  ${fullFeedItemFragment}
`

export const GET_FULL_FEED = gql`
  query GetFullFeed($cursor: String) {
    fullFeed(cursor: $cursor) {
      cursor
      hasMore
      feedItems {
        ...fullFeedItemFragment
      }
    }
  }
  ${fullFeedItemFragment}
`

export const FeedItemQuery = gql`
  query FeedItemQuery($slug: String!) {
    feedItem(slug: $slug) {
      ...fullFeedItemFragment
    }
  }
  ${fullFeedItemFragment}
`

export const FeedItemCommentsQuery = gql`
  query FeedItemCommentsQuery($slug: String!) {
    feedItemComments(slug: $slug) {
      ...feedCommentFragment
    }
  }
  ${feedCommentFragment}
`

// export const OFFLINE_FEED_ITEMS = gql`
//   query OfflineFeedItems {
//     offlineFeedItems {
//       ...fullFeedItemFragment
//     }
//   }
//   ${fullFeedItemFragment}
// `

// export const FeedItemLikesQuery = gql`
//   query FeedItemLikesQuery($slug: String!) {
//     feedItemLikes(slug: $slug) {
//       ...basicUserFragment
//     }
//   }
//   ${basicUserFragment}
// `

export const commentOnFeedItemMutate = gql`
  mutation commentOnFeedItemMutate($slug: String!, $comment: String!) {
    commentOnFeedItem(slug: $slug, comment: $comment) {
      ...feedCommentFragment
    }
  }
  ${feedCommentFragment}
`

export const uncommentOnFeedItemMutate = gql`
  mutation uncommentOnFeedItemMutate($slug: String!, $commentId: String!) {
    uncommentOnFeedItem(slug: $slug, commentId: $commentId) {
      ...feedCommentFragment
    }
  }
  ${feedCommentFragment}
`

export const likeFeedItemMutate = gql`
  mutation likeFeedItemMutate($slug: String!) {
    likeFeedItem(slug: $slug) {
      ...basicFeedItemFragment
    }
  }
  ${basicFeedItemFragment}
`

export const unlikeFeedItemMutate = gql`
  mutation unlikeFeedItemMutate($slug: String!) {
    unlikeFeedItem(slug: $slug) {
      ...basicFeedItemFragment
    }
  }
  ${basicFeedItemFragment}
`

export const SEND_FEEDBACK = gql`
  mutation SendFeedback($pageInfo: String!, $message: String!) {
    sendFeedback(pageInfo: $pageInfo, message: $message) {
      isSuccessful
      error
    }
  }
`

export default {
  basicFeedItemFragment,
  fullFeedItemFragment,
  feedCommentFragment,
  FeedQuery,
  FeedItemQuery,
  FeedItemCommentsQuery,
  // FeedItemLikesQuery,
  commentOnFeedItemMutate,
  uncommentOnFeedItemMutate,
  likeFeedItemMutate,
  unlikeFeedItemMutate,
  SEND_FEEDBACK,
}
