import React from "react"

const ManageCompetitorsContext = React.createContext({})
const ManageCompetitorsProvider = ({ children }) => {
  const [competitor, setCompetitor] = React.useState()
  return (
    <ManageCompetitorsContext.Provider
      value={{
        competitor,
        setCompetitor,
      }}
    >
      {children}
    </ManageCompetitorsContext.Provider>
  )
}
export { ManageCompetitorsContext, ManageCompetitorsProvider }
