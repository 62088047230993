import { Avatar, Button, Heading, Input } from '@chakra-ui/react';
import * as React from 'react';
import { User } from '../../../gql/types/graphql';
import Card from '../../common/components/Card';
import AthletesSearchList from '../../users/components/AthletesSearchList';
import UserCard from '../../users/components/UserCard';

export interface IAthleteRowProps {
  athlete: User;
  onClick: (athlete: User) => void;
}

const AthleteRow = ({ athlete, onClick, ...props }: IAthleteRowProps) => (
  <UserCard size="40px" isSmall user={athlete} onClick={onClick} {...props} />
)

export interface IJudgeFinderProps {
  judge: User
  setJudge: (judge: User) => void
  excludedUserId?: string
}

export default function JudgeFinder ({ judge, setJudge, excludedUserId }: IJudgeFinderProps) {
  const [isFindingJudge, setIsFindingJudge] = React.useState(false);
  const [searchStr, setSearchStr] = React.useState('');
  if (isFindingJudge) {
    return (<>
      <Card>
        <Input
          placeholder="Search for a judge"
          value={searchStr}
          onChange={(e) => setSearchStr(e.target.value)}
        />
      </Card>

      <AthletesSearchList
        isEmbedded
        searchStr={searchStr}
        // @ts-ignore
        filterFn={(user) => !excludedUserId || user._id !== excludedUserId}
        Row={AthleteRow}
        onClick={(item) => {
          setJudge(item)
          setIsFindingJudge(false)
        }}
      />
    </>
    );
  }
  return (
    <>
      {!!judge && (
        <Card
          overflow="hidden"
          width="100%"
          display="flex"
          alignItems="center"
          mb={5}
        >
          <>
            <Avatar
              m={-5}
              mr={3}
              src={judge.profile?.picture ?? undefined}
              name={judge.name ?? undefined}
              size="lg"
              borderRadius={0}
            />
            <Heading mb={0} as="h6" size="sm" variant="link">
              {judge?.name}
            </Heading>
          </>
        </Card>
      )}
      <Button
        width="100%"
        variant="secondary"
        onClick={() => setIsFindingJudge(true)}
      >
        {!!judge ? "Change Judge" : "Find a Judge"}
      </Button>

    </>
  );
}
