import React, { useState, useEffect } from "react"
import Modal from "react-modal"
import {
  Heading,
  IconButton,
  Button,
  Link,
  Flex,
  Input,
  Divider,
  Text,
  useTheme,
} from "@chakra-ui/react"
import Grid from "../../common/components/Grid"
import Cell from "../../common/components/Cell"
import Card from "../../common/components/Card"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-light-svg-icons"
import { Box } from "@chakra-ui/react"
import CompetitorSearch from "./CompetitorSearch"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import {
  GET_COMPETITORS_SCORES,
  DELETE_COMP_SCORE,
  GET_COMP_SCORE_FROM_CLIMB,
  TRANSFER_SCORES,
  GET_COMP_SET,
  DELETE_SCANNER_COMP_SCORE,
} from "../graphql/comps"
import CompetitorRow from "./CompetitorRow"
import GradeContext from "../../context/GradeContext"
import ClimbGrade from "../../common/components/ClimbGrade"
import ConfirmModal from "../../common/components/ConfirmModal"
import CompClimbSearch from "./CompClimbSearch"
import CreateCompScoreAdmin from "./CreateCompScoreAdmin"
import exportFromJSON from "export-from-json"
import { siteURL } from "../../config/env"
import dayjs from "dayjs"
import UserSearch from "../../users/components/UserSearch"
import UserCard from "../../users/components/UserCard"
import CompetitorCard from "./CompetitorCard"
import TextLink from "../../common/components/TextLink"
import RecalcRelativeScoresButton from "./RecalcRelativeScoresButton"
import CompetitorFromCompCodeInput from "./CompetitorFromCodeInput"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { getWCBoulderingScoreFromPoints } from "../../utilities/comps"
import Tabs from "../../common/components/Tabs"
import EditCompRoundScannerActivityCompScores from "../../comps/components/EditCompRoundScannerActivityCompScores"
import useColors from "../../common/hooks/useColors"

function downloadCompetitorScores({ competitor, comp, compSet, scores }) {
  const data = scores.map((score) => ({
    Score: score.score,
    ...(compSet.isGradingAttempts ? { Attempts: score.numAttempts } : {}),
    ...(compSet.isGradingHolds ? { Highpoint: score.highpoint } : {}),
    ...(score.problem
      ? {
          Boulder: `${score.problem.name} - ${siteURL}/problems/${score.problem.slug}`,
        }
      : {}),
    ...(score.route
      ? { Route: `${score.route.name} - ${siteURL}/routes/${score.route.slug}` }
      : {}),
    ...(score.send ? { Send: `${siteURL}/sends/${score.send.slug}` } : {}),
    ...(score.judge ? { Judge: score.judge.name } : {}),
    "Date Recorded": dayjs(score.createdAt).format("LLLL"),
  }))
  const fileName = `${competitor.name} - ${compSet.name} - ${comp.name} - Scores`
  const exportType = "csv"
  exportFromJSON({ data, fileName, exportType })
}

const EditCompRoundScoresModal = ({
  isModalOpen,
  comp,
  compSet,
  closeModal,
  setAlertMessage,
  isManager,
}) => {
  const { blueColor } = useColors()
  const [competitor, setCompetitor] = useState()
  const [isEditingScore, setIsEditingScore] = useState(false)
  const [compClimb, setCompClimb] = useState()
  const [scannerActivity, setScannerActivity] = useState()
  const [curTab, setCurTab] = useState("climbs")
  const [competitorCode, setCompetitorCode] = useState()
  const [scoreToEdit, setScoreToEdit] = useState()
  const [isTransferringScores, setIsTransferringScores] = useState(false)
  const [userToTransferTo, setUserToTransferTo] = useState()
  const [isSavingTransfer, setIsSavingTransfer] = useState(false)
  const [hasTransferred, setHasTransferred] = useState(false)

  const [getCompetitorsScores, { data, loading, error }] = useLazyQuery(
    GET_COMPETITORS_SCORES,
  )

  const { data: compSetData } = useQuery(GET_COMP_SET, {
    variables: { slug: compSet.slug },
    skip: !isModalOpen,
  })
  const scannerActivities = compSetData?.compSet?.scannerActivities ?? []

  const [transferScores] = useMutation(TRANSFER_SCORES)

  useEffect(() => {
    if (competitor?._id) {
      getCompetitorsScores({
        variables: {
          compSetId: compSet._id,
          competitorId: competitor?._id,
        },
        fetchPolicy: "network-only",
      })
    }
  }, [compSet._id, competitor?._id, getCompetitorsScores])
  const scores = data?.getCompetitorsScores?.scores ?? []

  return (
    <GradeContext.Consumer>
      {(gradeType) => (
        <>
          <Modal
            isOpen={isModalOpen}
            className={"modal-container flex-center"}
            overlayClassName="modal-overlay"
            ariaHideApp={false}
            contentLabel={compSet.name}
            onRequestClose={() => {
              closeModal()
              setCompetitor()
              setCompetitorCode()
              setScoreToEdit()
              setIsEditingScore(false)
              setIsTransferringScores(false)
              setUserToTransferTo()
              setIsSavingTransfer(false)
              setHasTransferred(false)
            }}
          >
            <Card width={700}>
              <IconButton
                variant="text"
                position="absolute"
                top={0}
                right={0}
                onClick={() => {
                  closeModal()
                  setCompetitor()
                  setCompetitorCode()
                  setScoreToEdit()
                  setIsEditingScore(false)
                  setIsTransferringScores(false)
                  setUserToTransferTo()
                  setIsSavingTransfer(false)
                  setHasTransferred(false)
                }}
              >
                <FontAwesomeIcon color={blueColor} icon={faTimes} />
              </IconButton>
              <Heading size="md" as="h4">
                {isManager
                  ? `Add, edit, or transfer a user’s score for ${compSet.name}.`
                  : `Add or edit your scores for ${compSet.name}.`}
              </Heading>
              {competitor ? (
                <>
                  <Box display="flex" alignItems="center">
                    <CompetitorRow competitor={competitor} isBottom />
                    <IconButton
                      mb={2}
                      variant="text"
                      onClick={() => {
                        setCompetitor()
                        setIsEditingScore(false)
                        setCompClimb()
                        setScannerActivity()
                      }}
                    >
                      <FontAwesomeIcon color={blueColor} icon={faTimes} />
                    </IconButton>
                  </Box>
                  <Divider mt={0} />
                  {isEditingScore ? (
                    <Box>
                      <Flex align="center" justify="space-between">
                        <Heading mb={1} as="h4" size="sm">
                          {isManager
                            ? `Add a score for ${competitor.name}`
                            : `${competitor.name}, add a score below.`}
                        </Heading>
                        <IconButton
                          variant="text"
                          onClick={() => {
                            setIsEditingScore(false)
                            setCompClimb()
                            setScannerActivity()
                          }}
                        >
                          <FontAwesomeIcon color={blueColor} icon={faTimes} />
                        </IconButton>
                      </Flex>
                      {/* inputs for finding climb, adding score (should create send on server) */}
                      {compClimb ? (
                        <CreateCompScoreAdmin
                          climb={compClimb}
                          competitor={competitor}
                          compSet={compSet}
                          comp={comp}
                          competitorCode={competitorCode}
                          compSetScores={scores}
                          setAlertMessage={setAlertMessage}
                          score={scoreToEdit}
                          finishSaving={async () => {
                            setScoreToEdit()
                            await getCompetitorsScores({
                              variables: {
                                compSetId: compSet._id,
                                competitorId: competitor?._id,
                              },
                              fetchPolicy: "network-only",
                            })
                            setIsEditingScore(false)
                            setCompClimb()
                            setScannerActivity()
                          }}
                          cancel={() => {
                            setScoreToEdit()
                            setCompClimb()
                            setScannerActivity()
                          }}
                        />
                      ) : (
                        <>
                          <Tabs
                            tabsAr={[
                              {
                                name: "Climbs",
                                value: "climbs",
                              },
                              {
                                name: "Scanner Activities",
                                value: "activities",
                              },
                            ]}
                            curTab={curTab}
                            onTabChange={(type) => {
                              setCurTab(type)
                            }}
                          />
                          <Box mt={3}>
                            {curTab === "climbs" && (
                              <CompClimbSearch
                                compSetId={compSet?._id}
                                title="Find a climb in this set to score"
                                setCompClimb={setCompClimb}
                              />
                            )}
                            {curTab === "activities" && (
                              <EditCompRoundScannerActivityCompScores
                                scannerActivities={scannerActivities}
                                compSet={compSet}
                                competitor={competitor}
                                setAlertMessage={setAlertMessage}
                                finishSaving={() => {
                                  setIsEditingScore(false)
                                  setCompClimb()
                                  setScannerActivity()
                                }}
                              />
                            )}
                          </Box>
                        </>
                      )}
                    </Box>
                  ) : isTransferringScores ? (
                    <>
                      <Box>
                        <Heading as="h5" size="sm">
                          Transfer competitor to another user
                        </Heading>
                        <Text>
                          If one user mistakenly logged into the app with a
                          different user than they use to sign up for the comp,
                          you can use this form to recompute their scores and
                          assign the competitor to the user that logged the
                          sends.
                        </Text>
                        <UserSearch
                          title="Find a user"
                          onClick={(user) => {
                            setUserToTransferTo(user)
                          }}
                        />
                        {!!userToTransferTo && (
                          <>
                            {hasTransferred ? (
                              <>
                                <h6>Success!</h6>
                                <p>
                                  Scores have been transferred from{" "}
                                  {competitor?.name} to {userToTransferTo?.name}
                                  . Reload the page to see the changes to the
                                  leaderboard.
                                </p>
                              </>
                            ) : (
                              <>
                                <Box display="flex" alignItems="center">
                                  <Box flex={1}>
                                    <CompetitorCard competitor={competitor} />
                                  </Box>
                                  <Box mx={4}>
                                    <FontAwesomeIcon
                                      color={blueColor}
                                      icon={faArrowRight}
                                    />
                                  </Box>
                                  <Box flex={1}>
                                    <UserCard user={userToTransferTo} />
                                  </Box>
                                </Box>
                                {/* <Button
                                  isPrimary
                                  size="sm"
                                  variant={"secondary"}
                                  isLoading={isSavingTransfer}
                                  onClick={async () => {
                                    setIsSavingTransfer(true)
                                    try {
                                      const res = await transferScores({
                                        variables: {
                                          fromCompetitorId: competitor._id,
                                          toUserId: userToTransferTo._id,
                                          compSetId: compSet._id,
                                        },
                                      })
                                      if (res?.data?.transferScores?._id) {
                                        setHasTransferred(true)
                                        setTimeout(() => {
                                          setHasTransferred(false)
                                          setUserToTransferTo()
                                          setIsTransferringScores(false)
                                        }, 5000)
                                      } else {
                                        setAlertMessage(
                                          "Something went wrong. Please contact support@pebbleclimbing.com.",
                                          true,
                                        )
                                      }
                                    } catch (e) {
                                      console.error(e)
                                    }
                                    setIsSavingTransfer(false)
                                  }}
                                >
                                  Transfer Scores
                                </Button> */}
                              </>
                            )}
                          </>
                        )}
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box
                        my={3}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Heading as="h4" size="md">
                          Scores {compSet ? `for ${compSet.name}` : ""}
                        </Heading>
                        {isManager && (
                          <Flex>
                            <Button
                              mr={3}
                              size="sm"
                              variant={"secondary"}
                              onClick={() => {
                                downloadCompetitorScores({
                                  competitor,
                                  comp,
                                  compSet,
                                  scores,
                                })
                              }}
                            >
                              Download Scores
                            </Button>
                            {/* <Button
                              mr={3}
                              size="sm"
                              variant={"secondary"}
                              onClick={() => {
                                setIsTransferringScores(true)
                              }}
                            >
                              Transfer scores
                            </Button> */}
                            <RecalcRelativeScoresButton
                              size="sm"
                              title="Recalculate scores"
                              compId={comp._id}
                              competitorId={competitor._id}
                            />
                          </Flex>
                        )}
                      </Box>
                      <Box mt={3}>
                        <Grid className="width-100">
                          <Cell alignItems="flex-end" display="flex" col={4}>
                            <Heading size="sm">Climb</Heading>
                          </Cell>
                          <Cell alignItems="flex-end" display="flex" col={2}>
                            <Heading size="sm">Send</Heading>
                          </Cell>
                          <Cell alignItems="flex-end" display="flex" col={3}>
                            <Heading size="sm">Judge</Heading>
                          </Cell>
                          <Cell alignItems="flex-end" display="flex" col={2}>
                            <Box>
                              <Heading size="sm">Score</Heading>
                              {compSet.isGradingHolds && (
                                <Heading size="sm">Highpoint</Heading>
                              )}
                            </Box>
                          </Cell>
                          <Cell
                            alignItems="flex-end"
                            display="flex"
                            col={1}
                          ></Cell>
                        </Grid>
                      </Box>
                      {scores.map((score) => (
                        <ScoreRow
                          key={score._id}
                          score={score}
                          competitorCode={competitorCode}
                          gradeType={gradeType}
                          isWorldCupBouldering={compSet.isWorldCupBouldering}
                          isGradingHolds={compSet.isGradingHolds}
                          setScoreToEdit={() => {
                            setIsEditingScore(true)
                            setCompClimb(score.problem || score.route)
                            setScoreToEdit(score)
                          }}
                          refetchScores={() =>
                            getCompetitorsScores({
                              variables: {
                                compSetId: compSet._id,
                                competitorId: competitor?._id,
                              },
                              fetchPolicy: "network-only",
                            })
                          }
                        />
                      ))}
                      <Flex my={3} justifyContent="flex-end">
                        <Button
                          size="sm"
                          variant={"primary"}
                          onClick={() => {
                            setIsEditingScore(true)
                          }}
                        >
                          Add a score
                        </Button>
                      </Flex>
                    </>
                  )}
                </>
              ) : (
                <>
                  {isManager ? (
                    <CompetitorSearch
                      compId={comp?._id}
                      title="Find a competitor to download or adjust their scores"
                      setCompetitor={setCompetitor}
                    />
                  ) : (
                    <CompetitorFromCompCodeInput
                      competitor={competitor}
                      setCompetitor={setCompetitor}
                      setCompetitorCode={setCompetitorCode}
                    />
                  )}
                </>
              )}
            </Card>
          </Modal>
        </>
      )}
    </GradeContext.Consumer>
  )
}

const ScoreRow = ({
  score: compScore,
  gradeType,
  isGradingHolds,
  setScoreToEdit,
  refetchScores,
  competitorCode,
  isWorldCupBouldering,
}) => {
  const { headingLineColor } = useColors()
  const { score, highpoint, judge, send, problem, route, activity } = compScore
  const finalScore = isWorldCupBouldering
    ? getWCBoulderingScoreFromPoints({ pts: score })
    : Math.round(score * 100) / 100
  const climb = problem || route
  const [isConfirmDelete, setIsConfirmDelete] = useState(false)
  const [deleteScannerCompScore] = useMutation(DELETE_SCANNER_COMP_SCORE)
  const [deleteCompScore] = useMutation(DELETE_COMP_SCORE)

  return (
    <>
      <Grid py={2} borderBottom={`1px solid ${headingLineColor}`}>
        <Cell col={4}>
          <Box display="flex" alignItems="center">
            {climb ? (
              <>
                <Heading as="h6" size="sm">
                  <TextLink to={`/${climb.type}s/${climb.slug}`}>
                    {climb.name}
                  </TextLink>{" "}
                  -{" "}
                </Heading>
                <Box mt={-2} ml={2}>
                  <ClimbGrade
                    climb={climb}
                    gym={climb.gym}
                    gradeType={gradeType}
                    dotSize={20}
                    isCenter
                  />
                </Box>
              </>
            ) : (
              <Heading as="h6" size="sm">
                {activity.name}
              </Heading>
            )}
          </Box>
        </Cell>
        <Cell col={2}>
          {!!send?.slug && (
            <Heading as="h6" size="sm">
              <TextLink to={`/sends/${send.slug}`}>{send.name}</TextLink>
            </Heading>
          )}
        </Cell>
        <Cell col={3}>
          {!!judge && (
            <Heading as="h6" size="sm">
              <TextLink to={`/users/${judge.slug}`}>{judge.name}</TextLink>
            </Heading>
          )}
        </Cell>
        <Cell col={2}>
          <Heading as="h6" size="sm">
            <span className="orange-text">{finalScore}</span>
          </Heading>
          {isGradingHolds && (
            <Heading as="h6" size="sm">
              {!!send ? climb.numHolds : highpoint} of {climb.numHolds}
            </Heading>
          )}
        </Cell>
        <Cell col={1}>
          <Flex direction="column" align="flex-end">
            <Button
              mb={2}
              variant="primary"
              size="xs"
              onClick={() => setScoreToEdit(score)}
            >
              Edit
            </Button>
            <Button
              variant="secondary"
              size="xs"
              onClick={() => {
                setIsConfirmDelete(true)
              }}
            >
              Delete
            </Button>
          </Flex>
        </Cell>
      </Grid>
      <ConfirmModal
        isOpen={isConfirmDelete}
        title={"Are you sure you want to delete this score?"}
        actionString="Delete Score"
        action={async () => {
          if (compScore.activity) {
            await deleteScannerCompScore({
              variables: {
                scannerCompScoreId: compScore._id,
              },
            })
          } else {
            await deleteCompScore({
              variables: {
                compScoreId: compScore._id,
                competitorCode,
              },
              refetchQueries: [
                {
                  query: GET_COMP_SCORE_FROM_CLIMB,
                  variables: {
                    compId: compScore.comp._id,
                    climbId: climb._id,
                    climbType: climb.type,
                  },
                },
              ],
            })
          }
          await refetchScores()
        }}
        closeModal={() => {
          setIsConfirmDelete(false)
        }}
      />
    </>
  )
}

export default EditCompRoundScoresModal
