import { useAtom } from "jotai"
import { hasSidebarAtom } from "../atoms/sidebar"
import { useMediaQuery } from "@chakra-ui/react"

const Container = ({
  children,
  isThin = false,
  isFull = false,
  isHidingHeader = false,
  ...props
}) => {
  // TODO: use later to restyle if sidebar is open
  const [isSidebarBig] = useMediaQuery("(min-width: 1450px)")
  const [isSidebarSmall] = useMediaQuery("(min-width: 1250px)")
  const isShowingSidebar = isSidebarBig || isSidebarSmall
  const [hasSidebar] = useAtom(hasSidebarAtom)
  const sidebarWidth = isSidebarBig ? 280 : 200
  const marginRightBuffer = isSidebarBig ? 1700 : 1535
  const sidebarWidthBuffer = 20
  return (
    <div
      style={{
        marginTop: isHidingHeader ? "20px" : isFull ? "62px" : "80px",
        marginBottom: isFull ? 0 : 12,
        marginLeft: hasSidebar && isShowingSidebar ? "2rem" : "auto",
        marginRight:
          hasSidebar && isShowingSidebar
            ? isFull
              ? -1 * sidebarWidth + sidebarWidthBuffer
              : `calc(100vw - ${marginRightBuffer}px)`
            : "auto",
        width: isThin
          ? "800px"
          : isFull
          ? hasSidebar && isShowingSidebar
            ? `calc(100vw - ${sidebarWidth + sidebarWidthBuffer}px)`
            : "100vw"
          : "calc(1170px - 2rem)",
        maxWidth: "100%",
        minHeight: 600,
        flex: 1,
        padding: "0 1rem",
        ...props,
      }}
    >
      {children}
    </div>
  )
}
export default Container
