// TODO: investigate below
import { concatPagination } from "@apollo/client/utilities"

import _ from "lodash"

const typePolicies = {
  Workout: {
    customExercises: {
      merge(existing = [], incoming = [], { args }) {
        // console.log("existing:", existing)
        // console.log("incoming:", incoming)
        return _.uniqBy([...existing, ...incoming], "_id")
      },
    },
  },
  User: {
    followers: {
      merge(existing = [], incoming = []) {
        return _.uniqBy([...existing, ...incoming], "__ref")
      },
    },
    following: {
      merge(existing = [], incoming = []) {
        return _.uniqBy([...existing, ...incoming], "__ref")
      },
    },
    notifications: {
      merge(existing = [], incoming = []) {
        return _.uniqBy([...existing, ...incoming], "__ref")
      },
    },
    blockers: {
      merge(existing = [], incoming = []) {
        return _.uniqBy([...existing, ...incoming], "__ref")
      },
    },
    blocking: {
      merge(existing = [], incoming = []) {
        return _.uniqBy([...existing, ...incoming], "__ref")
      },
    },
    gymsFollowingForMeetups: {
      merge(existing = [], incoming = []) {
        return _.uniqBy([...existing, ...incoming], "__ref")
      },
    },
    notificationTokens: {
      merge(existing = [], incoming = []) {
        return _.uniqBy([...existing, ...incoming], "__ref")
      },
    },
    notifications: {
      merge(existing = [], incoming = []) {
        return _.uniqBy([...existing, ...incoming], "__ref")
      },
    },
    gymsMaxGradesSetAt: {
      merge(existing = [], incoming = []) {
        return _.uniqBy([...existing, ...incoming], "gymId")
      },
    },
    brandsRepresenting: {
      merge(existing = [], incoming = []) {
        return _.uniqBy([...existing, ...incoming], "_id")
      },
    },
  },
  Query: {
    fields: {
      settersSchedules: {
        keyArgs: ["isShowingAll", "startDate"],
        // Concatenate the incoming list items with
        // the existing list items.
        merge(existing = { schedules: [] }, incoming = { schedules: [] }) {
          return {
            ...incoming,
            schedules: _.uniqBy(
              [...existing.schedules, ...incoming.schedules],
              "__ref",
            ),
          }
        },
      },
      feed: {
        keyArgs: false,
        merge(existing = { feedItems: [] }, incoming = { feedItems: [] }) {
          return {
            ...incoming,
            feedItems: _.uniqBy(
              [...existing.feedItems, ...incoming.feedItems],
              "__ref",
            ),
          }
        },
      },
      fullFeed: {
        keyArgs: false,
        merge(existing = { feedItems: [] }, incoming = { feedItems: [] }) {
          return {
            ...incoming,
            feedItems: _.uniqBy(
              [...existing.feedItems, ...incoming.feedItems],
              "__ref",
            ),
          }
        },
      },
      getGymPosts: {
        keyArgs: ["_id", "maxDate"],
        merge(existing = { items: [] }, incoming = { items: [] }) {
          return {
            ...incoming,
            items: _.uniqBy([...existing.items, ...incoming.items], "__ref"),
          }
        },
      },
      getGymMessages: {
        keyArgs: ["_id"],
        merge(existing = { items: [] }, incoming = { items: [] }) {
          return {
            ...incoming,
            items: _.uniqBy([...existing.items, ...incoming.items], "__ref"),
          }
        },
      },
      instagramMedia: {
        keyArgs: false,
        merge(existing = { media: [] }, incoming = { media: [] }) {
          if (!incoming) {
            return existing
          }
          return {
            ...incoming,
            media: existing?.media
              ? _.uniqBy([...existing.media, ...incoming.media], "mediaUrl")
              : incoming.media,
          }
        },
      },
      athletesSearch: {
        keyArgs: ["searchStr", "compId"],
        merge(existing = { athletes: [] }, incoming = { athletes: [] }) {
          return {
            ...incoming,
            athletes: _.uniqBy(
              [...existing.athletes, ...incoming.athletes],
              "__ref",
            ),
          }
        },
      },
      followersList: {
        keyArgs: ["slug"],
        merge(existing = { followers: [] }, incoming = { followers: [] }) {
          return {
            ...incoming,
            followers: _.uniqBy(
              [...existing.followers, ...incoming.followers],
              "__ref",
            ),
          }
        },
      },
      followingList: {
        keyArgs: ["slug"],
        merge(existing = { following: [] }, incoming = { following: [] }) {
          return {
            ...incoming,
            following: _.uniqBy(
              [...existing.following, ...incoming.following],
              "__ref",
            ),
          }
        },
      },
      getGymFollowers: {
        keyArgs: ["_id", "searchStr"],
        merge(existing = { followers: [] }, incoming = { followers: [] }) {
          return {
            ...incoming,
            followers: _.uniqBy(
              [...existing.followers, ...incoming.followers],
              "__ref",
            ),
          }
        },
      },
      getComments: {
        keyArgs: ["_id", "type"],
        merge(existing = { comments: [] }, incoming = { comments: [] }) {
          return {
            ...incoming,
            comments: _.uniqBy(
              [...existing.comments, ...incoming.comments],
              "__ref",
            ),
          }
        },
      },
      getScanners: {
        keyArgs: ["_id"],
        merge(existing = { scanners: [] }, incoming = { scanners: [] }) {
          return {
            ...incoming,
            scanners: _.uniqBy(
              [...existing.scanners, ...incoming.scanners],
              "__ref",
            ),
          }
        },
      },
      getGradeSends: {
        keyArgs: [
          "userId",
          "grade",
          "climbType",
          "gymId",
          "guideId",
          "isIndoor",
        ],
        merge(existing = { sends: [] }, incoming = { sends: [] }) {
          return {
            ...incoming,
            sends: _.uniqBy([...existing.sends, ...incoming.sends], "__ref"),
          }
        },
      },
      getSessions: {
        keyArgs: ["userId", "minDate", "maxDate", "sessionTypes"],
        merge(existing = { items: [] }, incoming = { items: [] }) {
          return {
            ...incoming,
            items: _.uniqBy([...existing.items, ...incoming.items], "__ref"),
          }
        },
      },
      userSendsPaginated: {
        keyArgs: ["userId", "query", "sort"],
        merge(existing = { sends: [] }, incoming = { sends: [] }) {
          return {
            ...incoming,
            sends: _.uniqBy([...existing.sends, ...incoming.sends], "__ref"),
          }
        },
      },
      getWorkouts: {
        keyArgs: ["query", "sortValue", "searchStr"],
        merge(existing = { items: [] }, incoming = { items: [] }) {
          return {
            ...incoming,
            items: _.uniqBy([...existing.items, ...incoming.items], "__ref"),
          }
        },
      },
      userGamePyramids: {
        keyArgs: ["_id"],
        merge(existing = { pyramids: [] }, incoming = { pyramids: [] }) {
          return {
            ...incoming,
            pyramids: _.uniqBy(
              [...existing.pyramids, ...incoming.pyramids],
              "__ref",
            ),
          }
        },
      },
      getPyramidSends: {
        keyArgs: [
          "sinceDate",
          "userId",
          "climbType",
          "isIndoor",
          "gradeIndDiff",
          "gymId",
          "guideId",
        ],
      },
    },
  },
}

export default typePolicies
