import gql from "graphql-tag"
import { basicMediaFragment } from "../../media/graphql/basicMediaFragment"

export const basicGymAreaFragment = gql`
  fragment basicGymAreaFragment on GymArea {
    _id
    name
    slug
    type
    problemIds
    routeIds
    model3dImage
    hasProblems
    hasRoutes
    sortOrder
    setterPicture
    hideChildren
    popMedia {
      ...basicMediaFragment
    }
  }
  ${basicMediaFragment}
`

export default {
  basicGymAreaFragment,
}
