import React from "react"
import {
  Button,
  Flex,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react"
import Card from "../../common/components/Card"

import styles from "./css/ConfirmModal.module.css"
import useColors from "../hooks/useColors"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ModalCard = (props) => {
  const {
    isOpen,
    children,
    closeString = "Close",
    closeModal,
    contentStyle = {},
    isThin = false,
    ActionBtn = null,
    isHidingClose = false,
    width = "90vw",
  } = props
  const { blueColor } = useColors()
  return (
    <Modal
      onClose={() => {
        closeModal()
      }}
      isOpen={isOpen === true}
    >
      <ModalOverlay />
      <ModalContent bg={"brand.whiteColor"} width={width} maxWidth="100vw">
        <IconButton
          zIndex={2}
          position="absolute"
          top={-5}
          right={-5}
          size="lg"
          icon={<FontAwesomeIcon icon={faTimes} size="2x" />}
          variant={"secondary"}
          onClick={() => {
            closeModal(true)
          }}
        />
        <Card>{children}</Card>
      </ModalContent>
    </Modal>
  )
}

export default ModalCard
